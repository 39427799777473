import { FormattedMessage } from "react-intl";
import { EThemeType } from "redux/reducers";
import { useWindowSize } from "hooks/useWindowSize";
export interface IFeature {
  icon: React.ReactNode;
  title: string;
  content: string;
}

interface IHowToUseTheFeatureProps {
  features: IFeature[];
  themeMode?: EThemeType;
}

export const iconClassName =
  "md:w-[56px] md:h-[56px] lg:w-[70px] lg:h-[70px]";

export const HowToUseTheFeature: React.FC<IHowToUseTheFeatureProps> = ({
  features,
  themeMode,
}) => {
  
  const { width } = useWindowSize();
  return (
    <div
    style={{
        backgroundImage: `url(/feature/bgImages/bg-003-${themeMode}.jpeg)`,
        backgroundSize: "cover",
        backgroundPosition: "center",
      }
    }
    >
      <div className={`text-light dark:text-[#fff] ${width<=576 ? "px-10 pt-5 pb-[55px]":" py-14 md:py-28 px-[10%] "}`}>
        <h2 className={`text-center ${width <=405 ? "text-[26px] mb-[55px] mt-5 w-[210px] ml-auto mr-auto": width<=576 ? "text-[34px] mb-[55px] mt-5 w-[283px] ml-auto mr-auto":"text-[20px] sm:text-[24px] md:text-[28px] lg:text-[32px] mb-10 md:mb-20"}`}>
          <FormattedMessage id="feature.howToUseFeature.title" />
        </h2>
        <div className="flex flex-col lg:flex-row gap-8 xl:gap-24 md:pt-5">
          {features.map((row) => (
            <div
              className={`flex flex-col flex-1 ${width>=576? "max-w-[350px] md:max-w-[420px]": ""}`}
              key={row.title}
            >
              <span className=" lg:inline-block">{row.icon}</span>
              <div className="flex items-center mt-8 lg:mt-7">
                <h4 className={` ${width <=405? "text-[21px] pl-[6px]": width<=576? "text-[24px] pl-[6px]":"text-[16px] md:text-[20px] pl-3 lg:pl-0"}`}>
                  <FormattedMessage id={row.title} />
                </h4>
              </div>
              <p className={`mt-2 md:mt-4 opacity-70  leading-6 md:leading-7 ${width<=405? "text-[14px] pl-[6px] font-bold": width<=576? "text-[16px] pl-[6px] font-bold": "text-[14px] md:text-[16px] pl-3 lg:pl-0 font-medium"}`}>
                <FormattedMessage id={row.content} />
              </p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
