import { api } from "redux/api";
import { FolderCR, FolderCRRes, IChat, pagination } from "../chatInterface";
import { store } from "redux/store";
import { TYPES } from "redux/types";

export const setNewChatFolderId = (folder_id: number | null) => {
    store.dispatch({ type: TYPES.FOLDER_NAME, payload: folder_id });
};

export const setAccordionState = (newState: { [folder_Id: number]: boolean }) => {
    store.dispatch({ type: TYPES.SET_ACCORDION_STATE, payload: newState });
};

export const addNewChatInFolder = (folder_id: number, chat: IChat) => {
    store.dispatch({
        type: TYPES.ADD_NEW_CHAT, payload: {
            folder_id, chat
        }
    })
};

export const setChatInFolder = (chat: IChat[], ScrollPagination: pagination, folder_id: number | null) => {
    store.dispatch({ type: TYPES.APPEND_CHAT_FOLDER, payload: { chat, ScrollPagination, folder_id } });
}

export const EmptyFolder = () => {
    store.dispatch({ type: TYPES.EMPTY_FOLDER, payload: [] });
}

export const EmptyDefaultFolder = () => {
    store.dispatch({ type: TYPES.EMPTY_DEFAULT_FOLDER_CHATS, payload: [] });
}

export const EmptyFolderChats= (folder_id: number) => {
    store.dispatch({ type: TYPES.DEL_FOLDER_CHATS, payload: folder_id});
}

export const FolderCreate = (data: FolderCR): Promise<FolderCRRes> => {
    return new Promise((resolve, reject) => {
        api
            .post("/api/folders", data)
            .then((res: any) => {
                resolve(res.data as FolderCRRes);
                const page = 1;
                const lastPage = 1;
                const newChat = {
                    ...res?.data?.data,
                    pagination: {
                        page,
                        lastPage,
                    },
                    chats: []
                };
                store.dispatch({
                    type: TYPES.ADD_FOLDER,
                    payload: newChat,
                });
            })
            .catch((err: any) => {
                reject(err);
            });
    });
};

export const ShowFolders = () => {
    return new Promise((resolve, reject) => {
        api
            .get("/api/folders")
            .then((res: any) => {
                const user_id =  res?.data?.data[0]?.user_id || 0;
                const newObject = { id: 0, name: 'History', user_id, chats_count: 0, chats: [] };
                let folders = [newObject, ...res?.data?.data];
                const page = 1;
                const lastPage = 1;
                folders = folders.map(folder => ({
                    ...folder,
                    pagination: {
                        page,
                        lastPage,
                    },
                    chats: [],
                }));
                store.dispatch({
                    type: TYPES.SHOW_FOLDERS,
                    payload: folders,
                });
                resolve(folders);
            })
            .catch((err: any) => {
                reject(err);
            });
    });
};

export const DelFolder = (folder_id: number): Promise<string> => {
    return new Promise((resolve, reject) => {
        api
            .delete(`/api/folders/${folder_id}`)
            .then((res: any) => {
                const response = res.data.message as string;
                resolve(response);
                store.dispatch({
                    type: TYPES.DEL_FOLDER,
                    payload: folder_id,
                });
            })
            .catch((err: any) => {
                reject(err);
            });
    });
};

export const EditFolder = (folder_id: number, name: string): Promise<string> => {
    return new Promise((resolve, reject) => {
        api
            .put(`/api/folders/${folder_id}?name=${name}`)
            .then((res: any) => {
                resolve(res.data);
                store.dispatch({
                    type: TYPES.UPDATE_FOLDER,
                    payload: res.data.data,
                });
            })
            .catch((err: any) => {
                reject(err);
            });
    });
}

export const DelFolderChats = (folder_id: number): Promise<string> => {
    return new Promise((resolve, reject) => {
        api
            .delete(`/api/folders/empty/${folder_id}`)
            .then((res: any) => {
                const response = res.data.message as string;
                resolve(response);
                EmptyFolderChats(folder_id);
            })
            .catch((err: any) => {
                reject(err);
            });
    });
};
