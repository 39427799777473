import { FormattedMessage } from "react-intl";
import { EThemeType } from "redux/reducers";
import { useWindowSize } from "hooks/useWindowSize";

export interface IFeatureItem {
  icon: React.ReactNode;
  title: string;
  content: string;
}

interface IPerksOfFeatureProps {
  features: IFeatureItem[];
  title?: string;
  themeMode?: EThemeType;
}

export const iconSizeClassName = "md:w-[56px] md:h-[56px] lg:w-[64px] lg:h-[64px]";

export const PerksOfFeature: React.FC<IPerksOfFeatureProps> = ({
  features,
  title,
  themeMode,
}) => {
  const { width } = useWindowSize();
  return (
    <div
      className={`text-light dark:text-[#fff] ${width<=576? "pt-5 pb-[35px]": "py-14 md:py-28"}`}
      style={
      {
        backgroundImage: `url(/feature/bgImages/bg-002-${themeMode}.jpeg)`,
        backgroundSize: "cover",
        backgroundPosition: "center",
      }
    }
    >
      <div className={`w-fit mx-auto ${width<=576 ? "flex-col gap-[35px] flex": ""}`}>
        <h2 className={`text-center ${width <=405 ? "text-[26px] mb-5 w-[345px] ml-auto mr-auto": width<=576 ? "text-[34px] mb-5 w-[345px] ml-auto mr-auto": "text-[20px] sm:text-[24px] md:text-[28px] lg:text-[32px] mb-10 md:mb-20"}`}>
          <FormattedMessage id={title || "feature.perksOfFeature.title"} />
        </h2>
        {features.map((row) => (
          <div
            className={`flex flex-row items-center  ${width<=576? "mt-0 px-10": "mt-4 md:mt-8 px-6 md:px-[10%]"}`}
            key={row.title}
          >
            <span className="md:inline-block">{row.icon}</span>
            <div className="pl-5 flex-1">
              <div className="flex items-center mt-3 md:mt-0">
                <h4 className={`${width <=405? "text-[21px] pl-0": width<=576? "text-[24px] pl-0": "text-[16px] md:text-[20px] pl-3 md:pl-0" }`}>
                  <FormattedMessage id={row.title} />
                </h4>
              </div>
              <p className={`mt-2 md:mt-3 opacity-70  leading-6 md:leading-7 font-medium ${width<=405? "text-[16px]": width<=576? "text-[18px]":"text-[14px] md:text-[16px] " }`}>
                <FormattedMessage id={row.content} />
              </p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};
