import {
  SetStateAction,
  Dispatch,
  useEffect,
  useState,
  DragEvent,
  memo,
  forwardRef,
} from "react";
import { FormattedMessage, useIntl } from "react-intl";

import classNames from "classnames";
import styles from "./documentModal.module.scss";
import { Modal } from "components";

import useRouter from "hooks/useRouter";
import { useSelector } from "redux/hooks";
import { NewUploadIcon } from "../../icons/NewUploadIcon";
import { Chat } from "pages/ChatPage/pages/chat";
import { setChatModel, ChatType, EChatType } from "redux/actions";
import { IErrorMessage, IFileErrorState } from "../../NewChat";
import { IUploadFile } from "pages/ChatPage/ChatPage";
import { classifyFiles, hasInvalidAudioDuration, hasInvalidVideoDuration, shouldPreventMultipleVideoUpload, validateFile, validateFileTypes, validateImgFile, validateMaxFileCount } from "utils/fileService";
import { convertFileNamesToLowercase } from "utils/functions";

import { useFileUploader } from "utils/fileUploadS3";
import { supportedModels } from "utils/constants";
import { audioMaxCountReached, documentMaxCountReached, videoMaxCountReached } from "utils/chat";

interface IProps {
  setSelectedFile: Dispatch<SetStateAction<File[] | null>>;
  setIsMainScreenOpen?: Dispatch<SetStateAction<boolean>>;
  chatPage?: boolean;
  setIsDrag: Dispatch<SetStateAction<boolean>>;
  setErrorModal: Dispatch<SetStateAction<IFileErrorState>>;
  setShowErr?: Dispatch<SetStateAction<boolean>>;
  setOpenHistory?: Dispatch<SetStateAction<boolean>>;
  setUploadingFiles?: Dispatch<SetStateAction<IUploadFile[]>>;
  setErrorMessage?: Dispatch<SetStateAction<IErrorMessage[]>>;
  setFileS3Link?: Dispatch<SetStateAction<string[]>>;
  setIsFileUploading?: Dispatch<SetStateAction<boolean>>;
  uploadingFiles?: IUploadFile[];
  setMessageId?: Dispatch<SetStateAction<string>>;
  changeModel?: boolean;
  setChangeModel?: Dispatch<SetStateAction<boolean>>;
}

export interface DocumentModalMethods { }

export const DocumentModal = memo(
  forwardRef<DocumentModalMethods, IProps>(
    ({
      setSelectedFile,
      setIsMainScreenOpen,
      chatPage,
      setIsDrag,
      setErrorModal,
      setShowErr,
      setOpenHistory,
      setUploadingFiles,
      setErrorMessage,
      setFileS3Link,
      setIsFileUploading,
      uploadingFiles,
      setMessageId,
      changeModel,
      setChangeModel
    }) => {
      const { uploadToS3 } = useFileUploader();
      const { pathname } = useRouter();
      const { formatMessage } = useIntl();

      const { theme, gptModel, userDetail } = useSelector(
        (state) => state.authReducer
      );
      const { chatModels } = useSelector((state) => state.chatModelsReducer);
      const currentPlan = useSelector(
        (state) => state.planSubscriptionReducer.activePlan
      );
      const { newMessages, messages } = useSelector(
        (state) => state.chatReducer
      );

      const [isDrag, setDrag] = useState<boolean>(true);
      const history = pathname.includes("/chat/history");

      useEffect(() => {
        setDrag(true);
      }, []);

      const claudAllowedFileSize = gptModel?.name.includes("GPT-4") ? 15 : 5;

      const handleDragOver = (event: DragEvent<HTMLElement> | undefined) => {
        event?.preventDefault();
      };

      const handleDragLeave = (event: DragEvent<HTMLElement> | undefined) => {
        event?.preventDefault();
        setIsDrag(false);
      };

      // Helper function to set the error modal
      const setValidationError = (messageKey: string) => {
        setErrorModal({
          message: formatMessage({ id: messageKey }),
          show: true,
        });
        if (chatPage) setShowErr!(true);
        setChangeModel?.(false);
        setIsDrag(false);
        setDrag(false);
      };

      const resetFileUpload = () => {
        setSelectedFile!(null);
        setIsMainScreenOpen!(true);
        setIsDrag(false);
        setDrag(false);
      }

      const validateAndSetModel = (chatType: EChatType, modelKey: string) => {

        const supportedModels = chatModels
          .filter(model => model.type.includes(chatType))
          .map(model => model.name);

        if (!supportedModels.includes(gptModel?.name ?? '')) {
          const storedModel = localStorage.getItem(modelKey);
          const selectedModel = storedModel ? JSON.parse(storedModel) : chatModels[0];
          localStorage.setItem(modelKey, JSON.stringify(selectedModel));
          localStorage.setItem('GptModel', JSON.stringify(selectedModel));
          setChatModel(selectedModel);
        }
      };

      const handleDrop = async (event: DragEvent<HTMLElement> | undefined) => {
        event?.preventDefault();
        setOpenHistory && setOpenHistory(false);
        const files = event?.dataTransfer?.files;
        if (!files?.length) return;

        const newFilesArray = Array.from(files);
        const { imageFiles, videoFiles, audioFiles, otherFiles } = classifyFiles(newFilesArray);
        const validationError = validateFileTypes(newFilesArray, setValidationError, newMessages, messages, history, uploadingFiles);
        if (validationError === false) {
          if (shouldPreventMultipleVideoUpload(videoFiles, uploadingFiles) && !validationError) {
            setValidationError("validate.video.upload");
            return;
          }
          if (imageFiles.length === 0 && !validateMaxFileCount(audioFiles, 10, uploadingFiles)) {
            setValidationError("audioChat.file.fileLimit");
            return;
          }
          if (await hasInvalidVideoDuration(videoFiles) && !validationError) {
            setValidationError("validate.video.duration");
            return;
          }
          if (await hasInvalidAudioDuration(audioFiles) && !validationError) {
            setValidationError("validate.audio.duration");
            return;
          }
          if (
            imageFiles.length === 0 && audioFiles.length === 0 && 
            !validateMaxFileCount(otherFiles, 5, uploadingFiles)
          ) {
            setValidationError("documentChat.file.fileLimit");
            return;
          }
          await processFiles({ imageFiles, videoFiles, audioFiles, otherFiles, validationError, });
        }
      };

      const processFiles = async ({ imageFiles, videoFiles, audioFiles, otherFiles, validationError, }: { imageFiles: File[]; videoFiles: File[]; audioFiles: File[]; otherFiles: File[]; validationError: boolean; }) => {
        if (imageFiles.length > 0 && !validationError) {
          await handleImageFiles(imageFiles);
        } else if (videoFiles.length > 0 && !validationError) {
          await handleVideoFiles(videoFiles);
        } else if (audioFiles.length > 0 && !validationError) {
          await handleAudioFiles(audioFiles);
        } else if (otherFiles.length > 0 && !validationError) {
          await handleDocumentFiles(otherFiles);
        }
      };

      const handleImageFiles = async (files: File[]) => {
        setIsMainScreenOpen?.(true);
        updateModelIfNeeded("imageChatGptModel", files);

        // Handle file selection and validation
        let shouldStopExecution = false;

        // Fetch valid files first
        const validFiles = await handleFileLimit(files, 20, files);

        // Stop further execution if file limit is exceeded
        if (validFiles === null) {
          shouldStopExecution = true; // Set flag to stop further execution
          resetFileUpload();
          setSelectedFile(prevSelectedFiles => prevSelectedFiles || []); // Ensure the state is updated with the previous files
          return;
        }

        setSelectedFile(prevSelectedFiles => {
          const combinedFiles = prevSelectedFiles ? [...prevSelectedFiles, ...files] : files;
          return validFiles ? validFiles : combinedFiles;
        });

        // Stop further execution if file limit is exceeded
        if (shouldStopExecution) return;

        setChangeModel?.(false);
        setIsDrag(false);
        setDrag(false);
        const imgFile = convertFileNamesToLowercase(files);
        await uploadFilesToS3(imgFile);
      };

      const handleVideoFiles = async (files: File[]) => {
        if (videoMaxCountReached(currentPlan, userDetail)) {
          setMessageId?.("videoChat.plan.max_count");
          resetFileUpload();
          return;
        }
        setIsMainScreenOpen?.(true);
        updateModelIfNeeded("videoChatGptModel", files);
        validateAndSetModel(ChatType.video, "videoChatGptModel");
        setSelectedFile((prevSelectedFiles) => [...(prevSelectedFiles || []), ...files,]);
        setIsDrag(false); setDrag(false);
        await uploadFilesToS3(files);
      };

      const handleAudioFiles = async (files: File[]) => {
        if (audioMaxCountReached(currentPlan, userDetail)) {
          setMessageId?.("audioChat.plan.max_count");
          resetFileUpload();
          return;
        }
        const isFree = checkForFreeLimit(files, "audioChat.plan.max_count");
        if (isFree === false) return;
        setIsMainScreenOpen?.(true);
        updateModelIfNeeded("audioChatGptModel", files);
        validateAndSetModel(ChatType.audio, "audioChatGptModel");
        setSelectedFile((prevSelectedFiles) => [...(prevSelectedFiles || []), ...files,]);
        setIsDrag(false); setDrag(false);
        await uploadFilesToS3(files);
      };

      const handleDocumentFiles = async (files: File[]) => {
        if (documentMaxCountReached(currentPlan, userDetail)) {
          setMessageId?.("documentChat.plan.max_count");
          resetFileUpload();
          return;
        }
        const isFree = checkForFreeLimit(files, "documentChat.plan.max_count");
        if (isFree === false) return;
        setIsMainScreenOpen?.(true);
        updateModelIfNeeded("documentChatGptModel", files);
        validateAndSetModel(ChatType.document, "documentChatGptModel");
        setSelectedFile((prevSelectedFiles) => [...(prevSelectedFiles || []), ...files,]);
        setIsDrag(false); setDrag(false);
        await uploadFilesToS3(files);
      };

      const checkForFreeLimit = (files: File[], messageId: string) => {
        const isFreeUser = userDetail?.user.activeSubscription?.name === "Free";
        const totalFilesCount = (uploadingFiles ? uploadingFiles.length : 0) + files.length;
        if (isFreeUser && (files.length > 1 || totalFilesCount > 1)) {
          if (chatPage) setShowErr?.(true);
          resetFileUpload();
          setMessageId?.(messageId);
          return false;
        }

      };

      const updateModelIfNeeded = (modelKey: string, files: File[]) => {
        if (newMessages.length === 0 && !history && changeModel === true) {
          const storedModel = localStorage.getItem(modelKey);
          const selectedModel = storedModel ? JSON.parse(storedModel) : chatModels[1];
          localStorage.setItem(modelKey, JSON.stringify(selectedModel));
          localStorage.setItem("GptModel", JSON.stringify(selectedModel));
          setChatModel(selectedModel);
        } if (files[0].type.startsWith("image/") && !supportedModels.includes(gptModel?.name ?? "")) {
          updateModelForFileType("imageChatGptModel");
        }
      };

      const updateModelForFileType = (modelKey: string) => {
        if (newMessages.length === 0 && !history) {
          const storedModel = localStorage.getItem(modelKey);
          const selectedModel = storedModel ? JSON.parse(storedModel) : chatModels[1];
          localStorage.setItem(modelKey, JSON.stringify(selectedModel));
          localStorage.setItem("GptModel", JSON.stringify(selectedModel));
          setChatModel(selectedModel);
        }
      };

      const handleFileLimit = (files: File[], limit: number, imageFiles: File[]): File[] | null => {
        const total = (uploadingFiles && uploadingFiles.length + imageFiles.length);
        if ((uploadingFiles && (uploadingFiles?.length > 20)) || total && total > 20) {
          setErrorModal({ message: "ImageChat.file.fileLimit", show: true });
          return null;
        }
        else if (files.length > limit && uploadingFiles && uploadingFiles.length === 0) {
          setErrorModal({ message: "ImageChat.file.fileLimit", show: true });
          return null;
        }
        else
          return files;
      };

      const uploadFilesToS3 = async (files: File[]) => {
        if (!files[0].type.startsWith("image/") && !files[0]?.type.startsWith("video/") && !files[0]?.type.startsWith("audio/")
        ) {
          if (documentMaxCountReached(currentPlan, userDetail)) {
            setMessageId!("documentChat.plan.max_count");
            setSelectedFile!(null);
            setUploadingFiles!([]);
            setIsMainScreenOpen!(true);
            return;
          }
        }

        const fileArray = Array.from(files);
        const fileType = files[0]?.type.startsWith("image/")
          ? "image" : files[0]?.type.startsWith("video/") ? "video"
            : "document";
        const validFiles = fileType === "image"
          ? validateImgFile(
            fileArray,
            setErrorMessage,
            claudAllowedFileSize,
            formatMessage
          )
          : validateFile(
            fileArray,
            setIsMainScreenOpen,
            setErrorModal,
            userDetail,
            setMessageId,
          );

        if (validFiles) {
          setIsFileUploading!(true);
          await uploadToS3({ fileArray, setUploadingFiles, fileType, setIsFileUploading, setFileS3Link });
        }
      };

      const handleClose = () => {
        setIsMainScreenOpen!(true);
        setIsDrag(false);
      };

      return (
        <>
          {!chatPage && <Chat />}
          <div className={styles.container}>
            <div
              className={classNames(styles.header, {
                [styles.light]: theme === "light",
                [styles.dark]: theme === "dark",
              })}
            ></div>
            <div
              className={classNames(styles.body, {
                [styles.light]: theme === "light",
                [styles.dark]: theme === "dark",
              })}
            >
              {isDrag === true && (
                <div onClick={handleClose}>
                  <Modal
                    size="xl"
                    drag={true}
                    uploadDoc={true}
                    onDragOver={handleDragOver}
                    onDragLeave={handleDragLeave}
                    onDrop={handleDrop}
                    onClose={handleClose}
                  >
                    <label
                      htmlFor="file"
                      className={classNames(styles.dropzone, {
                        [styles.light]: theme === "light",
                        [styles.dark]: theme === "dark",
                      })}
                    >
                      <NewUploadIcon />
                      <div className={styles.message}>
                        <FormattedMessage id="documentChat.dragDrop.new.message" />
                      </div>
                      <div className={classNames(styles.validationText, {
                        [styles.light]: theme === "light",
                        [styles.dark]: theme === "dark",
                      })}>
                        <FormattedMessage id="doc.drag.drop.start" />
                      </div>
                    </label>
                  </Modal>
                </div>
              )}
            </div>
          </div>
        </>
      );
    }
  )
);