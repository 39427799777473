export const SearchIcon = (
    {chatsidebar}:{chatsidebar?: boolean}
) => {
    return (

        <svg xmlns="http://www.w3.org/2000/svg" width="30" height="28" viewBox="0 0 24 24" fill="none">
            <circle cx="11.7659" cy="11.7659" r="8.98856" stroke={chatsidebar? '#fff' :'#7A7880'} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M18.0195 18.4844L21.5436 21.9992" stroke={chatsidebar? '#fff' :'#7A7880'} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    )
}