import { Fragment, memo, useState } from "react";
import { Link, NavLink } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import classNames from "classnames";
import styles from "./SettingSidebar.module.scss";

import {
  AccountIcon,
  CurrentPlanIcon,
  TeamIcon,
  FeedbackIcon,
  EarnAsAffiliateIcon,
} from "../SettingSidebar/components/icons";

import { Separator } from "components";
import { Credits } from "views/layout/Navbar/components/ChatNav/components/Credits";
import { ThemeSwitch } from "../ChatSidebar/components/ThemeSwitch";

import { RoutePaths } from "pages/routePaths";
import useRouter from "hooks/useRouter";
import { useSelector } from "redux/hooks";
import { SettingIcon } from "../ChatSidebar/components/icons";
import { DeleteHistory, DeleteSetting } from "../ChatSidebar/components/icons/DeleteHistory";
import { DeleteChatConfirm } from "../ChatSidebar/components/DeleteChatConfirm";
import { useAppNotification } from "hooks/services/AppNotification";
import { deleteAllChats, deleteAllConversation, setPagination } from "redux/actions";
import { useWindowSize } from "hooks/useWindowSize";
interface IProp {
}

export const SettingSidebar = memo(({
}: IProp) => {

  const { triggerNotification } = useAppNotification();
  const { userDetail, theme } = useSelector((state) => state.authReducer);
  const { includeRoute } = useRouter();
  const [showConfirmModel, setShowConfirmModel] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const { width } = useWindowSize();

  const itemStyles = (route: string, delSetting?: boolean) => {
    return classNames(styles.menuItem, {
      [styles.active]: includeRoute(route) && !delSetting,
      [styles.light]: theme === "light",
      [styles.dark]: theme === "dark",
      [styles.settingsMenuItems]: includeRoute(RoutePaths.Settings),
    });
  };

  const handleSubmit = () => {
    setLoading(true);
    setShowConfirmModel(false);
    deleteAllChats();
    setPagination(0, 0)
    setLoading(false);
    deleteAllConversation(true).then(() => {
      setShowConfirmModel(false);
      setLoading(false);
    }).catch((err) => {
      setShowConfirmModel(false);
      setLoading(false);
      triggerNotification({ message: err?.data?.message, type: "error" });
    })
  }

  return (
    <Fragment>
      {showConfirmModel &&
        <DeleteChatConfirm onCancel={() => setShowConfirmModel(false)} onSubmit={handleSubmit} loading={loading} delConversation={true} />
      }
      <div className={classNames(styles.hideCredits, {
        [styles.settingsCredits]: includeRoute(RoutePaths.Settings)
      })}>
        <Credits />
      </div>
      <Separator height="20px" />
      <div
        className={styles.container}
      >
        <div className={styles.memu}>
          <div className={styles.icon}>
            <SettingIcon />
            <h2 className={classNames(styles.h2, {
              [styles.settingsH2]: includeRoute(RoutePaths.Settings)
            })}>
              <FormattedMessage id="sidebar.chat.newChat.settings" />
            </h2>
          </div>
          <div className={styles.menuSeparator}></div>
          <NavLink
            data-testid='setting-side-bar-account-btn'
            className={itemStyles(RoutePaths.Account)}
            to={RoutePaths.Account}
          >
            <AccountIcon route={includeRoute(RoutePaths.Account)} />
            <span className={classNames(styles.text, {
              [styles.active]: includeRoute(RoutePaths.Account),
              [styles.settingsText]: includeRoute(RoutePaths.Settings)

            })}>
              <FormattedMessage id="sidebar.settings.account" />
            </span>

          </NavLink>
          <div className={styles.menuSeparator}></div>
          {userDetail?.user.team.role !== "member" && (
            <>
              <NavLink
                data-testid='setting-side-bar-team-btn'
                className={itemStyles(RoutePaths.Team)}
                to={RoutePaths.Team}
              >
                <TeamIcon route={includeRoute(RoutePaths.Team)} />
                <span className={classNames(styles.text, {
                  [styles.active]: includeRoute(RoutePaths.Team),
                  [styles.settingsText]: includeRoute(RoutePaths.Settings)
                })}>
                  <FormattedMessage id="sidebar.settings.myTeam" />
                </span>
              </NavLink>
              <div className={styles.menuSeparator}></div>
              {userDetail?.user.team.role === "admin" && (
                <>
                  <NavLink
                    data-testid='setting-side-bar-current-btn'
                    className={itemStyles(RoutePaths.CurrentPlan)}
                    to={RoutePaths.CurrentPlan}
                  >
                    <CurrentPlanIcon theme={theme} route={includeRoute(RoutePaths.CurrentPlan)} settingsidebar={true} />
                    <span className={classNames(styles.text, {
                      [styles.active]: includeRoute(RoutePaths.CurrentPlan),
                      [styles.settingsText]: includeRoute(RoutePaths.Settings)
                    })}>
                      <FormattedMessage id="sidebar.settings.currentPlan" />
                    </span>
                  </NavLink>
                  <div className={styles.menuSeparator}></div>
                </>
              )}
            </>
          )}
          <a
            href="https://deftgpt.canny.io/feature-requests/create"
            target="_blank"
            rel="noreferrer"
            className={itemStyles(RoutePaths.Feedback)}
            data-testid='setting-side-bar-feedback-btn'
          >
            <FeedbackIcon />
            <span className={classNames(styles.text, {
              [styles.settingsText]: includeRoute(RoutePaths.Settings)
            })} >
              <FormattedMessage id="sidebar.settings.giveFeedback" />
            </span>
          </a>
          <div className={styles.menuSeparator}></div>
          <Link
            data-testid='setting-side-bar-affiliate-btn'
            to={`/${RoutePaths.Affiliate}`}
            className={itemStyles(RoutePaths.Affiliate)}
          >
            <EarnAsAffiliateIcon />
            <span className={classNames(styles.text, {
              [styles.settingsText]: includeRoute(RoutePaths.Settings)
            })} >
              <FormattedMessage id="sidebar.settings.earnAsAffiliate" />
            </span>
          </Link>
          <div className={styles.menuSeparator}></div>
          <div
            data-testid='setting.del.all.chat'
            className={itemStyles(RoutePaths.Account, true)}
            onClick={() => setShowConfirmModel(true)}
          >
            {width <= 576 && includeRoute(RoutePaths.Settings) ? (
              <DeleteSetting />
            ) : (
              <DeleteHistory settingSideBar={true} />
            )}
            <span className={classNames(styles.text, {
              [styles.settingsTextdelete]: includeRoute(RoutePaths.Settings)
            })} >
              <FormattedMessage id="delete.all.conversation" />
            </span>
          </div>
          {/* } */}
        </div>
      </div>
      <div className={styles.switchTheme}
        style={{
          position: "absolute",
          bottom: 0,
          width: "100%",
        }}
      >
        <ThemeSwitch settingsSidebar={true} />
      </div>
    </Fragment>
  );
});
