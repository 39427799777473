export const CheckedIcon = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M20 6.5L9 17.5L4 12.5"
      stroke="#527AE6"
      strokeWidth="2.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <defs>
      <linearGradient
        id="paint0_linear_65_2708"
        x1="4"
        y1="6.5"
        x2="20.5883"
        y2="7.47323"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#8C49F7" />
        <stop offset="1" stopColor="#6C53FF" />
      </linearGradient>
    </defs>
  </svg>
);


export const PriceCardCheck = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="15"
    height="15"
    viewBox="0 0 15 15"
    fill="none"
  >
    <path
      d="M7.5 0C6.01664 0 4.56659 0.439867 3.33323 1.26398C2.09986 2.08809 1.13856 3.25943 0.570907 4.62987C0.00324966 6.00032 -0.145275 7.50832 0.144114 8.96318C0.433503 10.418 1.14781 11.7544 2.1967 12.8033C3.2456 13.8522 4.58197 14.5665 6.03682 14.8559C7.49168 15.1453 8.99968 14.9968 10.3701 14.4291C11.7406 13.8614 12.9119 12.9001 13.736 11.6668C14.5601 10.4334 15 8.98336 15 7.5C15 5.51088 14.2098 3.60322 12.8033 2.1967C11.3968 0.790176 9.48912 0 7.5 0ZM11.3911 5.93659L6.61841 10.7093C6.49055 10.8371 6.31716 10.9089 6.13637 10.9089C5.95557 10.9089 5.78218 10.8371 5.65432 10.7093L3.60887 8.66386C3.48467 8.53527 3.41594 8.36304 3.4175 8.18427C3.41905 8.0055 3.49076 7.83449 3.61717 7.70808C3.74359 7.58166 3.91459 7.50996 4.09336 7.5084C4.27214 7.50685 4.44436 7.57557 4.57296 7.69977L6.13637 9.26318L10.427 4.9725C10.5556 4.8483 10.7279 4.77958 10.9066 4.78113C11.0854 4.78268 11.2564 4.85439 11.3828 4.9808C11.5092 5.10722 11.581 5.27823 11.5825 5.457C11.5841 5.63577 11.5153 5.808 11.3911 5.93659Z"
      fill="#5B71F5"
    />
  </svg>
);

export const PriceCardCheckHover = () => (
<svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 15 15" fill="none">
  <path d="M7.5 0C6.01664 0 4.56659 0.439867 3.33323 1.26398C2.09986 2.08809 1.13856 3.25943 0.570907 4.62987C0.00324966 6.00032 -0.145275 7.50832 0.144114 8.96318C0.433503 10.418 1.14781 11.7544 2.1967 12.8033C3.2456 13.8522 4.58197 14.5665 6.03682 14.8559C7.49168 15.1453 8.99968 14.9968 10.3701 14.4291C11.7406 13.8614 12.9119 12.9001 13.736 11.6668C14.5601 10.4334 15 8.98336 15 7.5C15 5.51088 14.2098 3.60322 12.8033 2.1967C11.3968 0.790176 9.48912 0 7.5 0ZM11.3911 5.93659L6.61841 10.7093C6.49055 10.8371 6.31716 10.9089 6.13637 10.9089C5.95557 10.9089 5.78218 10.8371 5.65432 10.7093L3.60887 8.66386C3.48467 8.53527 3.41594 8.36304 3.4175 8.18427C3.41905 8.0055 3.49076 7.83449 3.61717 7.70808C3.74359 7.58166 3.91459 7.50996 4.09336 7.5084C4.27214 7.50685 4.44436 7.57557 4.57296 7.69977L6.13637 9.26318L10.427 4.9725C10.5556 4.8483 10.7279 4.77958 10.9066 4.78113C11.0854 4.78268 11.2564 4.85439 11.3828 4.9808C11.5092 5.10722 11.581 5.27823 11.5825 5.457C11.5841 5.63577 11.5153 5.808 11.3911 5.93659Z" fill="white"/>
</svg>
);