import { store } from "../../store";
import {
  DropdownOption,
  IChat,
  IMessage,
  subOption,
} from "../chatInterface";
import { TYPES } from "../../types";



export const startNewChat = () => {
  store.dispatch({ type: TYPES.START_NEW_CHAT, payload: [] });
};

export const askNewChatQuestion = (message: IMessage) => {
  store.dispatch({ type: TYPES.NEW_CHAT, payload: message });
};

export const answerNewChatQuestion = (message: IMessage) => {
  store.dispatch({ type: TYPES.ANSWER_NEW_CHAT, payload: message });
};

export const askRegularQuestion = (message: IMessage) => {
  store.dispatch({ type: TYPES.REGULAR_CHAT, payload: message });
};

export const updateNewRegularQuestion = (message: IMessage) => {
  store.dispatch({ type: TYPES.UPDATED_NEW_REGULAR_CHAT, payload: message });
};

export const updatedRegularQuestion = (message: IMessage) => {
  store.dispatch({ type: TYPES.UPDATE_REGULAR_CHAT, payload: message });
};

export const answerRegularQuestion = (message: IMessage) => {
  store.dispatch({ type: TYPES.ANSWER_REGULAR_CHAT, payload: message });
};

export const setPrivateChat = (thempChat: boolean) => {
  store.dispatch({ type: TYPES.SET_PRIVATE_CHAT, payload: thempChat });
};

export const setRememberSetting = (rememberSetting: boolean) => {
  store.dispatch({
    type: TYPES.SET_REMEMBER_SETTING,
    payload: rememberSetting,
  });
};

export const setRememberHistoryType = (rememberType: string) => {
  store.dispatch({ type: TYPES.SET_REMEMBER_Chat_Type, payload: rememberType });
};

export const setDropDownOptions = (attribute: DropdownOption[]) => {
  store.dispatch({ type: TYPES.SET_DROP_DOWN_OPTIONS, payload: attribute });
};

export const setcontainerHeight = (containerHeight: number) => {
  store.dispatch({
    type: TYPES.SET_CONTAINER_HEIGHT,
    payload: containerHeight,
  });
};

export const setErrordisplay = (drag: boolean) => {
  store.dispatch({ type: TYPES.SET_DRAG_FILE, payload: drag });
};

export const SaveAllChats = (chathistory: IChat[]) => {
  store.dispatch({ type: TYPES.SAVE_ALL_CHATS, payload: chathistory });
};

export const setPagination = (page: number, LastPage: number) => {
  store.dispatch({ type: TYPES.SET_PAGINATION, payload: { page, LastPage } });
};

export const setChatHistoryLoading = (loading: boolean) => {
  store.dispatch({ type: TYPES.SET_CHAT_HISTORY_LOADING, payload: loading });
};

export const deleteAllChats = () => {
  store.dispatch({ type: TYPES.DELETE_ALL_CHATS });
};

export const setViewChatHistory = (viewHistory: boolean) => {
  store.dispatch({ type: TYPES.SET_VIEW_CHAT_HISTORY, payload: viewHistory });
};

export const setSearchWord = (searchWord: string) => {
  store.dispatch({ type: TYPES.SET_SEARCH_WORD, payload: searchWord });
};

export const setSearchQuery = (searchWord: string) => {
  store.dispatch({ type: TYPES.SET_SEARCH_Query, payload: searchWord });
};

export const setSelectesMessageId = (message: number) => {
  store.dispatch({ type: TYPES.SET_Selected_MessageId, payload: message });
};

export const setScrollId = (message: boolean) => {
  store.dispatch({ type: TYPES.SET_Scroll_Id, payload: message });
};

export const deleteSelectedChats = (selectedChatIds: number[], folder_id: number | string) => {
  if (typeof folder_id === "number")
    store.dispatch({
      type: TYPES.DELETE_SELECTED_CHAT_FOLDER,
      payload: { selectedChatIds, folder_id }
    })
  store.dispatch({
    type: TYPES.DELETE_SELECTED_CHATS,
    payload: { selectedChatIds },
  });
};

export const deleteSingleChatHistory = (id: number, folder_id?: number | string) => {
  if (typeof folder_id === 'number') store.dispatch({ type: TYPES.DELETE_CHAT_FOLDER, payload: { id: id, folder_id: folder_id } })
  else store.dispatch({ type: TYPES.DELETE_CHAT_HISTORY, payload: { id: id } });
};

export const addMessage = (newMessage: IMessage, chatId: number) => {
  store.dispatch({ type: TYPES.ADD_MESSAGE, payload: { newMessage, chatId } });
};

export const updateMessage = (newMessage: IMessage, chatId: number) => {
  store.dispatch({
    type: TYPES.UPDATE_MESSAGE,
    payload: { newMessage, chatId },
  });
};

export const regenerateModelNme = (modelName: string, loader: boolean) => {
  store.dispatch({
    type: TYPES.REGENERATE_MODEL_NAME,
    payload: { modelName, loader }
  })
}

export const updateAnswer = (
  message: IMessage,
  chatId: number,
  assistantId: number
) => {
  store.dispatch({
    type: TYPES.UPDATE_ANSWER,
    payload: { message, chatId, assistantId },
  });
};

export const newChatQuestion = (message: IMessage) => {
  store.dispatch({ type: TYPES.NEW_CHAT_QUESTION, payload: message });
};

export const removeChatQues = () => {
  store.dispatch({ type: TYPES.REMOVE_CHAT_QUESTION, payload: [] });
};

export const updateNewChatQuestion = (message: IMessage) => {
  store.dispatch({ type: TYPES.UPDATE_CHAT_QUESTION, payload: message });
};

export const AddHistoryMessage = (chatId: number) => {
  store.dispatch({ type: TYPES.ADD_MESSAGE_HISTORY, payload: chatId });
};

export const updateAssistantAnswer = (
  message: IMessage,
  assistantId: number
) => {
  store.dispatch({
    type: TYPES.UPDATE_ASSISTANT_ANSWER,
    payload: { message, assistantId },
  });
};

export const setIsLoadMoreMessages = (isloadMore: boolean) => {
  store.dispatch({ type: TYPES.LOAD_MESSAGES, payload: isloadMore });
};

export const clearhistoryMessages = () => {
  store.dispatch({ type: TYPES.CLEAR_HISTORY_MESSAGES });
};

export const setMessagesPagination = (page: number, lastPage: number) => {
  store.dispatch({
    type: TYPES.MESSAGES_PAGINATION,
    payload: { current_page: page, last_page: lastPage },
  });
};

export const updateLanguageOptions = (filterlanguage: subOption[]) => {
  store.dispatch({
    type: TYPES.UPDATE_DROP_DOWN_OPTIONS,
    payload: filterlanguage,
  });
};

export const setAdminAccountDeleted = (errorMessage: boolean) => {
  store.dispatch({ type: TYPES.SET_ADMIN_DELETED, payload: errorMessage });
};