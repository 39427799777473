import { useState } from "react";
import { useIntl, FormattedMessage } from "react-intl";
import classNames from "classnames";
import styles from "./GuidePage.module.scss";

import { Main } from "components/Main";

import { useSelector } from "redux/hooks";

enum GuideTabType {
  CHANGE_SHORTCUT = "change-shortcut",
  CHANGE_LANGUAGE = "change-language",
  ADD_TEAM_MEMBER = "add-team-member",
  GIVE_FEEDBACK = "give-feedback",
}

interface TabItem {
  text: string;
  id: string;
  desc: string;
  image: string;
}

export const GuidePage = () => {
  const themeMode = useSelector((state) => state.authReducer.theme);
  const { formatMessage } = useIntl();

  const [activeTab, setActiveTab] = useState<string>(
    GuideTabType.CHANGE_SHORTCUT
  );

  const Tabs: TabItem[] = [
    {
      text: formatMessage({ id: "landing.guide.changeShortcut" }),
      id: GuideTabType.CHANGE_SHORTCUT,
      desc: formatMessage({ id: "landing.guide.changeShortcut.desc" }),
      image:
        "https://www.deftgpt.com/storage/pages/pTfbkszoSblskwGRP5Z2j9JhPrKseBTaYXNkecH4.png",
    },
    {
      text: formatMessage({ id: "landing.guide.changeLanguage" }),
      id: GuideTabType.CHANGE_LANGUAGE,
      desc: formatMessage({ id: "landing.guide.changeLanguage.desc" }),
      image:
        "https://www.deftgpt.com/storage/pages/UZnr6SgI1rWc2DcTZ8FiQdEvwU46jW6iXP3WD50K.gif",
    },
    {
      text: formatMessage({ id: "landing.guide.addTeamMember" }),
      id: GuideTabType.ADD_TEAM_MEMBER,
      desc: formatMessage({ id: "landing.guide.addTeamMember.desc" }),
      image:
        "https://www.deftgpt.com/storage/pages/dJjpvjFlB69ngMcO1T2LZzri1FXeNelTWozjtYNH.png",
    },
    {
      text: formatMessage({ id: "landing.guide.giveFeedback" }),
      id: GuideTabType.GIVE_FEEDBACK,
      desc: formatMessage({ id: "landing.guide.giveFeedback.desc" }),
      image:
        "https://www.deftgpt.com/storage/pages/dJjpvjFlB69ngMcO1T2LZzri1FXeNelTWozjtYNH.png",
    },
  ];

  return (
    <Main>
      <div className={styles.container}>
        <h2
          className={classNames(styles.pageTitle, {
            [styles.light]: themeMode === "light",
            [styles.dark]: themeMode === "dark",
          })}
        >
          <FormattedMessage id="landing.nav.guide" />
        </h2>
        <div className={styles.tabs}>
          {Tabs.map((item) => (
            <div
              key={item.id}
              className={classNames(styles.tab, {
                [styles.tabLight]: themeMode === "light",
                [styles.tabDark]: themeMode === "dark",
                [styles.active]: item.id === activeTab,
              })}
              onClick={() => setActiveTab(item.id)}
              data-testid={item.id}
            >
              {item.text}
            </div>
          ))}
        </div>
        {Tabs.map((item) => {
          if (item.id === activeTab) {
            return (
              <div className={styles.tabContent} key={`content-${item.id}`}>
                <div className={styles.content}>
                  <h2
                    className={classNames(styles.title, {
                      [styles.light]: themeMode === "light",
                      [styles.dark]: themeMode === "dark",
                    })}
                  >
                    {item.text}
                  </h2>
                  <p
                    className={classNames(styles.text, {
                      [styles.light]: themeMode === "light",
                      [styles.dark]: themeMode === "dark",
                    })}
                  >
                    {item.desc}
                  </p>
                </div>
                <div className={styles.imgbox}>
                  <img src={item.image} loading="lazy" alt={item.text} />
                </div>
              </div>
            );
          }
          return null;
        })}
      </div>
    </Main>
  );
};
