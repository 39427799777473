export const FolderIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
      <path d="M7.16667 3C7.43713 3 7.70151 3.0731 7.93238 3.21016L8.06667 3.3L9.667 4.5H15.5C16.7703 4.5 17.8192 5.44737 17.9789 6.67409L17.9947 6.83562L18 7V14.5C18 15.8255 16.9685 16.91 15.6644 16.9947L15.5 17H4.5C3.17452 17 2.08996 15.9685 2.00532 14.6644L2 14.5V5.5C2 4.17452 3.03154 3.08996 4.33562 3.00532L4.5 3H7.16667ZM8.15763 7.03449C7.90991 7.2946 7.57838 7.45592 7.22435 7.49219L7.07143 7.5L3 7.499V14.5C3 15.2797 3.59489 15.9204 4.35554 15.9931L4.5 16H15.5C16.2797 16 16.9204 15.4051 16.9931 14.6445L17 14.5V7C17 6.2203 16.4051 5.57955 15.6445 5.50687L15.5 5.5H9.617L8.15763 7.03449ZM7.16667 4H4.5C3.7203 4 3.07955 4.59489 3.00687 5.35554L3 5.5V6.499L7.07143 6.5C7.1809 6.5 7.28655 6.46411 7.37274 6.39902L7.4335 6.34483L8.694 5.021L7.46667 4.1C7.40176 4.05132 7.32632 4.01941 7.24701 4.0065L7.16667 4Z" fill="white" />
      <path d="M10 8.75V13.25" stroke="white" stroke-width="0.8" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M7.75 11H12.25" stroke="white" stroke-width="0.8" stroke-linecap="round" stroke-linejoin="round" />
    </svg>
  );
}

export const Folder = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="30" height="28" viewBox="0 0 30 28" fill="none">
      <path d="M11.4583 5C11.7964 5 12.1269 5.09138 12.4155 5.2627L12.5833 5.375L14.5837 6.875H21.875C23.4629 6.875 24.774 8.05921 24.9736 9.59261L24.9934 9.79453L25 10V19.375C25 21.0319 23.7106 22.3875 22.0805 22.4934L21.875 22.5H8.125C6.46815 22.5 5.11245 21.2106 5.00665 19.5805L5 19.375V8.125C5 6.46815 6.28942 5.11245 7.91952 5.00665L8.125 5H11.4583ZM12.697 10.0431C12.3874 10.3682 11.973 10.5699 11.5304 10.6152L11.3393 10.625L6.25 10.6238V19.375C6.25 20.3496 6.99361 21.1505 7.94442 21.2414L8.125 21.25H21.875C22.8496 21.25 23.6505 20.5064 23.7414 19.5556L23.75 19.375V10C23.75 9.02537 23.0064 8.22444 22.0556 8.13359L21.875 8.125H14.5212L12.697 10.0431ZM11.4583 6.25H8.125C7.15038 6.25 6.34944 6.99361 6.25859 7.94442L6.25 8.125V9.37375L11.3393 9.375C11.4761 9.375 11.6082 9.33014 11.7159 9.24878L11.7919 9.18104L13.3675 7.52625L11.8333 6.375C11.7522 6.31415 11.6579 6.27426 11.5588 6.25812L11.4583 6.25Z" fill="white" />
    </svg>
  );
}