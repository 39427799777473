import classNames from "classnames";
import styles from "./ChatNav.module.scss";
import { Link } from "react-router-dom";
import CutsomButton from "components/Button";

import { RoutePaths } from "pages/routePaths";
import styled from "styled-components";
import { useWindowSize } from "hooks/useWindowSize";
import { Credits } from "./components/Credits";

import { useSelector } from "redux/hooks";

import useRouter from "hooks/useRouter";
import { FormattedMessage } from "react-intl";
import { SetStateAction, Dispatch } from "react";
import { Setting } from "./icons/setting";
import { IChat } from "redux/actions";
import { ChatModel } from "./components/ChatModel";

interface IProps {
  isMainScreenOpen?: boolean;
  toggleChatModelsModel?: () => void;
  setChatSetting?: Dispatch<SetStateAction<boolean>>;
  chatItem?: IChat;
}

const Button = styled(CutsomButton)`
  display: none;
  padding : 5px 40px ;
  @media screen and (min-width: 769px) {
    display: inline-flex;
  }
`;

export const ChatNav = ({
  isMainScreenOpen,
  toggleChatModelsModel,
  setChatSetting,
  chatItem,
}: IProps) => {

  const { push } = useRouter();
  const { width } = useWindowSize();
  const { theme, userDetail } = useSelector((state) => state.authReducer);
  const isShare = window.location.pathname.includes("share-chat");
  const lang = userDetail?.user?.language ? userDetail?.user?.language : "en";

  return (
    <>
      {isShare &&
        <div className={classNames(styles.LogoContainer)} >
          <Link to='/' className={styles.headerLogo} data-testid='share-header-logo'>
            <img src={`/logo-${theme}.svg`} alt='deftGPT logo' />
          </Link>
          {width > 1020 &&
            <div className={classNames(styles.multipleaiModel, {
              [styles.light]: theme === "light",
              [styles.dark]: theme === "dark",
            })}>- Chat with multiple AI models</div>
          }
        </div>
      }
      {userDetail && userDetail.token &&
        <ChatModel
          toggleChatModelsModel={toggleChatModelsModel}
          chatItem={chatItem}
          isMainScreenOpen={isMainScreenOpen} />
      }

      {isShare ?
        <div className={styles.shareChatbtn}>
          <Button data-testid='share-chat-try-btn' className={styles.shareChatTryBtn}
            onClick={() => push(`/${lang}/${RoutePaths.Signup}`)}
          >
            <FormattedMessage id='share.chat.header' />
          </Button>
        </div> :
        <>
          <div className={styles.creditsContainer}>
            <div
              className={styles.settingIcon}
              onClick={() => setChatSetting!(true)}
              data-testid='chat-setting-icon'
            >
              <Setting />
            </div>
            <div>
              <Credits />
            </div>
          </div>
        </>
      }
    </>
  );
};
