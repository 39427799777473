
import { useState } from "react";
import { FormattedMessage } from "react-intl";
import classNames from "classnames";
import styles from "./FAQs.module.scss";
import { useSelector } from "redux/hooks";
import { EThemeType } from "redux/reducers";
import styled from "styled-components";
import CustomButton from "components/Button";
import { FAQSDownArrow } from "../components/Icons";

const DurationButton = styled(CustomButton) <{ theme: EThemeType }>`
  background-color: ${({ theme }) => (theme === "dark" ? "#5B71F5" : "#5B71F5")};
  color:#fff;
  border-radius: 50px;
  padding: 10px 30px;
  justify-content: center;
align-items: center;
gap: 10px;
width:auto;
height: 68px;
`;
export const FrequentlyAskedQuestions = () => {

    const { theme } = useSelector((state) => state.authReducer);
    const [expandedQuestions, setExpandedQuestions] = useState<string[]>([]);


    const faqsData = [
        { question: "faqs.question1", answer: "faqs.answer1" },
        { question: "faqs.question2", answer: "faqs.answer2" },
        { question: "faqs.question3", answer: "faqs.answer3" },
        { question: "faqs.question4", answer: "faqs.answer4" },
        { question: "faqs.question5", answer: "faqs.answer5" },
        { question: "faqs.question6", answer: "faqs.answer6" },
        { question: "faqs.question7", answer: "faqs.answer7" },
    ];

    const toggleQuestion = (question: string) => {
        if (expandedQuestions.includes(question)) {
            setExpandedQuestions(expandedQuestions.filter((q) => q !== question));
        } else {
            setExpandedQuestions([...expandedQuestions, question]);
        }
    };
    return (
        <div style={{
            backgroundImage: `url(/landing/${theme}/FAQ-bg.png)`,
            backgroundSize: "cover",
            backgroundPosition: "center",
        }}>
            <div className={styles.faqsConatainer}>
                <div className={styles.headContainer}>
                    <div className={styles.textContainer}>
                        <h2
                            className={classNames(styles.title, {
                                [styles.light]: theme === "light",
                                [styles.dark]: theme === "dark",
                            })}
                        >
                            <FormattedMessage id="pricing.faqs.title" />
                        </h2>
                        <p
                            className={classNames(styles.desc, {
                                [styles.light]: theme === "light",
                                [styles.dark]: theme === "dark",
                            })}
                        >
                            <FormattedMessage id="pricing.faqs.desc" />
                        </p>
                    </div>
                    <DurationButton
                        planBtn={true}
                        theme={theme}
                    >
                        <span className={styles.monthly}>
                            <FormattedMessage id="pricing.about.deftgpt" />
                        </span>
                    </DurationButton>
                </div>
                <div className={styles.faqsDataContainer}>
                    {faqsData.map((item) => (
                        <div
                            key={item.question}
                            className={classNames(
                                styles.faqsItemContainer,
                                {
                                    [styles.light]: theme === "light",
                                    [styles.dark]: theme === "dark",
                                    [styles.expanded]: expandedQuestions.includes(item.question) && theme === "light",
                                    [styles.darkexpanded]: expandedQuestions.includes(item.question) && theme === "dark",
                                }
                            )}
                            onClick={() => toggleQuestion(item.question)}
                        >
                            <div className={styles.faqsData}>
                                <h4 className={classNames(
                                    styles.faqsQuestion,
                                    {
                                        [styles.light]: theme === "light",
                                        [styles.dark]: theme === "dark",
                                    }
                                )}>
                                    <FormattedMessage id={item.question} />
                                </h4>
                                {expandedQuestions.includes(item.question) && (
                                    <p className={classNames(
                                        styles.faqsAnswers,
                                        {
                                            [styles.light]: theme === "light",
                                            [styles.dark]: theme === "dark",
                                        }
                                    )}>
                                        <FormattedMessage id={item.answer} values={{ email: "Support@deftgpt.com", }} />
                                    </p>
                                )}
                            </div>
                            <span
                                className={classNames(
                                    styles.arrow,{
                                    [styles.dark]: theme === "dark",
                                    [styles.arrowUpward]: expandedQuestions.includes(item.question)

                                })}>
                                <FAQSDownArrow />
                            </span>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
}