import { FormattedMessage } from "react-intl";
import { Dispatch, SetStateAction } from "react";
import styles from "./Prompt.module.scss";
import classNames from "classnames";
import { useSelector } from "redux/hooks";
import { useWindowSize } from "hooks/useWindowSize";

interface ITab {
    label: string;
    value: string;
}

interface IProps {
    data: ITab[];
    activeTab: any;
    setActiveTab: Dispatch<SetStateAction<string>>;
}


export const PromptOptions = ({ data, activeTab, setActiveTab }: IProps) => {
    const { theme } = useSelector((state) => state.authReducer);
    const {width} = useWindowSize();

    return (
        <div className={`flex  ${width <= 576 ? "justify-between px-[20px]" : "gap-4"}`}>
            {data.map(({ label, value }) => (
                <button
                    key={value}
                    data-testid={value==='myprompts'? 'user-prompts-btn': 'community-prompts-btn'}
                    onClick={() => setActiveTab(value)}
                    className={ ` bg-transparent ${activeTab === value ? "text-[#527AE6] " + classNames(styles.activatedborder) : "border-transparent p-0"} ${classNames(styles.promptBtn)} ${theme === 'dark' && activeTab !== value ? "text-[#CBCBDD]" : theme === 'light' && activeTab !== value ? "text-[#CBCBDD]" : ""}  ${width <= 576 ? "text-[14px] leading-[20px] font-semibold" : "text-lg font-bold"}`}
                    style={{borderBottom: activeTab!== value && theme==='dark'?  '2px solid #CBCBDD': activeTab!== value && theme==='light'? '2px solid #CBCBDD':'' }}
                > 
                    <FormattedMessage id={label} />
                </button>
            ))}
        </div>
    )  
};