import { FormattedMessage } from "react-intl";
import classNames from "classnames";
import styles from "./InvoiceModal.module.scss";

import { Modal, Separator } from "components";
import Button from "components/Button";

import { IPlan, IActivePlan } from "redux/actions";
import { useSelector } from "redux/hooks";

interface IShowMessage {
  currentPlan?: IActivePlan;
  requiredPlan?: IPlan;
}

interface IProps extends IShowMessage {
  onCancel?: () => void;
  onConfirm?: () => void;
  isConfirmLoading?: boolean;
  onClose?: () => void;
}

const roundOff = (amount: number): number => {
  return Math.round((amount + Number.EPSILON) * 100) / 100;
};

const showMessage = ({ currentPlan, requiredPlan }: IShowMessage) => {
  const currentPlanAmount = JSON.parse(currentPlan?.cost as string);
  const requiredPlanAmount = requiredPlan ? requiredPlan?.discounted_price : 0;
  if (currentPlan?.name === requiredPlan?.name) {
    if (
      currentPlan?.duration === "month" &&
      requiredPlan?.duration === "year"
    ) {
      return (
        <FormattedMessage
          id="invoice.modal.upgrade.message"
          values={{ amount: roundOff(requiredPlanAmount * 12) }}
        />
      );
    } else if (
      currentPlan?.duration === "year" &&
      requiredPlan?.duration === "month"
    ) {
      return <FormattedMessage id="invoice.modal.downgrade.description" />;
    } else {
      return "";
    }
  } else {
    if (
      currentPlan?.duration === "month" &&
      requiredPlan?.duration === "year"
    ) {
      const amount = roundOff(requiredPlanAmount * 12) - currentPlanAmount;
      if (amount > 0) {
        return (
          <FormattedMessage
            id="invoice.modal.upgrade.message"
            values={{ amount: roundOff(requiredPlanAmount * 12) }}
          />
        );
      } else {
        return <FormattedMessage id="invoice.modal.downgrade.description" />;
      }
    } else if (
      currentPlan?.duration === "year" &&
      requiredPlan?.duration === "year"
    ) {
      const amount = roundOff(requiredPlanAmount * 12) - currentPlanAmount;
      if (amount > 0) {
        return (
          <FormattedMessage
            id="invoice.modal.upgrade.message"
            values={{ amount: roundOff(requiredPlanAmount * 12) }}
          />
        );
      } else {
        return <FormattedMessage id="invoice.modal.downgrade.description" />;
      }
    } else if (
      currentPlan?.duration === "year" &&
      requiredPlan?.duration === "month"
    ) {
      return <FormattedMessage id="invoice.modal.downgrade.description" />;
    } else {
      const amount = requiredPlanAmount - currentPlanAmount;
      if (amount > 0) {
        return (
          <FormattedMessage
            id="invoice.modal.upgrade.message"
            values={{ amount: requiredPlanAmount }}
          />
        );
      } else {
        return <FormattedMessage id="invoice.modal.downgrade.description" />;
      }
    }
  }
};

export const InvoiceModal = ({
  currentPlan,
  requiredPlan,
  onCancel,
  onConfirm,
  isConfirmLoading,
  onClose,
}: IProps) => {
  const { theme } = useSelector((state) => state.authReducer);

  return (
    <Modal size="sm" onClose={onClose} isPadding>
      <div className={styles.heading}>
        <div className={styles.headline}>
          <FormattedMessage
            id="invoice.modal.heading"
            values={{
              currentPlan: `${currentPlan?.name}(${currentPlan?.duration})`,
              requiredPlan: `${requiredPlan?.name}(${requiredPlan?.duration})`,
            }}
          />
        </div>
        <Separator height="17px" />
        <div className={styles.subHealine}>
          {showMessage({ currentPlan, requiredPlan })}
          {/* <FormattedMessage
            id="invoice.modal.upgrade.message"
            values={{ amount: requiredPlan?.price }}
          /> */}
        </div>
      </div>
      <Separator height="50px" />
      <div className={styles.modalFooter}>
        <Button
          style={{ minWidth: "100px" }}
          variant="outlined-primary"
          onClick={onCancel}
          data-testid='invoice-modal-cancel-btn'
        >
          <div
            className={classNames(styles.buttonText, {
              [styles.light]: theme === "light",
              [styles.dark]: theme === "dark",
            })}
          >
            <FormattedMessage id="invoice.modal.cancelBtn.text" />
          </div>
        </Button>
        <Button
          style={{ minWidth: "100px" }}
          variant="primary"
          onClick={onConfirm}
          isloading={isConfirmLoading}
          data-testid='invoice-modal-confirm-btn'
        >
          <FormattedMessage id="invoice.modal.confirmBtn.text" />
        </Button>
      </div>
    </Modal>
  );
};
