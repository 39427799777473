import { EThemeType } from "redux/reducers";

interface Props {
  EditIcon?: boolean;
  theme?: EThemeType;
}
export const EditIcon = ({ EditIcon, theme }: Props) => {
  if (EditIcon) {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="12"
        height="12"
        viewBox="0 0 12 12"
        fill="none"
      >
        <g clip-path="url(#clip0_19038_30128)">
          <path
            d="M1.5 8.62375V10.4987H3.375L8.9075 4.96625L7.0325 3.09125L1.5 8.62375ZM10.3525 3.52125C10.5475 3.32625 10.5475 3.00875 10.3525 2.81375L9.185 1.64625C8.99 1.45125 8.6725 1.45125 8.4775 1.64625L7.5625 2.56125L9.4375 4.43625L10.3525 3.52125Z"
            fill={theme === "dark" ? "#fff" : "#2A2831"}
          />
        </g>
        <defs>
          <clipPath id="clip0_19038_30128">
            <rect width="12" height="12" fill="white" />
          </clipPath>
        </defs>
      </svg>
    );
  } else {
    return (
      <svg
        width="16"
        height="16"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M3.75 16.25H4.66667L13.8958 7.02079L12.9792 6.10413L3.75 15.3333V16.25ZM16.5417 6.12496L13.875 3.45829L14.75 2.58329C14.9861 2.34718 15.2778 2.22913 15.625 2.22913C15.9722 2.22913 16.2639 2.34718 16.5 2.58329L17.4167 3.49996C17.6528 3.73607 17.7708 4.02774 17.7708 4.37496C17.7708 4.72218 17.6528 5.01385 17.4167 5.24996L16.5417 6.12496ZM15.6667 6.99996L5.16667 17.5H2.5V14.8333L13 4.33329L15.6667 6.99996ZM13.4375 6.56246L12.9792 6.10413L13.8958 7.02079L13.4375 6.56246Z"
          fill={EditIcon ? "#2A2831" : "white"}
        />
      </svg>
    );
  }
};

export const EditEmail = ({ theme }: Props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="13"
      height="14"
      viewBox="0 0 13 14"
      fill="none"
    >
      <path
        d="M11.5923 11.5871C11.5923 12.1863 11.1052 12.6917 10.5058 12.6917H1.81117C1.21176 12.6917 0.724605 12.1863 0.724605 11.5871V2.89513C0.724605 2.2959 1.21176 1.82658 1.81117 1.82658H6.88273V1.10287H1.81117C0.812374 1.10287 0 1.89732 0 2.89581V11.5871C0 12.5856 0.812374 13.4154 1.81117 13.4154H10.5051C11.5039 13.4154 12.3162 12.5849 12.3162 11.5871V6.53473H11.5916L11.5923 11.5871Z"
        fill={theme === "light" ? "#2A2831" : "#fff"}
      />
      <path
        d="M12.4088 0.992686C11.8617 0.445146 10.9072 0.445146 10.3595 0.992686L5.49951 5.85116C5.45325 5.89741 5.41991 5.95591 5.40426 6.01916L4.89261 8.06716C4.862 8.19028 4.89806 8.32087 4.98787 8.41133C5.05659 8.48003 5.14912 8.51744 5.24369 8.51744C5.27295 8.51744 5.3022 8.51404 5.33146 8.50656L7.38076 7.99439C7.44472 7.97874 7.50255 7.94541 7.54882 7.89916L12.4088 3.04069C12.6823 2.76726 12.8333 2.40336 12.8333 2.01635C12.8333 1.62933 12.683 1.26612 12.4088 0.992686ZM7.10793 7.31625L5.74173 7.6577L6.08328 6.29191L10.1036 2.27277L11.1283 3.29711L7.10793 7.31625ZM11.8965 2.52852L11.6406 2.78426L10.616 1.75992L10.8718 1.50418C11.1453 1.23075 11.6229 1.23075 11.8965 1.50418C12.0332 1.64089 12.1087 1.8225 12.1087 2.01635C12.1087 2.2102 12.0332 2.3918 11.8965 2.52852Z"
        fill={theme === "light" ? "#2A2831" : "#fff"}
      />
    </svg>
  );
};

export const AccountDeactivate = ({ theme }: Props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
    >
      <path
        d="M16.2808 3.95051C13.1372 3.3866 11.8374 2.96895 8.99997 1.6875C6.16251 2.96895 4.86278 3.3866 1.71911 3.95051C1.14958 12.9772 8.45786 16.0942 8.99997 16.3125C9.54208 16.0942 16.8504 12.9772 16.2808 3.95051Z"
        // stroke="#2A2831"
        stroke={theme === "light" ? "#2A2831" : "#fff"}
        stroke-width="0.8"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M8.98242 10.5523C9.27006 10.5523 9.50273 10.3192 9.50273 10.0461V5.86477C9.50273 5.57716 9.27006 5.34375 8.98242 5.34375C8.69479 5.34375 8.46211 5.57716 8.46211 5.86477V10.017C8.46211 10.3196 8.69479 10.5523 8.98242 10.5523ZM8.98242 11.3336C8.62324 11.3336 8.33203 11.6251 8.33203 11.9847C8.33203 12.3442 8.62324 12.6211 8.98242 12.6211C9.3416 12.6211 9.63281 12.3297 9.63281 11.9847C9.63281 11.6396 9.3416 11.3336 8.98242 11.3336Z"
        // fill="#2A2831"
        fill={theme === "light" ? "#2A2831" : "#fff"}
      />
    </svg>
  );
};
