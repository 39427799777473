import { TYPES } from "../../types";
import { api } from "../../api";
import { store } from "../../store";

export interface ITeam {
    id: number;
    name: string;
    slug: string;
};

export interface ISelectTeamBody {
    team_id?: number;
};

export interface ICurrentTeam {
    id: number;
    name: string;
    role: UserRolesType;
    slug: string;
};

export const UserRoles = {
    admin: "admin",
    manager: "manager",
    member: "member"
} as const;

export type UserRolesType = typeof UserRoles[keyof typeof UserRoles];

export interface IMember {
    id: number;
    team_id: number;
    name: string;
    email: string;
    role: UserRolesType;
    daily_limit: number;
    used_today: number;
    total_used_monthly?: number;
    created_at: string;
    is_expired?: boolean;
};

export interface IInviteMemberBody {
    email: string;
    role: UserRolesType;
    daily_limit: number;
    team_id: number;
    name: string;
};

export interface IAcceptInvitationBody {
    token: string;
};

export interface IRemoveMemberBody {
    user_id?: number;
    team_id?: number;
};

export interface IUpdateMemberRoleBody {
    team_id?: number;
    role?: UserRolesType;
    user_id?: number;
    name?: string;
};

export interface IUpdateMemberDailyLimitBody {
    user_id?: number;
    team_id?: number;
    credits: number;
};

export interface IInviteActionBody {
    invitation_id?: number;
};

export interface IEditEmail {
    email: string;
};

interface UpdateTeamMembersParams {
    data: ITeamName;
    user_id: number;
}

export interface IEditName {
    first_name: string;
    surname: string;
};

export interface ITeamName {
    name: string
}

export const setTeamUpdated = (switchTeam: boolean ) => {
    store.dispatch({ type: TYPES.ACCOUNT_SWITCH, payload: switchTeam });
  };

export const getAllTeams = () => {
    return new Promise((resolve, reject) => {
        api.get('/api/user/teams').then((res) => {
            resolve(res.data);
            store.dispatch({ type: TYPES.GET_ALL_TEAMS, payload: res.data });
        }).catch((err) => {
            reject(err);
        });
    });
};

export const selectTeam = (data: ISelectTeamBody) => {
    return new Promise((resolve, reject) => {
        api.post('/api/user/team/select', data).then((res) => {
            resolve(res.data);
            store.dispatch({ type: TYPES.SELECT_TEAM, payload: res.data });
        }).catch((err) => {
            reject(err);
        });
    });
};

export const getTeamMembers = () => {
    return new Promise((resolve, reject) => {
        api.get("/api/team/members").then((res) => {
            resolve(res.data.members);
            store.dispatch({ type: TYPES.GET_TEAM_MEMBERS, payload: res.data });
        }).catch((err) => {
            reject(err);
        });
    });
};

export const getTeamInfo = (team_id: number) => {
    return new Promise((resolve, reject) => {
        api.get(`/api/team/info?team_id=${team_id}`).then((res) => {
            resolve(res.data);
            store.dispatch({ type: TYPES.GET_TEAM_INFO, payload: res.data });
        }).catch((err) => {
            reject(err);
        });
    });
};

export const inviteTeamMember = (body: IInviteMemberBody) => {
    return new Promise((resolve, reject) => {
        api.post('/api/team/invite/send', body).then((res) => {
            resolve(res.data);
            store.dispatch({
                type: TYPES.INVITE_TEAM_MEMBER,
                payload: {
                    invitedMember: res.data
                }
            });
        }).catch((err) => {
            reject(err);
        });
    });
};

export const acceptTeamInvitation = (body: IAcceptInvitationBody) => {
    return new Promise((resolve, reject) => {
        api.post('/api/team/invite/accept', body).then((res) => {
            resolve(res.data);
            store.dispatch({ type: TYPES.VERIFY_EMAIL, payload: res.data });
        }).catch((err) => {
            reject(err);
        });
    });
};

export const removeTeamMember = (body: IRemoveMemberBody) => {
    return new Promise((resolve, reject) => {
        api.post('/api/team/remove', body).then((res) => {
            resolve(res.data);
            store.dispatch({
                type: TYPES.REMOVE_TEAM_MEMBER,
                payload: {
                    member_id: res.data.member_id
                }
            });
        }).catch((err) => {
            reject(err);
        });
    });
};

export const updateMemberRole = (body: IUpdateMemberRoleBody) => {
    return new Promise((resolve, reject) => {
        api.post('/api/team/role', body).then((res) => {
            resolve(res.data);
            store.dispatch({
                type: TYPES.UPDATE_MEMBER_ROLE,
                payload: {
                    member: body
                }
            });
        }).catch((err) => {
            reject(err);
        });
    });
};

export const updateMemberDailyLimit = (body: IUpdateMemberDailyLimitBody) => {
    return new Promise((resolve, reject) => {
        api.post('/api/team/credits', body).then((res) => {
            resolve(res.data);
            store.dispatch({
                type: TYPES.UPDATE_MEMBER_DAILY_LIMIT,
                payload: {
                    creditLimitMember: body
                }
            });
        }).catch((err) => {
            reject(err);
        });
    });
};

export const resendInvite = (body: IInviteActionBody) => {
    return new Promise((resolve, reject) => {
        api.post('/api/team/invite/resend', body).then((res) => {
            resolve(res.data);
            store.dispatch({ type: TYPES.RESEND_INVITE, payload: res.data });
        }).catch((err) => {
            reject(err);
        });
    });
};

export const cancelInvite = (body: IInviteActionBody) => {
    return new Promise((resolve, reject) => {
        api.post('/api/team/invite/cancel', body).then((res) => {
            resolve(res.data);
            store.dispatch({
                type: TYPES.CANCEL_INVITE,
                payload: {
                    invitation_id: res.data.invitation_id
                }
            });
        }).catch((err) => {
            reject(err);
        });
    });
};

// Update Member Name
export const updateTeamMembersName = ({ data, user_id }: UpdateTeamMembersParams) => {
    return new Promise((resolve, reject) => {
        const updatedData = {
            user_id,
            name: data.name,
        }
        api.put(`/api/team/members/${user_id}`, { name: data.name }).then((res) => {
            resolve(res.data);
            store.dispatch({
                type: TYPES.UPDATE_MEMBER_NAME,
                payload: {
                    member: updatedData
                }
            });
        }).catch((err) => {
            reject(err);
        });
    });
};