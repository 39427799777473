import { useWindowSize } from "hooks/useWindowSize";
interface Iprop{
  isActive?:boolean;
}
export const LightIcon = ({isActive}:Iprop) => {
  const { width } = useWindowSize();

  return(
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="22"
    height="22"
    viewBox="0 0 22 22"
    fill="none"
  >
    <path
      d="M14.2422 7.75893C16.0321 9.54883 16.0321 12.4508 14.2422 14.2407C12.4523 16.0306 9.5503 16.0306 7.7604 14.2407C5.97049 12.4508 5.97049 9.54883 7.7604 7.75893C9.5503 5.96903 12.4523 5.96903 14.2422 7.75893"
      stroke={width<=576 && isActive? "#527AE6" : "currentColor"}
      strokeOpacity="1"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M11.0013 3.66659V1.83325"
      stroke={width<=576 && isActive? "#527AE6" : "currentColor"}
      strokeOpacity="1"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M11.0013 20.1666V18.3333"
      stroke={width<=576 && isActive? "#527AE6" : "currentColor"}
      strokeOpacity="1"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M16.8302 5.16998L17.481 4.51915"
      stroke={width<=576 && isActive? "#527AE6" : "currentColor"}
      strokeOpacity="1"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M4.51964 17.4808L5.17047 16.8299"
      stroke={width<=576 && isActive? "#527AE6" : "currentColor"}
      strokeOpacity="1"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M18.334 10.9998H20.1673"
      stroke={width<=576 && isActive? "#527AE6" : "currentColor"}
      strokeOpacity="1"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M1.83398 10.9998H3.66732"
      stroke={width<=576 && isActive? "#527AE6" : "currentColor"}
      strokeOpacity="1"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M16.8302 16.8299L17.481 17.4808"
      stroke={width<=576 && isActive? "#527AE6" : "currentColor"}
      strokeOpacity="1"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M4.51964 4.51915L5.17047 5.16998"
      stroke={width<=576 && isActive? "#527AE6" : "currentColor"}
      strokeOpacity="1"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
};