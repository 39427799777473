import React from "react";
import classNames from "classnames";
import { useIntl } from "react-intl";
import styles from "../../chatFooter.module.scss";
import { useSelector } from "redux/hooks";
import { SelectedSubOptions } from "pages/ChatPage/ChatPage";
import { DropdownOption } from "redux/reducers";
import { RightArrow } from "views/layout/Navbar/components/ChatNav/icons/rightArrow";

interface ISubOptionProps {
  option: DropdownOption;
  selectedOption: string | null;
  selectedSubOptions: SelectedSubOptions;
  onClick: (value: string) => void;
}

const MainOption: React.FC<ISubOptionProps> = ({
  option,
  selectedOption,
  selectedSubOptions,
  onClick,
}) => {
  const { formatMessage } = useIntl();
  const { theme } = useSelector((state) => state.authReducer);
  const { PrivateChat } = useSelector((state) => state.chatReducer);

  const getBorderRadius = (value: string) => {
    if (PrivateChat && value === "outputFormats") return "10px 10px 0px 0px";
    if (value === "responseLengths") return "0px 0px 10px 10px";
    return "0px";
  };

  return (
    <div
      key={option.value}
      className={classNames(styles.dropdownOption, {
        [styles.selected]: selectedOption === option.value,
        [styles.light]: theme === "light",
        [styles.dark]: theme === "dark",
      })}
      onClick={() => onClick(option.value)}
      style={{ borderRadius: getBorderRadius(option.value) }}
    >
      {selectedSubOptions[option.value] ? (
        <div className={styles.labellink}>
          <div
            className={classNames(styles.outputFormatLabel, {
              [styles.light]: theme === "light",
              [styles.dark]: theme === "dark",
              [styles.darkSelected]:
                theme === "dark" && selectedOption === option.value,
            })}
          >
            {formatMessage({ id: `Roll.bot.${option.value}` })}
          </div>
          <div
            className={classNames(styles.selectedFormat, {
              [styles.light]: theme === "light",
              [styles.dark]: theme === "dark",
            })}
          >
            {option.value === "language" &&
              selectedSubOptions[option.value] !== "default"
              ?  (
                option.options.some(
                  (subOption) => subOption.value === selectedSubOptions[option.value]
                ) ? (
                  option.options.find(
                    (subOption) => subOption.value === selectedSubOptions[option.value]
                  )?.label
                ) : (
                  formatMessage({
                    id: `bot.setting.${option.value}.default`,
                  })
                )
              )
              :(
                 formatMessage({
                id: `bot.setting.${option.value}.${selectedSubOptions[option.value]}`,
              }))
              }
            <span className="absolute right-[6px]">
              <RightArrow BotDropdown={true} theme={theme} />
            </span>
          </div>
        </div>
      ) : (
        <span> {formatMessage({ id: `Roll.bot.${option.value}` })} </span>
      )}
    </div>
  );
};

export default MainOption;