import EditIcon from "pages/ChatPage/pages/chatHistory/components/icons/EditIcon";
import { ArrowRightIcon } from "../Icons";

import { IPrompt, deleteUserPRompts } from "redux/actions";
import DeleteIcon from "pages/ChatPage/pages/chatHistory/components/icons/DeleteIcon";
import CheveronDown, { CheveronDownsmall } from "pages/ChatPage/pages/chatHistory/components/icons/CheveronDown";
import classNames from "classnames";
import { useSelector } from "react-redux";
import { useState } from "react";
import { useAppNotification } from "hooks/services/AppNotification";
import { Spinner } from "components";
import styles from "./Prompt.module.scss";
import { useWindowSize } from "hooks/useWindowSize";

interface IProps {
  prompt: IPrompt;
  onSelect: (prompt: string) => void;
  dataTestId: string;
  setEditPrompt?: React.Dispatch<React.SetStateAction<number | null>>;
  setAllowEditPrompt?: React.Dispatch<React.SetStateAction<boolean>>;
}

export const PromptItem = ({ prompt, onSelect, dataTestId, setEditPrompt, setAllowEditPrompt }: IProps) => {
  const { theme } = useSelector(
    (state: any) => state.authReducer
  );
  const { triggerNotification } = useAppNotification();
  const [loading, setIsLoading] = useState<boolean>(false);
  const { width } = useWindowSize();

  const DeletePrompt = (e: React.MouseEvent<HTMLSpanElement, MouseEvent>,id: number) => {
    e.stopPropagation();
    setIsLoading(true)
    deleteUserPRompts(id)
      .then(() => {
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        triggerNotification({ message: err?.data?.message, type: "error" });
      });
  }

  const handleEditClick = (e: React.MouseEvent<HTMLSpanElement, MouseEvent>, id: number) => {
    e.stopPropagation();
    setAllowEditPrompt!(true);
    setEditPrompt!(id);
  };

  return (
    <div
      className={`flex flex-row text-lg animate-all duration-200 ease-in-out hover:rounded-md hover:cursor-pointer  hover:bg-[#EDF1FE] dark:hover:bg-[#2A3249] px-5 py-[10px]`}
      onClick={() => onSelect(prompt?.prompt)}
      data-testid={dataTestId}
    >
      <span className={`${styles.arrowIcon} flex flex-row mr-3 ${dataTestId === "user-prompts"? width <= 576? "mt-[6px]": "mt-[4px]": "mt-[-1.5px]"}`}>
        <ArrowRightIcon />
      </span>
      <span className={`${styles.Community} ${" flex-1 font-medium whitespace-nowrap overflow-hidden text-ellipsis opacity-90"}`}>
        <span className={`flex justify-between items-center gap-2 ${width <= 576 ? "pr-2" : "pr-4"}`}> <b className={`${theme==="light" ? styles.lightTitle : styles.darkTitle} w-4/5 overflow-hidden text-ellipsis text-sm`}>{prompt.title}</b>
          {dataTestId === 'user-prompts' &&
            <span className="flex items-center gap-2 mt-[6px]"><span className={classNames(styles.edit, {
              "stroke-[#2A2831]": theme === "light",
              "stroke-white": theme === "dark",
            })} onClick={(e)=>handleEditClick(e,prompt.id)}><EditIcon /></span>
            {loading? <div style={{ marginTop: "-5px" }}> <Spinner extraSmall /> </div>:
              <span className={classNames(styles.delete, {
                "stroke-[#2A2831]": theme === "light",
                "stroke-white": theme === "dark",
              })} onClick={(e) => DeletePrompt(e,prompt.id)} ><DeleteIcon /></span>
            }</span>    
          }
        </span>
        <div className={` flex-1 font-medium whitespace-nowrap overflow-hidden text-ellipsis text-[12px]  ${width<=576 ? "text-[12px] leading-5": ""} ${theme==="light" ? styles.lightHeading : styles.darkHeading}`}>
          {prompt.prompt}
        </div>
      </span>
      <span className={classNames("", {
        "mt-[3px]": dataTestId === 'user-prompts',
        "!fill-white": theme === "dark",
      })}>{width<=576 ? <CheveronDownsmall theme={theme}/>:<CheveronDown />}</span>

    </div>
  );
};
