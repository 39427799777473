

const DeleteIcon = () => {
  return (
    <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M13.6848 6.70605C13.6848 6.70605 13.3001 11.4767 13.077 13.4862C12.9708 14.446 12.3779 15.0084 11.4068 15.0261C9.55873 15.0594 7.70856 15.0616 5.86123 15.0226C4.92694 15.0035 4.34398 14.434 4.23985 13.4912C4.01531 11.4639 3.63281 6.70605 3.63281 6.70605"  strokeLinecap="round" strokeLinejoin="round"/>
<path d="M14.6682 4.41862H2.65625"  strokeLinecap="round" strokeLinejoin="round"/>
<path d="M12.3517 4.41962C11.7957 4.41962 11.3168 4.02649 11.2077 3.48178L11.0356 2.62045C10.9294 2.22308 10.5695 1.94824 10.1594 1.94824H7.16104C6.75092 1.94824 6.39108 2.22308 6.28483 2.62045L6.11271 3.48178C6.00362 4.02649 5.52479 4.41962 4.96875 4.41962"  strokeLinecap="round" strokeLinejoin="round"/>
</svg>
  )
}

export default DeleteIcon

export const DeleteIconsmall = () => {
  return (
<svg xmlns="http://www.w3.org/2000/svg" width="16" height="17" viewBox="0 0 16 17" fill="none">
<g id="8666703_trash_icon 1" clip-path="url(#clip0_23951_22929)">
<path id="Vector" d="M2 4.125H3.33333H14" stroke="#2A2831" stroke-linecap="round" stroke-linejoin="round"/>
<path id="Vector_2" d="M12.6663 4.12516V13.4585C12.6663 13.8121 12.5259 14.1513 12.2758 14.4013C12.0258 14.6514 11.6866 14.7918 11.333 14.7918H4.66634C4.31272 14.7918 3.97358 14.6514 3.72353 14.4013C3.47348 14.1513 3.33301 13.8121 3.33301 13.4585V4.12516M5.33301 4.12516V2.79183C5.33301 2.43821 5.47348 2.09907 5.72353 1.84902C5.97358 1.59897 6.31272 1.4585 6.66634 1.4585H9.33301C9.68663 1.4585 10.0258 1.59897 10.2758 1.84902C10.5259 2.09907 10.6663 2.43821 10.6663 2.79183V4.12516" stroke="#2A2831" stroke-linecap="round" stroke-linejoin="round"/>
</g>
<defs>
<clipPath id="clip0_23951_22929">
<rect width="16" height="16" fill="white" transform="translate(0 0.125)"/>
</clipPath>
</defs>
</svg>
  )
}
