export const BurgerIcon = () => {
  return (
    <svg
      width="21"
      height="16"
      viewBox="0 0 21 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        // opacity="0.8"
        d="M0 15.75V13.5H20.25V15.75H0ZM0 9.125V6.875H20.25V9.125H0ZM0 2.5V0.25H20.25V2.5H0Z"
        fill="white"
      />
    </svg>
  );
};

export const BurgerIconlg = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="34" height="33" viewBox="0 0 34 33" fill="none">
      <path d="M4.84387 9.74609H29.1542" stroke="white" stroke-width="2" stroke-linecap="round" />
      <path d="M4.84387 16.5H29.1542" stroke="white" stroke-width="2" stroke-linecap="round" />
      <path d="M4.84387 23.2539H29.1542" stroke="white" stroke-width="2" stroke-linecap="round" />
    </svg>
  );
}