import { FormattedMessage } from "react-intl";
import { EThemeType } from "redux/reducers";
import { useWindowSize } from "hooks/useWindowSize";
export interface IFaq {
  title: string;
  content: string;
}
interface IFaqProps {
  faqs: IFaq[];
  themeMode?: EThemeType;
}

export const Faqs: React.FC<IFaqProps> = ({ faqs, themeMode }) => {
  const { width } = useWindowSize();
  return (
    <div
    style={{
        backgroundImage: `url(/feature/bgImages/bg-004-${themeMode}.jpeg)`,
        backgroundSize: "cover",
        backgroundPosition: "center",
      }
    }
    >
     <div className={`text-light dark:text-[#fff]  w-fit max-w-[1200px] flex flex-col items-center mx-auto  ${ width<=576 ? "pt-40px pb-[35px]": "py-14 md:py-28"}`}>
        <h2 className={`bg-gradient-features-right bg-clip-text text-transparent ${width <=405 ? "text-[26px] w-[210px] mb-[35px] text-center leading-[36px]": width<=576 ? "text-[34px] mb-[35px] w-[283px] text-center leading-[57px]":"text-[20px] sm:text-[24px] md:text-[28px] lg:text-[32px] mb-10 md:mb-20 w-fit"}`}>
          <FormattedMessage id="feature.faq.title" />
        </h2>
        <div className={` ${width<=576? "px-10":"px-[10%] xl:px-0"}`}>
          {faqs.map((row) => (
            <div className="py-5" key={row.title}>
              <h4 className={` ${width <=405? "text-[21px] leading-[30px]": width<=576? "text-[24px] leading-[30px]":"text-[16px] md:text-[20px] leading-7 "}`}>
                <FormattedMessage id={row.title} />
              </h4>
              <p className={` mt-3 md:mt-4 opacity-70 font-medium  ${width<=405? "text-[16px] leading-[26px]": width<=576? "text-[18px] leading-[26px]":"text-[14px] md:text-[16px] leading-6 md:leading-7"}`}>
                <FormattedMessage id={row.content} />
              </p>
            </div>
          ))}
        </div>
      </div>
      </div>
  );
};
