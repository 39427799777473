import { useState, memo, lazy, Suspense } from "react";
import classNames from "classnames";
import styles from "./chatMiniSidebar.module.scss";
import { NavLink } from "react-router-dom";

import {
    PlanIcon,
    SettingIcon,
    FreeCreditsIcon,
    PlusIcon
} from "../icons";

import { Separator } from "components";
import { ThemeSwitch } from "../ThemeSwitch";

import useRouter from "hooks/useRouter";
import { RoutePaths } from "pages/routePaths";

import { useSelector } from "redux/hooks";
import links from "core/links";
import { ChatRoute } from "pages/ChatPage";
import { ChatSideBarFooter } from "../ChatSideBarFooter";
import { SearchIcon } from "../icons/SearchIcon";
import { HistoryIcon } from "views/layout/Navbar/components/icons";
import { useToggleSidebar } from "hooks/services/ToggleSidebarProvider";

const FreeCreditsModal = lazy(() =>
    import("pages/ChatPage/components/freeCreditsModal").then((module) => ({
        default: module.FreeCreditsModal,
    }))
);
interface IProps {
    onStartNewChat?: ({ toChat }: { toChat: boolean }) => void;
    isMainScreenOpen?: boolean;
    itemStyles?: any;
}

export const ChatMiniSidebar = memo(
    ({
        onStartNewChat, isMainScreenOpen, itemStyles,
    }: IProps) => {

        const { includeRoute } = useRouter();
        const { userDetail, theme } = useSelector((state) => state.authReducer);
        const { PrivateChat } = useSelector((state) => state.chatReducer);
        const { toggleSidebar } = useToggleSidebar();

        const [showReferralModal, setShowReferralModal] = useState<boolean>(false);

        const isButtonActive = (): boolean => {
            let isActive = false;
            if (includeRoute(ChatRoute.History)) {
                isActive = true;
            } else if (includeRoute(ChatRoute.New) && !isMainScreenOpen) {
                isActive = true;
            }
            return isActive;
        };

        return (
            <>
                <div className={styles.container}>
                    <div className={styles.headSection}>
                        <div className={`${styles.themeallow} mt-3`}>
                            <button
                                className={classNames(styles.newChatBtn, {
                                    [styles.light]: theme === "light",
                                    [styles.dark]: theme === "dark",
                                })}
                                onClick={() => {
                                    if (isButtonActive()) onStartNewChat?.({ toChat: true });
                                }}
                                data-testid='new-chat-btn'
                            >
                                <div className={styles.btnContent}>
                                    <PlusIcon />
                                </div>
                            </button>
                        </div>
                        <Separator />
                        <div className={`flex justify-center mt-4 cursor-pointer ${PrivateChat? styles.isbackdrop: ""}`} onClick={toggleSidebar}>
                            <SearchIcon chatsidebar={true}/>
                        </div>
                        <Separator />
                        <div className={`flex justify-center mt-4 cursor-pointer ${PrivateChat? styles.isbackdrop: ""}`} onClick={toggleSidebar}>
                            <HistoryIcon />
                        </div>
                    </div>
                    <div className={styles.footer}>
                        <div className={styles.Hidefootersetting}>
                            <ChatSideBarFooter itemStyles={itemStyles} toggleSidebar={toggleSidebar} userDetail={userDetail} theme={theme} />
                        </div>
                        <div className={styles.themeallow}>
                            <div
                                className={classNames(styles.itemContainer,{
                                    [styles.isbackdrop]: PrivateChat,
                                })}
                                onClick={() => setShowReferralModal(true)}
                                data-testid='sidebar-chat-newchat-earnCredit'
                            >
                                <FreeCreditsIcon />

                            </div>
                            <Separator height="18px" />
                            {userDetail?.user.team.role === "admin" && (
                                <>
                                    <NavLink
                                        className={classNames(styles.itemContainer,{
                                            [styles.isbackdrop]: PrivateChat,
                                        })}
                                        to={`/${RoutePaths.Settings}/${RoutePaths.CurrentPlan}`}
                                        data-testid='chat-sidebar-currentplan'
                                    >
                                        <PlanIcon />
                                    </NavLink>
                                    <Separator height="18px" />
                                </>
                            )}
                            <a
                                href={links.ChromeStorePluginUrl}
                                target="_blank"
                                rel="noreferrer"
                                className={classNames(styles.itemContainer,{
                                    [styles.isbackdrop]: PrivateChat,
                                })}
                                data-testid='chrome-store-plugin'
                            >
                                <img
                                    src="/ChromeIcon.svg"
                                    style={{ backgroundColor: "transparent" }}
                                />
                            </a>
                            <Separator height="18px" />
                            <NavLink
                                className={classNames(styles.itemContainer,{
                                    [styles.isbackdrop]: PrivateChat,
                                })}
                                to={`/${RoutePaths.Settings}`}
                                data-testid='sidebar-setting'
                            >
                                <SettingIcon />
                            </NavLink>
                        </div>
                        <div className=" pt-[18px]">
                            <ThemeSwitch minSidebar={true} />
                        </div>
                    </div>
                </div>
                <Suspense fallback={<></>}>
                    {showReferralModal && (
                        <FreeCreditsModal onClose={() => setShowReferralModal(false)} />
                    )}
                </Suspense>
            </>
        );
    }
);