import { useState, Fragment, Dispatch, SetStateAction } from "react";
import classNames from "classnames";
import styles from "./MDNav.module.scss";

import { PlusIcon } from "../icons";
import { FormattedMessage } from "react-intl";
import { RoutePaths } from "pages/routePaths";

import useRouter from "hooks/useRouter";
import { useAppNotification } from "hooks/services/AppNotification";
import { IChat } from "redux/actions";
import { useSelector } from "redux/hooks";
import { LogoutIcon } from "../AccountNav/icons";
import { logout } from "redux/actions";
import { Setting } from "../ChatNav/icons/setting";
import { ChatModel } from "../ChatNav/components/ChatModel";
import { Link } from "react-router-dom";
import { Credits } from "../ChatNav/components/Credits";

interface IProps {
  onStartNewChat?: ({ toChat }: { toChat: boolean }) => void;
  toggleChatModelsModel?: () => void;
  setChatSetting?: Dispatch<SetStateAction<boolean>>;
  chatItem?: IChat;
}

export const MDNav = ({
  onStartNewChat,
  toggleChatModelsModel,
  setChatSetting,
  chatItem,
}: IProps) => {
  const { pathname, includeRoute, push } = useRouter();
  const { triggerNotification } = useAppNotification();
  const userDetail = useSelector((state) => state.authReducer.userDetail);
  const themeMode = useSelector((state) => state.authReducer.theme);
  const isShareChat = window.location.pathname.includes("share-chat");

  const lang = userDetail?.user?.language ? userDetail?.user?.language : "en";

  //Logout Function
  const [isloading, setIsloading] = useState<boolean>(false);
  const onLogout = () => {
    setIsloading(true);
    logout()
      .then(() => {
        localStorage.removeItem('email');
        window.postMessage({ logout: true });
        setIsloading(false);
      })
      .catch((err: any) => {
        triggerNotification({ message: err?.data?.message, type: "error" });
        setIsloading(false);
      });
  };


  if (includeRoute(RoutePaths.Chat)) {
    return (
      <Fragment>
        <div
          className={classNames(styles.container, {
            [styles.light]: themeMode === "light",
            [styles.dark]: themeMode === "dark",
          })}
        >
          {isShareChat &&
            <Link to='/' className={styles.headerLogo} data-testid='share-header-logo'>
              <img src={require('../../../Sidebar/logo.png')} />
            </Link>
          }
          {userDetail && userDetail.token &&
            <ChatModel toggleChatModelsModel={toggleChatModelsModel} chatItem={chatItem} />
          }
          <div className={!isShareChat ? styles.menuContainer : styles.menuShareContainer}>
            {!isShareChat &&
              <>
                <div
                  data-testid='md-setting-new-chat'
                  className={styles.icon}
                  onClick={() => {
                    onStartNewChat?.({ toChat: true });
                  }}
                >
                  <PlusIcon
                    color= "#71717a" 
                  />
                </div>
                <div
                  data-testid='md-chat-setting-icon'
                  className={`${styles.icon} ${styles.settingIcon}`}
                  onClick={() => setChatSetting!(true)}
                ><Setting />
                </div>
                <div>
              <Credits mdNav={true}/>
            </div>
              </>
            }
            {isShareChat &&
              <div className={styles.shareChatbtn}>
                <button data-testid='share-chat-try-deftgpt-free' className={styles.shareChatTryBtn}
                  onClick={() => push(`/${lang}/${RoutePaths.Signup}`)}
                >
                  <FormattedMessage id='share.chat.header' />
                </button>
              </div>
            }
          </div>
        </div>
      </Fragment>
    );
  } else {
    return (
      <Fragment>
        <div
          className={classNames(styles.container, {
            [styles.light]: themeMode === "light",
            [styles.dark]: themeMode === "dark",
            [styles.teamMdNav]: includeRoute(RoutePaths.Team),
          })}
        >
          {(includeRoute(RoutePaths.Team) || includeRoute(RoutePaths.Account) || includeRoute(RoutePaths.CurrentPlan) ) && (
            <div
              className={classNames(styles.pageTitle, {
                [styles.account]: includeRoute(RoutePaths.Account),
              })}
            >
              {includeRoute(RoutePaths.Team) ? `My ${pathname.split("/")[2].charAt(0).toUpperCase()}${pathname.split("/")[2].slice(1)}` : includeRoute(RoutePaths.CurrentPlan) ? "Current Plan": pathname.split("/")[2]}
              </div>
          )}
          {!includeRoute(RoutePaths.Team) && <div className={styles.placeholder}></div>}
          {(includeRoute(RoutePaths.Account) || includeRoute(RoutePaths.Team) || includeRoute(RoutePaths.CurrentPlan) ) && (
            <button
              className={classNames(styles.logoutButton, {
                [styles.light]: themeMode === "light",
                [styles.dark]: themeMode === "dark",
              })}
              onClick={onLogout}
              disabled={isloading}
              id="logout"
              data-testid='account-setting-logout'
            >
              <div
                className={classNames(styles.btnContent, {
                  [styles.light]: themeMode === "light",
                  [styles.dark]: themeMode === "dark",
                })}
              >
                <LogoutIcon theme={themeMode} />
              </div>
            </button>
          )}
        </div>
       
      </Fragment>
    );
  }
};
