import { ChatRoute } from 'pages/ChatPage';
import { RoutePaths } from 'pages/routePaths';
import { useState, useCallback } from 'react';
import useRouter from "hooks/useRouter";
import { useAppNotification } from "hooks/services/AppNotification";
import {
    deleteAllChats, deleteAllConversation,
    deleteSelectedChats,
    IChat, removeMultipleChat, setPagination
} from "redux/actions";
import { DelFolderChats, EmptyDefaultFolder, EmptyFolderChats } from 'redux/actions/chatFolder';

export const useChatActions = (setChatDelConfirm?: React.Dispatch<React.SetStateAction<Record<number, boolean>>>,
    onStartNewChat?: ({ toChat }: { toChat: boolean }) => void, selectedItem?: IChat, loadMoreChats?: Function,
) => {

    const { triggerNotification } = useAppNotification();
    const { push } = useRouter();
    const [loading, setLoading] = useState<boolean>(false);
    const [selectedChatIds, setSelectedChatIds] = useState<Record<number | string, number[]>>({});
    const [manuallySelectedChatIds, setManuallySelectedChatIds] = useState<Record<number | string, number[]>>({});
    const [selectAllChat, setSelectAllChats] = useState<Record<number | string, boolean>>({});

    const resetStates = (folder_id: number | string) => {
        setLoading(false);
        deleteAllChats();
        setChatDelConfirm?.((prev) => ({ ...prev, [folder_id]: false }));
        setSelectedChatIds((prevSelectedChatIds) => ({
            ...prevSelectedChatIds,
            [folder_id]: [],
        }));
        setSelectAllChats((prev) => ({ ...prev, [folder_id]: false }));
    };

    const checkForRedirect = (folder_id: number | string) => {
        const currentPath = window.location.pathname;

        const pathSegments = currentPath.split('/');
        const chatIdFromUrl = pathSegments[pathSegments.length - 2];

        if (selectedItem && selectedChatIds[folder_id].includes(selectedItem.id)) {
            push(`/${RoutePaths.Chat}/${ChatRoute.New}`);
        }

        if (selectedChatIds[folder_id].includes(Number(chatIdFromUrl))) {
            onStartNewChat?.({ toChat: true });
        }
    }

    const handleSubmit = useCallback((options: { searchQuery: string; folder_id: number | string; }) => {
        const { searchQuery, folder_id } = options;
        setLoading(true);

        if (selectAllChat[folder_id] && searchQuery === '' && folder_id === 0) {
            resetStates(folder_id);
            setPagination(0, 0)
            EmptyDefaultFolder();
            checkForRedirect(folder_id);
            deleteAllConversation()
                .then(() => {
                    setSelectedChatIds((prevSelectedChatIds) => ({
                        ...prevSelectedChatIds,
                        [folder_id]: [],
                    }));
                })
                .catch((err) => {
                    setLoading(false);
                    triggerNotification({ message: err?.data?.message, type: 'error' });
                });
        }
        else if (selectAllChat[folder_id] && searchQuery === '' && typeof folder_id === "number") {
            EmptyFolderChats(folder_id);
            resetStates(folder_id);
            checkForRedirect(folder_id);
            DelFolderChats(folder_id).then(() => {
                resetStates(folder_id);
            }).catch((err) => {
                setLoading(false);
                triggerNotification({ message: err?.data?.message, type: 'error' });
            })
        }
        else {
            if (searchQuery && selectAllChat[folder_id]) {
                resetStates(folder_id);
                removeMultipleChat(selectedChatIds[folder_id], folder_id)
                    .then(() => {
                        checkForRedirect(folder_id);
                    })
                    .catch((err) => {
                        setLoading(false);
                        triggerNotification({ message: err?.data?.message, type: "error" });
                    });
            }
            else {
                removeMultipleChat(selectedChatIds[folder_id], folder_id)
                    .then(() => {
                        deleteSelectedChats(selectedChatIds[folder_id], folder_id);
                        setLoading(false);
                        setChatDelConfirm?.((prev) => ({ ...prev, [folder_id]: false }));
                        checkForRedirect(folder_id);
                        setSelectedChatIds((prevSelectedChatIds) => ({
                            ...prevSelectedChatIds,
                            [folder_id]: [],
                        }));
                        if (!searchQuery) {
                            loadMoreChats?.();
                        }
                    })
                    .catch((err) => {
                        setLoading(false);
                        triggerNotification({ message: err?.data?.message, type: 'error' });
                    });
            }
        }
    }, [selectedChatIds, triggerNotification, checkForRedirect, loadMoreChats]);

    const handleSelectId = (folder_id: number | string, id: number) => {

        setManuallySelectedChatIds((prev) => {
            const existingChats = prev[folder_id] || [];
            const isAlreadySelectedInSelectedChatIds = 
                (selectedChatIds[folder_id] || []).includes(id);
    
            if (existingChats.includes(id) || isAlreadySelectedInSelectedChatIds) {
                return {
                    ...prev,
                    [folder_id]: existingChats.filter((chatId) => chatId !== id),
                };
            } else {
                return {
                    ...prev,
                    [folder_id]: [...existingChats, id],
                };
            }
        });

        setSelectedChatIds((prev) => {
            const existingChats = prev[folder_id] || [];
            if (existingChats.includes(id)) {
                return {
                    ...prev,
                    [folder_id]: existingChats.filter((chatId) => chatId !== id),
                };
            } else {
                return {
                    ...prev,
                    [folder_id]: [...existingChats, id],
                };
            }
        });
        setSelectAllChats((prev) => ({ ...prev, [folder_id]: false }));
    };

    const handleCancel = useCallback((cancel: boolean, folder_id: number | string) => {
        setSelectAllChats((prev) => ({ ...prev, [folder_id]: false }));
        if (cancel) {
            setSelectedChatIds((prevSelectedChatIds) => ({
                ...prevSelectedChatIds,
                [folder_id]: manuallySelectedChatIds[folder_id] || [],
            }));
        } else {
            setTimeout(() => {
                setSelectedChatIds((prevSelectedChatIds) => ({
                    ...prevSelectedChatIds,
                    [folder_id]: [],
                }));
                setManuallySelectedChatIds((prevSelectedChatIds) => ({
                    ...prevSelectedChatIds,
                    [folder_id]: [],
                }));
            }, 15);
        }
        setChatDelConfirm?.((prev) => ({ ...prev, [folder_id]: false }));
    }, [manuallySelectedChatIds, selectAllChat]);

    return {
        loading,
        selectedChatIds,
        handleSubmit,
        handleSelectId,
        handleCancel,
        selectAllChat,
        setSelectAllChats,
        setSelectedChatIds,
        setManuallySelectedChatIds,
        manuallySelectedChatIds,
    };
};
