
import { EThemeType } from "redux/reducers";

interface Iprop {
  theme?: EThemeType | undefined
}
const CheveronDown = () => {
  return (
    <svg width="10" height="11" viewBox="0 0 12 8"  xmlns="http://www.w3.org/2000/svg">
    <path d="M6 7.4L0 1.4L1.4 0L6 4.6L10.6 0L12 1.4L6 7.4Z" />
    </svg>
  )
}

export default CheveronDown


export const CheveronDownsmall = ({theme}:Iprop)=>{
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="12" height="13" viewBox="0 0 12 13" fill="none">
<g id="9042706_nav_arrow_left_icon (1) 1">
<path id="Vector" d="M3 4.625L6 7.625L9 4.625" stroke={theme==="light"? "#2A2831": "white"} stroke-linecap="round" stroke-linejoin="round"/>
</g>
</svg>
);
}