import styled from "styled-components";
import { Theme } from "theme";

import { useSelector } from "redux/hooks";
import { EThemeType } from "redux/reducers";
import styles from "../sections/Hero.module.scss";
import { useRef, useState, useEffect } from "react";

import Lottie from "react-lottie";

import {
  AskDeftGPT,
  AskDeftGPTDark,
  GenerateAI,
  GenerateAIDark,
  UploadImage,
  UploadImageDark,
  UploadDocument,
  UploadDocumentDark,
  PrivateChat,
  BotFeature,
  BotFeatureDark,
  Language,
  LanguageDark,
} from "json";

interface IProps {
  gradientText: string;
  normalText?: string;
  description: string;
  $reverse?: boolean;
  backgroundUrl?: string;
  $leftMargin?: boolean;
  $document?: boolean;
  $botFeature?: boolean;
  $language?: boolean;
  $PrivateChat?: boolean;
  $uploadImage?: boolean;
  featureType: string;
}

const Section = styled.div<{
  $reverse?: boolean;
  $PrivateChat?: boolean;
  $document?: boolean;
  $uploadImage?: boolean;
  $botFeature?: boolean;
  $language?: boolean;
}>`
  display: flex;
  flex-direction: column-reverse;
  justify-content: center;
  align-items: center;
  padding-top: 80px;
  padding: 5% 2% 0%;

  @media screen and (max-width: 576px) {
    padding:  ${(props) => (props.$reverse || props.$language || props.$uploadImage ? "40px 23px 40px 23px": "40px 23px 5px 23px")};
  }

  @media screen and (min-width: 769px) {
    padding-top: 100px;
    padding: 6% 5% 2%;
  }

  @media screen and (min-width: 1024px) {
    flex-direction: ${(props) => (props.$reverse ? "row-reverse" : "row")};
    justify-content: space-between;
    padding: ${(props) =>
      props.$uploadImage
        ? "154px 132px 135px 120px"
        : props.$PrivateChat
          ? "110px 100px 80px 48px"
          : props.$document
            ? "110px 60px 80px 185px"
            : props.$botFeature
              ? "110px 60px 80px 185px"
              : props.$reverse
                ? "110px 46px 80px 185px"
                : "110px 100px 80px 81px"};
    gap: ${(props) =>
      props.$uploadImage
        ? "110px"
        : props.$language
          ? "80px"
          : props.$reverse
            ? "50px"
            : "20px"};
    align-items: ${(props) => (props.$language ? " center" : "flex-start")};
  }
  @media screen and (min-width: 1240px) {
    padding: ${(props) =>
      props.$uploadImage
        ? "154px 132px 135px 135px"
        : props.$PrivateChat
          ? "110px 100px 80px 48px"
          : props.$document
            ? "110px 65px 80px 232px"
            : props.$botFeature
              ? "110px 65px 80px 232px"
              : props.$language
                ? "110px 100px 80px 124px"
                : props.$reverse
                  ? "110px 46px 80px 232px"
                  : "110px 100px 80px 81px"};
  }
  @media screen and (min-width: 1492px) {
    align-items: ${(props) => (props.$language ? " center" : "flex-start")};
    gap: ${(props) =>
      props.$uploadImage
        ? "130px"
        : props.$language
          ? "96px"
          : props.$reverse
            ? "70px"
            : "28px"};
    padding: ${(props) =>
      props.$uploadImage
        ? "154px 132px 135px 135px"
        : props.$PrivateChat
          ? "154px 132px 135px 48px"
          : props.$document
            ? "154px 76px 135px 264px"
            : props.$botFeature
              ? "154px 76px 135px 264px"
              : props.$language
                ? "154px 132px 135px 124px"
                : props.$reverse
                  ? "154px 46px 135px 264px"
                  : "154px 132px 135px 91px"};
  }
  @media screen and (min-width: 1670px) {
    align-items: ${(props) => (props.$language ? " center" : "flex-start")};
    gap: ${(props) =>
      props.$uploadImage
        ? "140px"
        : props.$language
          ? "96px"
          : props.$reverse
            ? "70px"
            : "28px"};
    padding: ${(props) =>
      props.$uploadImage
        ? "154px 220px 135px 153px"
        : props.$PrivateChat
          ? "154px 220px 135px 48px"
          : props.$document
            ? "154px 76px 135px 264px"
            : props.$botFeature
              ? "154px 76px 135px 264px"
              : props.$language
                ? "154px 220px 135px 124px"
                : props.$reverse
                  ? "154px 46px 135px 264px"
                  : "154px 220px 135px 91px"};
  }
`;

const SectionRight = styled.div<{
  $reverse?: boolean;
  $document?: boolean;
  $uploadImage?: boolean;
  $PrivateChat?: boolean;
  $botFeature?: boolean;
  $language?: boolean;
}>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 90%;
  margin-bottom: 40px;
  white-space: normal;

  @media screen and (max-width: 576px) {
    margin-bottom: 60px;
    width: auto;
  }

  @media screen and (min-width: 1024px) {
    max-width: ${(props) =>
      props.$document
        ? "382px"
        : props.$PrivateChat
          ? "384px"
          : props.$botFeature
            ? "419px"
            : props.$reverse
              ? "335px"
              : "425px"};
    margin-top: ${(props) =>
      props.$uploadImage
        ? "12px"
        : props.$document
          ? "12px"
          : props.$PrivateChat
            ? "12px"
            : props.$botFeature
              ? "12px"
              : props.$language
                ? "0px"
                : "35px"};
    white-space: pre-line;
  }
  @media screen and (min-width: 1246px) {
    max-width: ${(props) =>
      props.$document
        ? "415px"
        : props.$PrivateChat
          ? "432px"
          : props.$botFeature
            ? "419px"
            : props.$reverse
              ? "335px"
              : "425px"};
    margin-top: ${(props) =>
      props.$uploadImage
        ? "40px"
        : props.$document
          ? "40px"
          : props.$PrivateChat
            ? "40px"
            : props.$botFeature
              ? "40px"
              : props.$language
                ? "0px"
                : "40px"};
    white-space: pre-line;
  }
  @media screen and (min-width: 1492px) {
    margin-top: ${(props) =>
      props.$uploadImage
        ? "12px"
        : props.$document
          ? "12px"
          : props.$PrivateChat
            ? "12px"
            : props.$botFeature
              ? "12px"
              : props.$language
                ? "0px"
                : "40px"};

    white-space: pre-line;
    max-width: ${(props) =>
      props.$uploadImage
        ? "584px"
        : props.$document
          ? "584px"
          : props.$PrivateChat
            ? "595px"
            : props.$botFeature
              ? "579px"
              : props.$language
                ? "584px"
                : props.$reverse
                  ? "483px"
                  : "681px"};
  }
  @media screen and (min-width: 1550px) {
    margin-top: ${(props) =>
      props.$uploadImage
        ? "40px"
        : props.$document
          ? "40px"
          : props.$PrivateChat
            ? "40px"
            : props.$botFeature
              ? "40px"
              : props.$language
                ? "0px"
                : "40px"};
  }
`;

const Title = styled.p<
  {
    themeMode?: EThemeType;
    $reverse?: boolean;
    $document?: boolean;
    $language?: boolean;
  } & { theme: Theme }
>`
  font-size: 26px;
  font-weight: 700;
  margin: 0px 0 18px 0;
  text-align: center;
  color: ${({ themeMode, theme }) =>
    themeMode === "dark" ? theme.white : theme.textColorLight};

  @media screen and (max-width: 576px) {
    margin-bottom: 25px;
    text-align: ${(props) => (props.$reverse ? "left" : "right")};
    font-size: 34px;
  }
 @media screen and (max-width: 405px) {
    font-size: 26px;
    width: ${(props) => (props.$reverse ? "285px": "")};
  }
  @media screen and (min-width: 1024px) {
    text-align: ${(props) => (props.$reverse ? "left" : "right")};
    font-size: 40px;
    line-height: 40px;
    margin: ${(props) =>
      props.$document
        ? "0 0 24px 0"
        : props.$language
          ? "0 0 24px -44px"
          : "0 0 24px 0"};
    width: ${(props) => (props.$language ? "475px" : "")};
  }
  @media screen and (min-width: 1492px) {
    font-size: 57px;
    line-height: normal;
    margin: ${(props) =>
      props.$document
        ? "0 0 24px 0"
        : props.$language
          ? "0 0 24px -44px"
          : "0 0 24px 0"};
    width: ${(props) => (props.$language ? "646px" : "")};
  }
  @media screen and (min-width: 1492px) {
    font-size: 60px;
    line-height: normal;
  }
`;

const DescriptionText = styled.p<
  {
    themeMode?: EThemeType;
    $reverse?: boolean;
    $document?: boolean;
    $botFeature?: boolean;
    $language?: boolean;
    $uploadImage?: boolean;
    $PrivateChat?: boolean;
  } & { theme: Theme }
>`
  text-align: center;
  line-height: 22px;
  color: ${({ themeMode, theme }) =>
    themeMode === "dark" ? theme.white : theme.textColorLight};

  opacity: ${({ themeMode }) => (themeMode === "dark" ? "0.7" : "70%")};
  font-weight: 400;

  @media screen and (min-width: 1024px) {
    line-height: normal;
    text-align: ${(props) => (props.$reverse ? "left" : "right")};
    font-size: 16px;
    width: ${(props) =>
      props.$botFeature ? "422px" : props.$reverse ? "356px" : ""};
    margin-left: ${(props) => (props.$uploadImage ? "7%" : "")};
  }
  @media screen and (min-width: 1246px) {
    margin-left: ${(props) =>
      props.$uploadImage
        ? "7%"
        : props.$PrivateChat
          ? "10%"
          : props.$language
            ? "3%"
            : ""};
  }
  @media screen and (min-width: 1492px) {
    line-height: normal;
    font-size: 22px;
    width: ${(props) =>
      props.$document
        ? "576px"
        : props.$botFeature
          ? "474px"
          : props.$reverse
            ? "476px"
            : ""};
  }
  @media screen and (max-width: 576px) {
    text-align: ${(props) => (props.$reverse ? "left" : "right")};
    font-size: 18px;
    margin-left: ${(props) => (props.$reverse ? "0px" : "33px")};
    margin-right: ${(props) => (props.$reverse ? "33px" : "0px")};
    line-height: 26px;
  }
      @media screen and (max-width: 405px) {
    font-size: 16px;
    margin-left: ${(props) => (props.$reverse ? "0px" : "27px")};
    margin-right: ${(props) => (props.$reverse ? "27px" : "0px")};
  }
`;

const GradientText = styled.span<{ themeMode?: EThemeType }>`
  background: ${({ themeMode }) =>
    themeMode === "dark"
      ? "linear-gradient(93deg, #4693E8 -41.18%, #6C53FF 100%)"
      : "linear-gradient(92.68deg, #4693E8 -41.18%, #6C53FF 100%)"};
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin-right: 8px;

   @media screen and (max-width: 576px) {
   margin-right: 3px;
  }

`;

export const FeatureSection = (props: IProps) => {
  const theme = useSelector((state) => state.authReducer.theme);
  const [isAnimationComplete, setIsAnimationComplete] = useState(false); // Track if the animation is complete
  const [isSectionVisible, setIsSectionVisible] = useState(false); // Track if section is visible
  const sectionRef = useRef<HTMLDivElement>(null);
  const [animationKey, setAnimationKey] = useState(0); // To force remount and restart the animation

  let animationData;

  switch (props.featureType) {
    case "askdeftgpt":
      animationData = theme === "light" ? AskDeftGPT : AskDeftGPTDark;
      break;
    case "generateai":
      animationData = theme === "light" ? GenerateAI : GenerateAIDark;
      break;
    case "uploadimage":
      animationData = theme === "light" ? UploadImage : UploadImageDark;
      break;
    case "uploaddocument":
      animationData = theme === "light" ? UploadDocument : UploadDocumentDark;
      break;
    case "privatechat":
      animationData = PrivateChat;
      break;
    case "botfeature":
      animationData = theme === "light" ? BotFeature : BotFeatureDark;
      break;
    case "language":
      animationData = theme === "light" ? Language : LanguageDark;
      break;
    default:
      animationData = AskDeftGPT;
  }

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        setIsSectionVisible(entry.isIntersecting);

        // When the section becomes visible, reset animation state and allow it to play again
        if (entry.isIntersecting) {
          setIsAnimationComplete(false);
          setAnimationKey((prevKey) => prevKey + 1);
        }
      },
      { threshold: 0.6 }
    );

    if (sectionRef.current) {
      observer.observe(sectionRef.current);
    }

    return () => {
      if (sectionRef.current) {
        observer.unobserve(sectionRef.current);
      }
    };
  }, []);

  const defaultOptions = {
    loop: false,
    autoplay: isSectionVisible && !isAnimationComplete,
    animationData,
    isStopped: isAnimationComplete, // Stop the animation on completion to show the last frame
    isPaused: !isSectionVisible || isAnimationComplete,
  };

  return (
    <Section
      ref={sectionRef}
      $reverse={props.$reverse}
      $PrivateChat={props.$PrivateChat}
      $uploadImage={props.$uploadImage}
      $document={props.$document}
      $botFeature={props.$botFeature}
      $language={props.$language}
      style={{
        backgroundImage: `url(${props.backgroundUrl})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
      }}
    >
      <div className={styles.xs_image}>
        {animationData && (
          <Lottie
            key={animationKey}
            options={defaultOptions}
            eventListeners={[
              {
                eventName: "complete",
                callback: () => {
                  setIsAnimationComplete(true); // Set animation complete state when it finishes
                },
              },
            ]}
          />
        )}
      </div>
      <SectionRight
        $reverse={props.$reverse}
        $uploadImage={props.$uploadImage}
        $document={props.$document}
        $PrivateChat={props.$PrivateChat}
        $botFeature={props.$botFeature}
        $language={props.$language}
      >
        <Title
          themeMode={theme}
          $reverse={props.$reverse}
          $document={props.$document}
          $language={props.$language}
        >
          <GradientText themeMode={theme}>{props.gradientText}</GradientText>
          {props.normalText}
        </Title>
        <DescriptionText
          themeMode={theme}
          $reverse={props.$reverse}
          $uploadImage={props.$uploadImage}
          $PrivateChat={props.$PrivateChat}
          $document={props.$document}
          $botFeature={props.$botFeature}
          $language={props.$language}
        >
          {props.description}
        </DescriptionText>
      </SectionRight>
    </Section>
  );
};
