import { EThemeType } from "redux/reducers";

interface Iprop {
  model?: boolean;
  theme?: boolean;
  themeMode?: EThemeType;
}
export const OptionsIcon = ({ model, theme }: Iprop) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={model ? "20" : "15"}
      height={model ? "20" : "15"}
      viewBox="0 0 15 15"
      fill="none"
    >
      <circle
        cx="7.5"
        cy="7.5"
        r="7.5"
        fill={theme ? "#fff" : "#2A2831"}
        fill-opacity="0.1"
      />
      <path
        d="M7.49975 6.52148C6.9604 6.52148 6.52148 6.9604 6.52148 7.49975C6.52148 8.03909 6.9604 8.47801 7.49975 8.47801C8.03909 8.47801 8.47801 8.03909 8.47801 7.49975C8.47801 6.9604 8.03909 6.52148 7.49975 6.52148Z"
        fill={theme ? "#fff" : "#2A2831"}
      />
      <path
        d="M7.49975 3.26074C6.9604 3.26074 6.52148 3.69966 6.52148 4.239C6.52148 4.77835 6.9604 5.21726 7.49975 5.21726C8.03909 5.21726 8.47801 4.77835 8.47801 4.239C8.47801 3.69966 8.03909 3.26074 7.49975 3.26074Z"
        fill={theme ? "#fff" : "#2A2831"}
      />
      <path
        d="M7.49975 9.78223C6.9604 9.78223 6.52148 10.2211 6.52148 10.7605C6.52148 11.2998 6.9604 11.7387 7.49975 11.7387C8.03909 11.7387 8.47801 11.2998 8.47801 10.7605C8.47801 10.2211 8.03909 9.78223 7.49975 9.78223Z"
        fill={theme ? "#fff" : "#2A2831"}
      />
    </svg>
  );
};

export const EditNameIcon = ({themeMode}: Iprop) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
    >
      <g clip-path="url(#clip0_25706_12432)">
        <path
          d="M11 22C17.0751 22 22 17.0751 22 11C22 4.92487 17.0751 0 11 0C4.92487 0 0 4.92487 0 11C0 17.0751 4.92487 22 11 22Z"
          fill= {themeMode === "dark"? "#2D2D2D" :"#F4F4F4"}
        />
        <path
          d="M15.0219 8.6793L16.7234 6.97773C16.7922 6.90898 16.7922 6.80586 16.7234 6.73711L15.2625 5.27617C15.1938 5.20742 15.0906 5.20742 15.0219 5.27617L13.3203 6.97773L15.0219 8.6793Z"
          fill={themeMode === "dark"? "#FFFFFF":"#2A2831"}
        />
        <path
          d="M12.8216 7.47656L6.53101 13.7672L5.77476 16.0016C5.72319 16.1391 5.86069 16.2594 5.99819 16.225L8.23257 15.4688L14.5232 9.17813L12.8216 7.47656Z"
          fill={themeMode === "dark"? "#FFFFFF":"#2A2831"}
        />
      </g>
      <defs>
        <clipPath id="clip0_25706_12432">
          <rect width="22" height="22" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export const SaveName = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        d="M12 1C9.82441 1 7.69767 1.64514 5.88873 2.85383C4.07979 4.06253 2.66989 5.78049 1.83733 7.79048C1.00477 9.80047 0.786929 12.0122 1.21137 14.146C1.6358 16.2798 2.68345 18.2398 4.22183 19.7782C5.76021 21.3166 7.72022 22.3642 9.85401 22.7886C11.9878 23.2131 14.1995 22.9952 16.2095 22.1627C18.2195 21.3301 19.9375 19.9202 21.1462 18.1113C22.3549 16.3023 23 14.1756 23 12C23 9.08262 21.8411 6.28473 19.7782 4.22183C17.7153 2.15893 14.9174 1 12 1ZM17.707 9.707L10.707 16.707C10.5195 16.8945 10.2652 16.9998 10 16.9998C9.73484 16.9998 9.48053 16.8945 9.293 16.707L6.293 13.707C6.11085 13.5184 6.01005 13.2658 6.01233 13.0036C6.01461 12.7414 6.11978 12.4906 6.30519 12.3052C6.49059 12.1198 6.74141 12.0146 7.0036 12.0123C7.2658 12.01 7.5184 12.1108 7.707 12.293L10 14.586L16.293 8.293C16.4816 8.11084 16.7342 8.01005 16.9964 8.01233C17.2586 8.0146 17.5094 8.11977 17.6948 8.30518C17.8802 8.49059 17.9854 8.7414 17.9877 9.0036C17.99 9.2658 17.8892 9.5184 17.707 9.707Z"
        fill="#47CE88"
      />
    </svg>
  );
};
