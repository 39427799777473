
import { useIntl } from "react-intl";
import * as yup from "yup";
import { Field, Formik, FieldProps } from "formik";

import styled from "styled-components";

import { FieldItem, Form } from "pages/AuthPages/components/FormComponents";
import {
    LabeledInput,
    Separator,
} from "components";
import { useSelector } from "redux/hooks";
import { FolderCR, FolderCRRes } from "redux/actions";
import { FolderCreate } from "redux/actions/chatFolder";
import { useAppNotification } from "hooks/services/AppNotification";
import { useState } from "react";

interface IProp {
    setCreateFolder: React.Dispatch<React.SetStateAction<boolean>>;
}

const FieldsRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

const FieldRowItem = styled(FieldItem)`
  border-radius: 10px;
  margin-bottom: 0;
`;

export const CreateFolder = ({setCreateFolder}: IProp) => {
    const { formatMessage } = useIntl();
    const { triggerNotification } = useAppNotification();
    const { theme, userDetail } = useSelector((state) => state.authReducer);
    const [loading, setIsLoading] = useState<boolean>(false);

    const AddMemberValidateionSchema = yup.object().shape({
        name: yup
            .string()
            .required("folder.name.empty.error")
    });

    const handleFolderCreation = (data: FolderCR) => {
        if(loading) return;
        setIsLoading(true);
        FolderCreate(data).then((res: FolderCRRes) => {
            setIsLoading(false);
            triggerNotification({ message: res?.message, type: "info" });
            setCreateFolder(false);
        }).catch((err) => {
            setIsLoading(false);
            triggerNotification({ message: err?.data?.message, type: "error" });
        })
    }

    return (
        <Formik
            onSubmit={(values) => {
                if (userDetail?.user) {
                    const data = {
                        name: values.name,
                        user_id: userDetail?.user?.id,
                    }
                    handleFolderCreation(data)
                }
            }}
            initialValues={{ name: "" }}
            validationSchema={AddMemberValidateionSchema}
            validateOnBlur={false}
            validateOnChange={true}
        >
            <Form data-testid='team-invite-form'>
                <Separator height="10px" />
                <FieldsRow>
                    <FieldRowItem>
                        <Field name="name">
                            {({ field, meta, form }: FieldProps<string>) => (
                                <LabeledInput
                                    {...field}
                                    CRFolder={true}
                                    placeholder={formatMessage({
                                        id: "folder.name",
                                    })}                                                     
                                    type="name"
                                    error={!!meta.error && meta.touched}
                                    message={
                                        meta.touched &&
                                        meta.error &&
                                        formatMessage({ id: meta.error })
                                    }
                                    variant={theme}
                                    autoFocus
                                    data-testid='create-folder-name'
                                    onClear={() => form.setFieldValue("name", "")}
                                    loading={loading}
                                />
                            )}
                        </Field>
                    </FieldRowItem>
                </FieldsRow>
            </Form>
        </Formik>
    );
};
