import React from "react";
import { getChatHistory, IActivePlan, IChat, IMessage, IUserDetail, setIsLoadMoreMessages } from "redux/actions";

export const SaveMessages = (messages: IMessage[], newMessages: IMessage[], chatHistoryPagination: IChat[], setChatHistory: React.Dispatch<React.SetStateAction<IChat[]>>, chatId: string) => {
  const currentPath = window.location.pathname;
  const pathSegments = currentPath.split('/');
  const newChatId = pathSegments[pathSegments.length - 1];
  const id = newMessages.length > 0 ? Number(newChatId) : messages[0]?.chat_id ?? Number(chatId);

  if ((newMessages.length > 0 || messages.length > 0) && chatHistoryPagination.length > 0) {
    const updatedNewMessages = newMessages.map((message) => {
      // Remove related_questions and isNEw
      const { related_questions, isNew, ...rest } = message;
      return rest;
    });
    const updatedRegularMessages = messages.map((message) => {
      // Remove related_questions and isNEw
      const { related_questions, isNew, ...rest } = message;
      return rest;
    });

    setChatHistory(prevChatHistory => {
      return prevChatHistory.map(chat =>
        chat.id === id
          ? {
            ...chat,
            messages: newMessages.length > 0 ? updatedNewMessages : updatedRegularMessages
          }
          : chat
      );
    });
    return;
  }
}

export const loadMoreMessages = (chatId: string | null,
  messagesPagination: { page: number; lastPage: number },
  contentContainerRef: React.RefObject<HTMLDivElement> | undefined) => {
  if (chatId) {
    setIsLoadMoreMessages(true);

    if (contentContainerRef && contentContainerRef.current) {
      const previousScrollHeight = contentContainerRef.current.scrollHeight;
      getChatHistory(JSON.parse(chatId), undefined, messagesPagination.page + 1)
        .then(() => {
          setIsLoadMoreMessages(false);
          requestAnimationFrame(() => {
            if (contentContainerRef && contentContainerRef.current) {
              const newScrollHeight = contentContainerRef.current.scrollHeight;
              const scrollDifference = newScrollHeight - (previousScrollHeight + 15);
              contentContainerRef.current.scrollTop = scrollDifference;
            }

          });
        })
        .catch(() => {
          setIsLoadMoreMessages(false);
        });
    }
  }
}

// Document Upload Varification

export const documentMaxCountReached = (currentPlan: IActivePlan | undefined, userDetail: IUserDetail | undefined) => {
  const max_count = currentPlan?.attributes?.max_document_chats;
  if (max_count && userDetail)
    return (
      userDetail?.user.activeSubscription.document_chat_count >= max_count
    );
};

// Video Upload Varification

export const videoMaxCountReached = (currentPlan: IActivePlan | undefined, userDetail: IUserDetail | undefined) => {
  const max_count = currentPlan?.attributes?.max_video_chats;
  if (max_count && userDetail)
    return (
      userDetail?.user.activeSubscription.video_chat_count >= max_count
    );
};

// Audio Upload Varification

export const audioMaxCountReached = (currentPlan: IActivePlan | undefined, userDetail: IUserDetail | undefined) => {
  const max_count = currentPlan?.attributes?.max_audio_chats;
  if (max_count && userDetail)
    return (
      userDetail?.user.activeSubscription.audio_chat_count >= max_count
    );
};