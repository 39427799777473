
import { FormattedMessage } from "react-intl";
import CutsomButton from "components/Button";
import { EThemeType } from "redux/reducers";

export const AFFILIATE_SIGN_UP_LINK = "https://deftgpt.firstpromoter.com/";
const AFFILIATE_SIGN_IN_LINK = "https://deftgpt.firstpromoter.com/login";

export const Hero = ({ theme }: { theme?: EThemeType }) => {
  return (
    <section
      className="pt-[60px] md:pt-[111px] pb-[111px] px-5 text-light dark:text-dark"
      style={{
        backgroundImage: `url(/affiliate/hero-bg-${theme}.png)`,
        backgroundSize: "cover",
        backgroundPosition: "center",
      }}
    >
      <div className="flex flex-col w-full items-center justify-center md:px-[5%] lg:px-0">
        <h2 className=" flex text-center text-[20px] sm:text-[24px] md:text-[28px] lg:text-[32px] bg-gradient-to-right bg-clip-text text-transparent w-fit">
          <FormattedMessage id="affiliate.hero.introducing" />
        </h2>
        <p className="text-[18px] sm:text-[22px] md:text-[26px] lg:text-[30px] font-bold text-center mt-5">
          <FormattedMessage id="affiliate.hero.title" />
        </p>
        <img
          src="/affiliate-hero-banner.svg"
          alt=""
          className="max-w-[80%] md:max-w-[70%] lg:max-w-[100%]"
        />

        <div className="flex flex-col md:flex-row items-center justify-center gap-8">
          <a
            data-testid="AFFILIATE_SIGN_UP_LINK"
            href={AFFILIATE_SIGN_UP_LINK}
            target="_blank"
            rel="noreferrer"
          >
            <CutsomButton variant="latest-primary">
              <span className="inline-block p-1">
                <FormattedMessage id="affiliate.nav.btn.sign-up.text" />
              </span>
            </CutsomButton>
          </a>
          <a
            data-testid="AFFILIATE_SIGN_IN_LINK"
            href={AFFILIATE_SIGN_IN_LINK}
            target="_blank"
            rel="noreferrer"
          >
            <CutsomButton variant="outlined-primary">
              <span className="text-primary">
                <FormattedMessage id="affiliate.nav.btn.sign-in.text" />
              </span>
            </CutsomButton>
          </a>
        </div>
      </div>
    </section>
  );
};
