import { Fragment } from "react";
import { FormattedMessage } from "react-intl";
import _ from "lodash";

import classNames from "classnames";
import styles from "./Chat.module.scss";

import { AssistanceIcon } from "../chatHistory/components/userName";

import { EChatType } from "redux/actions";
import { useSelector } from "redux/hooks";
interface IProps {
  updateChatModel?: (modelType: EChatType) => void;
  isFileUploading?: boolean;
}

export const Chat = ({
  // isFileUploading
  // isPrivateChat
}: IProps) => {
  const { theme, userDetail } = useSelector((state) => state.authReducer);
  const { PrivateChat } = useSelector(
    (state) => state.chatReducer
  );

  const getFirstName = (fullName: string | undefined) => {
    if (!fullName) return "";
    return fullName.split(" ")[0];
  };

  return (
    <Fragment>
      {/* {!isFileUploading && ( */}
      {!PrivateChat && (
        <div className="h-[100%]">
          <div
            className={classNames(styles.newContentContainer, {
              [styles.light]: theme === 'light',
              [styles.dark]: theme === 'dark',
            })}
          >
            <AssistanceIcon className={styles.logo} />
            <h1 className={styles.h1}>
              <FormattedMessage id="chatpage.new.heading" />
              {userDetail?.user?.name ? "," : "!"}
              {userDetail?.user?.name && (
                <span>{" "}
                  {getFirstName(userDetail?.user?.name)}
                  {"!"}</span>
              )}
            </h1>
            <p className={styles.message}>
              <FormattedMessage id="chatpage.new.subHeading" />
            </p>
          </div>
        </div>
      )}
      {/* )} */}
      {PrivateChat && (
        <div className={styles.privateContainer}>
          <AssistanceIcon className={styles.logo} />
          <h1 className={styles.private_h1}>
            <FormattedMessage id="PrivateChat.heading" />
          </h1>
          <p className={styles.private_message}>
            <FormattedMessage id="PrivateChat.subHeading" />
          </p>
        </div>
      )}

    </Fragment>
  );
};
