import { memo, useMemo, forwardRef } from "react";
import {
  CSSObjectWithLabel,
  GroupBase,
  Props,
  SelectComponentsConfig,
  StylesConfig,
} from "react-select";
import Select from "react-select/dist/declarations/src/Select";
import { EThemeType } from "redux/reducers";

import { equal, naturalComparatorBy } from "utils/objects";

import { DropdownIndicator } from "./components/DropdownIndicator";
import Menu from "./components/Menu";
import Option, { IDataItem } from "./components/Option";
import SingleValue from "./components/SingleValue";
import { CustomSelect } from "./CustomSelect";
import { SelectContainer } from "./SelectContainer";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type OptionType = any;

export interface DropdownProps<T = unknown> extends Props<OptionType> {
  variant?: EThemeType;
  withBorder?: boolean;
  $withBorder?: boolean;
  $background?: string;
  $textColor?: string;
  fullText?: boolean;
  error?: boolean;
  selectProps?: T;
  height?: number;
  account?: boolean;
  small?: boolean;
  inviteModel?: boolean;
  signup?:boolean;
  updateTeam?: boolean;
}



// eslint-disable-next-line react/function-component-definition
function DropDownInner<T = unknown>(
  props: DropdownProps<T>,
  ref: React.ForwardedRef<Select<unknown, boolean, GroupBase<unknown>>>
) {
  const propsComponents = props.components;

  const components = useMemo<
    SelectComponentsConfig<OptionType, boolean, GroupBase<unknown>>
  >(
    () =>
      ({
        // DropdownIndicator: DropdownIndicator,
        DropdownIndicator: (indicatorProps: any) => (
          <DropdownIndicator
            {...indicatorProps}
            account={props.account} 
            signup={props.signup}
          />
        ),
        SelectContainer: SelectContainer,
        Menu: (menuProps: any) => (
          <Menu variant={props.variant} account={props.account} {...menuProps} inviteModel={props.inviteModel} />
        ),
        Option: (optionProps: any) => (
          <Option
            variant={props.variant}
            {...optionProps}
            small={props.small}
            account={props.account}
            inviteModel={props.inviteModel}
            updateTeam={props.updateTeam}
          />
        ),
        SingleValue: (singleValueProps: any) => (
          <SingleValue
            variant={props.variant}
            {...singleValueProps}
            small={props.small}
            account={props.account}
            inviteModel={props.inviteModel}
          />
        ),
        IndicatorSeparator: null,
        ClearIndicator: null,
        MultiValueRemove: null,
        ...(propsComponents ?? {}),
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } as any),
    [propsComponents, props]
  );

  // undefined value is assumed to mean that value was not selected
  const currentValue =
    props.value !== undefined
      ? props.isMulti
        ? props.options?.filter((op) =>
            props.value.find((o: OptionType) => equal(o, op.value))
          )
        : props.options?.find((op) => equal(op.value, props.value))
      : null;

  const styles: StylesConfig = {
    ...(props.styles ?? {}),
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    menuPortal: (base: CSSObjectWithLabel, menuPortalProps: any) => ({
      ...(props.styles?.menuPortal?.(base, menuPortalProps) ?? { ...base }),
      zIndex: 999999,
    }),
  };

  return (
    <CustomSelect
      ref={ref}
      data-testid={props.name}
      $error={props.error}
      account={props.account}
      inviteModel={props.inviteModel}
      signup={props.signup}
      className="react-select-container"
      classNamePrefix="react-select"
      menuPortalTarget={document.body}
      placeholder="..."
      isSearchable={false}
      closeMenuOnSelect={!props.isMulti}
      hideSelectedOptions={false}
      {...props}
      styles={styles}
      value={currentValue ?? null}
      components={components}
    />
  );
}

export const defaultDataItemSort = naturalComparatorBy<IDataItem>(
  (dataItem) => dataItem.label || ""
);

export const DropDown = memo(forwardRef(DropDownInner));
