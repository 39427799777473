import { FormattedMessage } from "react-intl";
import classNames from "classnames";
import styles from "./ThemeSwitch.module.scss";

import { LightIcon, DarkIcon } from "../icons";

import { setTheme } from "redux/actions";
import { EThemeType } from "redux/reducers";
import { useSelector } from "redux/hooks";
import { useToggleSidebar } from "hooks/services/ToggleSidebarProvider";

interface IProp {
  minSidebar?: boolean;
  settingsSidebar?:boolean;
}

export const ThemeSwitch = ({ minSidebar, settingsSidebar }: IProp) => {
  const currentTheme = useSelector((state) => state.authReducer.theme);
  const { toggleSidebar } = useToggleSidebar();

  const onUpdateTheme = (theme: EThemeType) => {
    if (theme !== currentTheme) {
      window.postMessage({ theme });
      setTheme(theme);
    }
  };

  return (
    <div
      className={classNames(styles.theme, {
        [styles.themeLight]: currentTheme === "light",
        [styles.themeDark]: currentTheme === "dark",
        [styles.minsidebar]: minSidebar,
      })}
    >
      {minSidebar && currentTheme === 'light' ? <div
        data-testid='chat-theme-light'
        onClick={toggleSidebar}
        className={classNames(
          styles.themeBox,
          currentTheme === "light" && styles.dark,
          { [styles.minsidebar]: minSidebar ,
            [styles.settingsSidebar]:settingsSidebar,
          }
        )}
      >
        <LightIcon />
      </div> :
        minSidebar && currentTheme === 'dark' && (
          <div
            data-testid='chat-theme-dark'
            onClick={toggleSidebar}
            className={classNames(
              styles.themeBox,
              currentTheme === "dark" && styles.dark,
              { [styles.minsidebarDark]: minSidebar,
                [styles.settingsSidebar]:settingsSidebar,
               }
            )}
          >
            <DarkIcon />
          </div>
        )
      }
      {!minSidebar && (
        <>
          <div
            data-testid='chat-theme-light'
            onClick={() => onUpdateTheme("light")}
            className={classNames(
              styles.themeBox,
              currentTheme === "light" && styles.dark,
              { [styles.settingsSidebar]:settingsSidebar}
            )}
          >
            <LightIcon isActive={currentTheme === 'light' && settingsSidebar}/>
            <span className={styles.themeText}>
              <FormattedMessage id="theme.light" />
            </span>
          </div>
          <div
            data-testid='chat-theme-dark'
            onClick={() => onUpdateTheme("dark")}
            className={classNames(
              styles.themeBox,
              currentTheme === "dark" && styles.dark,
              { [styles.settingsSidebar]:settingsSidebar}
            )}
          >
            <DarkIcon isActive={currentTheme === 'dark' && settingsSidebar}/>
            <span className={styles.themeText}>
              <FormattedMessage id="theme.dark" />
            </span>
          </div>
        </>
      )}
    </div>
  );
};
