import { EThemeType } from "redux/reducers";

interface Iprop {
  theme?: EThemeType | undefined;
  signup?:boolean;
}
export const AccountArrowIcon=({theme,signup}:Iprop)=>{
  const getStrokeColor = () => {
    if (theme === "light" && signup) return "#A09FA2";
    if (theme === "dark" && signup) return "#737377";
    return theme === "light" ? "#2A2831" : "white";
  };
    return(
<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
  <path d="M8 10L12 14L16 10" stroke={getStrokeColor()} stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
</svg>
    );
}