import { FormattedMessage, useIntl } from "react-intl";
import * as yup from "yup";
import { Field, Formik, FieldProps } from "formik";

import styles from "../EditAccount/EditAccount.module.scss";
import styled from "styled-components";

import { CloseIcon } from "components/icons/CloseIcon";

import { FieldItem, Form } from "pages/AuthPages/components/FormComponents";
import {
    LabeledInput,
    Modal,
} from "components";
import CustomButton from "components/Button";

import { useSelector } from "redux/hooks";
import { useWindowSize } from "hooks/useWindowSize";
import { IEditName, ITeamName } from "redux/actions";

interface IProps {
    onClose?: () => void;
    onSubmit?: (data: IEditName) => void;
    onSubmitTeamName?: (data: ITeamName) => void;
    loading?: boolean;
    team?: boolean;
}

const FieldsRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  gap: 10px;
    @media (max-width: 576px) {
          display: flex;
          flex-direction:column;
          gap:10px;
        }
`;

const FieldRowItem = styled(FieldItem)`
  border-radius: 5px;
  margin-bottom: 20px;
  padding-bottom: 0;
`;

export const EditName = ({
    onClose,
    onSubmit,
    loading,
    team,
    onSubmitTeamName,
}: IProps) => {

    const { formatMessage } = useIntl();
    const { theme } = useSelector((state) => state.authReducer);
    const { width } = useWindowSize();

    const AddMemberValidationSchema = yup.object().shape({
        first_name: yup
            .string()
            .min(3, "update.firstName.minLength").max(20, "error.firstname.error.max")
            .required("signup.firstName.empty.error"),
        surname: yup
            .string()
            .min(3, "update.surName.minLength").max(20, "error.surname.error.max")
            .required("signup.surName.empty.error"),
    });

    const AddNameValidation = yup.object().shape({
        name: yup
            .string()
            .min(3, "Invite.name.empty.error.min").max(30, "Invite.name.empty.error.max")
            .required("Invite.name.empty.error"),
    })

    return (
        <>
            <Modal size="sm" onClose={onClose} updateEmail>
                <div className="py-[20px] px-[20px]">
                    <div className={styles.header}>
                        <div className={styles.headerTextContainer}>
                            <div className={styles.title}>
                                <FormattedMessage id="modal.updateName.heading" />
                            </div>
                        </div>
                        <div
                            className={styles.crossBtn}
                            onClick={onClose}
                            data-testid="team-form-cross-btn"
                        >
                            <CloseIcon />
                        </div>
                    </div>
                    <Formik
                        initialValues={team
                            ? { name: "" } // If team is true, initialize only `name`
                            : { first_name: "", surname: "" } // If team is false, initialize `first_name`
                        }
                        validationSchema={team ? AddNameValidation : AddMemberValidationSchema}
                        validateOnBlur={false}
                        onSubmit={(values, { setSubmitting }) => {
                            if (team) {
                                onSubmitTeamName?.({ name: values.name ?? "" });
                            } else {
                                onSubmit?.({
                                    first_name: values.first_name ?? "",
                                    surname: values.surname ?? ""
                                });
                            }
                            setSubmitting(false); // Reset after submission
                        }}
                        validateOnChange
                    >
                        {() => (
                            <div className="w-[100%]">
                                <Form data-testid="account-updateEmail-form">
                                    <FieldsRow style={{ paddingTop: "20px" }}>
                                        <FieldRowItem>
                                            <Field name={team ? "name" : "first_name"}>
                                                {({ field, meta }: FieldProps<string>) => (
                                                    <LabeledInput
                                                        updateEmail={true}
                                                        UpdateName={true}
                                                        {...field}
                                                        placeholder={formatMessage({
                                                            id: team ? "team.table.th.name" : "signup.firstName.placeholder",
                                                        })}
                                                        type="text"
                                                        variant={theme}
                                                        error={!!meta.error && meta.touched}
                                                        message={
                                                            meta.touched &&
                                                            meta.error &&
                                                            formatMessage({ id: meta.error })
                                                        }
                                                        data-testid="account-updateEmail-email"
                                                    />
                                                )}
                                            </Field>
                                        </FieldRowItem>
                                    </FieldsRow>
                                    {!team && (
                                        <FieldsRow>
                                            <FieldRowItem>
                                                <Field name="surname">
                                                    {({ field, meta }: FieldProps<string>) => (
                                                        <LabeledInput
                                                            updateEmail={true}
                                                            UpdateName={true}
                                                            {...field}
                                                            placeholder={formatMessage({
                                                                id: "signup.surName.placeholder",
                                                            })}
                                                            type="text"
                                                            variant={theme}
                                                            data-testid="account-updateName-name"
                                                            error={!!meta.error && meta.touched}
                                                            message={
                                                                meta.touched &&
                                                                meta.error &&
                                                                formatMessage({ id: meta.error })
                                                            }
                                                        />
                                                    )}
                                                </Field>
                                            </FieldRowItem>
                                        </FieldsRow>
                                    )}
                                    <FieldsRow>
                                        <div className="flex justify-center w-[100%]">
                                            <CustomButton
                                                style={{
                                                    padding: width <= 576 ? "8px 12px 8px 12px" : "10px 16px 10px 14px",
                                                    borderRadius: "5px",
                                                    background:
                                                        "var(--btn-color, linear-gradient(93deg, #4693E8 -41.18%, #6C53FF 100%))",
                                                    fontSize: width <= 576 ? "12px" : "16px",
                                                }}
                                                type="submit"
                                                isloading={loading}
                                                data-testid="account-btn-updateEmail"
                                            >
                                                <FormattedMessage id="modal.updateName.submit" />
                                            </CustomButton>
                                        </div>
                                    </FieldsRow>
                                </Form>
                            </div>
                        )}
                    </Formik>
                </div>
            </Modal >
        </>
    );
};
