

const EditIcon = () => {
  return (
    <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M8.13756 1.97559H5.4891C3.31098 1.97559 1.94531 3.51763 1.94531 5.70071V11.5898C1.94531 13.7729 3.3046 15.3149 5.4891 15.3149H11.7394C13.9246 15.3149 15.2839 13.7729 15.2839 11.5898V8.73663"  strokeLinecap="round" strokeLinejoin="round"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M6.25075 7.73568L11.5441 2.4423C12.2036 1.78355 13.2725 1.78355 13.9319 2.4423L14.794 3.30435C15.4534 3.9638 15.4534 5.03339 14.794 5.69214L9.47508 11.011C9.18679 11.2993 8.79579 11.4615 8.38779 11.4615H5.73438L5.80096 8.78401C5.81088 8.39018 5.97167 8.01476 6.25075 7.73568Z"  strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M10.7422 3.25977L13.9764 6.49402" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>    
  )
}

export default EditIcon

export const EditIconsmall = () => {
  return (  
    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="19" viewBox="0 0 18 19" fill="none">
    <g id="1976055_edit_edit document_edit file_edited_editing_icon (1) 1">
    <path id="Vector" d="M14.7053 14.1845C14.7053 14.8453 14.1683 15.4025 13.5075 15.4025H3.92326C3.26251 15.4025 2.72551 14.8453 2.72551 14.1845V4.60027C2.72551 3.93952 3.26251 3.42202 3.92326 3.42202H9.51376V2.62402H3.92326C2.82226 2.62402 1.92676 3.50002 1.92676 4.60102V14.1845C1.92676 15.2855 2.82226 16.2005 3.92326 16.2005H13.5068C14.6078 16.2005 15.5033 15.2848 15.5033 14.1845V8.61352H14.7045L14.7053 14.1845Z" fill="#2A2831"/>
    <path id="Vector_2" d="M15.6056 2.50262C15.0026 1.89887 13.9504 1.89887 13.3466 2.50262L7.9894 7.85987C7.9384 7.91087 7.90165 7.97537 7.8844 8.04512L7.3204 10.3034C7.28665 10.4391 7.32639 10.5831 7.42539 10.6829C7.50114 10.7586 7.60315 10.7999 7.7074 10.7999C7.73965 10.7999 7.77189 10.7961 7.80414 10.7879L10.0631 10.2231C10.1336 10.2059 10.1974 10.1691 10.2484 10.1181L15.6056 4.76087C15.9071 4.45937 16.0736 4.05812 16.0736 3.63137C16.0736 3.20462 15.9079 2.80412 15.6056 2.50262ZM9.76239 9.47537L8.2564 9.85187L8.6329 8.34587L13.0646 3.91412L14.1941 5.04362L9.76239 9.47537ZM15.0409 4.19612L14.7589 4.47812L13.6294 3.34862L13.9114 3.06662C14.2129 2.76512 14.7394 2.76512 15.0409 3.06662C15.1916 3.21737 15.2749 3.41762 15.2749 3.63137C15.2749 3.84512 15.1916 4.04537 15.0409 4.19612Z" fill="#2A2831"/>
    </g>
    </svg>
  )
}
