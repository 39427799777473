

import classNames from "classnames";
import styles from "./CurrentPlanNav.module.scss";

import { useSelector } from "redux/hooks";

import { useToggleSidebar } from "hooks/services/ToggleSidebarProvider";

export const CurrentPlanNav = () => {
  const { isOpen } = useToggleSidebar();
  const { theme } = useSelector((state) => state.authReducer);

  return (
    <div
      className={classNames(styles.container, {
        [styles.light]: theme === "light",
        [styles.dark]: theme === "dark",
      })}
    >
      <div
        className={classNames(styles.heading, {
          [styles.isSidebarOpen]: isOpen,
        })}
      >
        {/* <FormattedMessage id="navbar.setting.currentPlan.title" /> */}
      </div>
    </div>
  );
};
