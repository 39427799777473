
interface IProps {
    onClick?: () => void;
}

export const LogoSmall = ({  onClick }: IProps) => {
    return (
        <>
            <div
                className="md:mr-10 flex items-center"
                data-testid="logo-header-xs"
                onClick={onClick}
            >
                <img
                    src="/Logoheader.svg"
                    alt="Logoheader"
                />
            </div>
        </>
    );
};