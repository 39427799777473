
import classNames from "classnames";
import { FormattedMessage } from "react-intl";
import styles from "./TeamMdHead.module.scss";
import { useWindowSize } from "hooks/useWindowSize";
import { useSelector } from "redux/hooks";
import CustomButton from "components/Button";
import { InviteMemberModal } from "pages/SettingsPage/pages/Team/components/InviteMemberModal/InviteMemberModal";

import { useAppNotification } from "hooks/services/AppNotification";
import { inviteTeamMember, IInviteMemberBody } from "redux/actions";
import { useIntl } from "react-intl";
import { useState } from "react";
import { PlusIcon } from "views/layout/Navbar/components/icons/PlusIcon";
import { theme as themeMode } from "theme";

interface IProps {
    invitation?: boolean;
}

export const TeamMdHead = ({ invitation }: IProps) => {
    const { width } = useWindowSize();
    const { theme } = useSelector((state) => state.authReducer);
    const userDetail = useSelector((state) => state.authReducer.userDetail);
    const { triggerNotification } = useAppNotification();
    const { formatMessage } = useIntl();

    const [inviteLoading, setInviteLoading] = useState<boolean>(false);
    const [inviteModal, setInviteModal] = useState<boolean>(false);

    const toggleInviteModal = () => {
        if (inviteLoading) return;
        setInviteModal((prev) => {
            if (!prev) {
                if (
                    userDetail?.user.activeSubscription.name === "Free" ||
                    userDetail?.user.activeSubscription.name === "Standard"
                ) {
                    triggerNotification({
                        message: "invite.teamMember.error",
                        type: "info",
                    });
                    return false;
                } else {
                    return true;
                }
            } else {
                return false;
            }
        });
    };
    const onInviteTeamMember = (data: IInviteMemberBody) => {
        setInviteLoading(true);
        inviteTeamMember(data)
            .then(() => {
                setInviteLoading(false);
                toggleInviteModal();
                triggerNotification({
                    message: formatMessage(
                        { id: "inivation.send.message.success" },
                        { email: data.email }
                    ),
                    type: "info",
                });
            })
            .catch((err) => {
                setInviteLoading(false);
                triggerNotification({ message: err?.data?.message, type: "error" });
            });
    };

    return (
        <>
            {(width <= 768 || (invitation && width <= 862)) && (
                <>
                {!invitation &&(
                        <CustomButton teamNav={true} teamMdNav={true} teamMdHead={true} variant="outline-primary" data-testid='toggle-invite-modal' onClick={toggleInviteModal}>
                            <div
                                className={classNames(styles.btnContent, {
                                    [styles.light]: theme === "light",
                                    [styles.dark]: theme === "dark",
                                    [styles.teamMdNav]: true,
                                })}
                            >
                                <PlusIcon color={theme === 'dark' ? '#181819' : themeMode.white} />
                                <span className={styles.btntext} >
                                    <FormattedMessage id="team.page.inviteTeam.btnText" />
                                </span>
                            </div>
                        </CustomButton>
                        )}
                    <div className={classNames(styles.MdNavhead, {
                        [styles.pendingInvite]: invitation,
                        [styles.light]: theme === 'light',
                        [styles.dark]: theme === 'dark',
                    })}>
                        <div className={classNames(styles.MdText)}>
                            <h1 className={classNames(styles.title, {
                                [styles.dark]: theme === 'dark',
                                [styles.light]: theme === 'light',
                                [styles.pendingInvite]: invitation,
                            })}>
                                {invitation ? <FormattedMessage id="invite.teamMember.pending" /> : <FormattedMessage id="team.page.heading" />}
                            </h1>
                            {!invitation && (
                                <span className={classNames(styles.text, {
                                    [styles.dark]: theme === 'dark',
                                    [styles.light]: theme === 'light',
                                })}>
                                    <FormattedMessage id="team.page.subHeading" />
                                </span>
                            )}
                        </div>
                    </div>

                    {inviteModal && (
                        <InviteMemberModal
                            userRole={userDetail?.user?.team?.role}
                            userDetail={userDetail}
                            onClose={toggleInviteModal}
                            onSubmit={onInviteTeamMember}
                            submitLoading={inviteLoading}
                        />
                    )}
                </>

            )}

        </>
    );
};
