import _ from "lodash";
import { TYPES } from "../types";
import { produce } from "immer";
import { getFolder } from "redux/actions";

export interface IChatFolderReducerState {
  Folders: getFolder[];
  folderID: number | null;
  accordionState: { [folder_Id: number]: boolean };
}

const state: IChatFolderReducerState = {
  Folders: [],
  folderID: null,
  accordionState: {},
};

export const chatFolderReducer = (
  mState = { ...state },
  action: any
): IChatFolderReducerState => {
  switch (action.type) {

    case TYPES.SHOW_FOLDERS:
      return produce(mState, (dState) => {
        dState.Folders = [...action.payload.reverse()];
      });

    case TYPES.DEL_FOLDER:
      return produce(mState, (dState) => {
        dState.Folders = dState.Folders.filter(
          (chat) => chat.id !== action.payload
        );
      });

    case TYPES.UPDATE_FOLDER:
      return produce(mState, (dState) => {
        let index = dState.Folders.findIndex(
          (chat) => chat.id === action.payload.id
        );
        if (index >= 0) dState.Folders[index].name = action.payload.name;
      });

    case TYPES.ADD_FOLDER:
      return produce(mState, (dState) => {
        dState.Folders.unshift(action.payload);
      });

    case TYPES.FOLDER_NAME:
      return produce(mState, (dState) => {
        dState.folderID = action.payload;
      });

    case TYPES.SET_ACCORDION_STATE:
      return produce(mState, (dState) => {
        dState.accordionState = { ...dState.accordionState, ...action.payload };
      });

    case TYPES.ADD_NEW_CHAT:
      return produce(mState, (dState) => {
        const folder = dState.Folders.find((f) => f.id === action.payload.folder_id);
        if (folder) {
          const existingChat = folder.chats.find((chat) => chat.id === action.payload.chat.id);
          if (existingChat) {
            existingChat.topic = action.payload.chat.topic;
          } else {
            folder.chats.unshift(action.payload.chat);
          }
        }
      });

    case TYPES.UPDATE_CHAT_TOPIC:
      return produce(mState, (dState) => {
        const folder = dState.Folders.find((f) => f.id === action.payload.folder_id);

        if (folder) {
          let Index = folder.chats.findIndex(
            (chat) => chat.id === action.payload.id
          );
          if (Index >= 0) {
            folder.chats[Index].topic = action.payload.topic;
          }
        }
      })

    case TYPES.DELETE_CHAT_FOLDER:
      return produce(mState, (dState) => {
        const folder = dState.Folders.find((f) => f.id === action.payload.folder_id);
        if (folder)
          folder.chats = folder.chats.filter(
            (chat) => chat.id !== action.payload.id // Keep chats where the id is not equal to the payload's chat_id
          );
      })

    case TYPES.DELETE_SELECTED_CHAT_FOLDER:
      return produce(mState, (dState) => {
        const folder = dState.Folders.find((f) => f.id === action.payload.folder_id);
        if (folder)
          folder.chats = folder.chats.filter(
            (chat) => !action.payload.selectedChatIds.includes(chat.id)// Keep chats where the id is not equal to the payload's chat_id
          );
      })

    case TYPES.DELETE_MULTIPLE_CHAT_HISTORY_FOLDER:
      return produce(mState, (dState) => {
        const folder = dState.Folders.find((f) => f.id === action.payload.folder_id);
        if (folder)
          folder.chats = folder.chats.filter(
            (chat) => chat.id !== action.payload.includes(chat.id)
          );
      })

    case TYPES.APPEND_CHAT_FOLDER:
      return produce(mState, (dState) => {
        const folder = dState.Folders.find((f) => f.id === action.payload.folder_id);
        const lastFolder = dState.Folders[dState.Folders.length - 1];
        if (folder) {
          folder.chats = [...lastFolder.chats, ...action.payload.chat];
          folder.pagination = { ...action.payload.ScrollPagination }
        }
        else if (lastFolder) {
          lastFolder.chats = [...lastFolder.chats, ...action.payload.chat];
          lastFolder.pagination = { ...action.payload.ScrollPagination }
        }
      })

    case TYPES.GET_FOLDER_CHAT:
      return produce(mState, (dState) => {
        const folder = dState.Folders.find((f) => f.id === action.payload.folder_id);
        if (folder) {
          folder.chats = [...folder.chats, ...action.payload.chats];
          folder.pagination = { ...action.payload.pagination };
        }
      })

    case TYPES.EMPTY_FOLDER:
      return produce(mState, (dState) => {
        dState.Folders = [];
        dState.accordionState = {};
      });

    case TYPES.DEL_FOLDER_CHATS:
      return produce(mState, (dState) => {
        const folder = dState.Folders.find((f) => f.id === action.payload);
        if (folder) {
          folder.chats = [];
        }
      })

    case TYPES.EMPTY_DEFAULT_FOLDER_CHATS:
      return produce(mState, (dState) => {
        const lastFolder = dState.Folders[dState.Folders.length - 1];
        if (lastFolder) {
          lastFolder.chats = [];
          lastFolder.pagination = { page: 1, lastPage: 1 };
        }
      })

    default:
      return { ...mState };
  }
};