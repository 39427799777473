import { FormattedMessage } from "react-intl";

import { CloseIcon, PlusIcon } from "../Icons";
import { BackIcon } from "views/layout/Navbar/components/icons";
import { useWindowSize } from "hooks/useWindowSize";
import styles from "./Prompt.module.scss";
import { useSelector } from "redux/hooks";

interface IHeader {
  showAddPrompt: boolean;
  onClick: () => void;
  onClose: () => void;
  allowEdit?: boolean;
}

export const Header = ({ onClick, onClose, showAddPrompt, allowEdit }: IHeader) => {
  const { theme } = useSelector((state) => state.authReducer);
  const { width } = useWindowSize();

  return (
    <div className={`${styles.mainPrompt} ${width <=576 ? "flex flex-col-reverse gap-0 px-[20px]" : "grid gap-2 grid-cols-3 grid-rows-1 mb-6"}`}>
      {showAddPrompt ? (
        <div className="hover:cursor-pointer" data-testid="back-button" onClick={onClick}>
          <BackIcon prompt={true} theme={theme} />
        </div>                      
      ) : (
        <div
          className={`${styles.Prompt} flex flex-row items-center text-[#527AE6] text-md hover:cursor-pointer ${width <= 576 ? "gap-[7px] py-[10px] pl-[4px]" : ""}`}
          data-testid="add-prompt"
        >
           <div onClick={onClick} className="flex items-center">
          <PlusIcon prompt={true}/>
          <span className={`${styles.addPrompt} ${width <=576 ? "font-semibold":"font-bold "}`}>
            <FormattedMessage id="prompt.add.library" />
          </span>
          </div>
        </div>
      )}
        <div className="flex justify-center ">
          <span className={`${styles.prompttext} ${width <= 576 ? "font-bold text-lg" : "font-bold text-xl"}`}>
            <FormattedMessage
              id={allowEdit? "prompt.edit.library": showAddPrompt ? "prompt.add.library" : "prompt.library"}
            />
          </span>
        </div>
      <span
        data-testid="prompt-close"
        className="flex items-center justify-end hover:cursor-pointer"
        onClick={onClose}
      >
        <CloseIcon />
      </span>
    </div>
  );
};
