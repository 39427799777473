import { EThemeType } from "redux/reducers";
import styled from "styled-components";

interface IIconContainer {
  variant?: EThemeType;
  isBackground?: boolean;
}

export const FeatureCard = styled.div<{
  themeMode?: EThemeType;
  isBackground?: boolean;
  customerPortal?: boolean;
  chatSetting?: boolean;
  packageinfo?: boolean;
  buycredit?: boolean;
  currentPackagenfo?: boolean;
}>`
  width: 100%;
  background-color: ${({ themeMode, isBackground, chatSetting, }) =>
    chatSetting && themeMode === 'light' ? ' rgba(124, 77, 255, 0.06)' :
      chatSetting && themeMode === 'dark' ? ' rgba(182, 156, 255, 0.06)' :
        isBackground
          ? themeMode === "dark"
            ? "rgba(255, 255, 255, 0.06)"
            : "#FFF"
          : "transparent"};
  border-radius: ${({ chatSetting }) => chatSetting ? '10px' : '24px'};
  padding: ${({ customerPortal, chatSetting }) =>
    customerPortal ? '25px' : chatSetting ? '11px 20px 11px 20px' : '16px'};
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: ${({ chatSetting }) => chatSetting ? '10px' : '16px'};
  color: ${({ themeMode, theme, chatSetting }) =>
    chatSetting && themeMode === "light" ? theme.black : chatSetting && themeMode === "dark" ? '#ffffff' : themeMode === "light" ? theme.textColorLight : theme.white};

    @media (max-width: 768px) {
    border-radius: ${({ customerPortal , chatSetting, packageinfo}) => (customerPortal || packageinfo) ? '15px' : chatSetting? '10px' : '24px'};
}

  @media (max-width: 576px) {
    border-radius: ${({ customerPortal , chatSetting, packageinfo}) => (customerPortal || packageinfo) ? '15px' : chatSetting? '10px' : '24px'};
    gap:  ${({ currentPackagenfo }) => (currentPackagenfo) ? " 16px" :"8px" };
    flex-direction: ${({ customerPortal, packageinfo }) => (customerPortal || packageinfo) ? 'column' : 'row'};
    padding: ${({ customerPortal , packageinfo}) => customerPortal ? '26px 46px' : packageinfo? "18px 17px" : ""};
    box-shadow: ${({ customerPortal, themeMode }) =>
  themeMode === "dark"
    ? "none"
    : customerPortal
    ? "0px 64px 18px 0px rgba(194, 194, 194, 0.00), 0px 41px 17px 0px rgba(194, 194, 194, 0.01), 0px 23px 14px 0px rgba(194, 194, 194, 0.05), 0px 10px 10px 0px rgba(194, 194, 194, 0.09), 0px 3px 6px 0px rgba(194, 194, 194, 0.10)"
    : "none"};
  }

  @media (max-width: 375px)
  {
    padding: ${({ customerPortal }) => customerPortal ? '26px 34px'  : ""};
  }
  @media (min-width: 1130px){
    background-color: ${({ themeMode, buycredit }) =>
    buycredit && themeMode === "light" ? '#f6f6f7' : buycredit && themeMode === "dark" ? 'rgba(255, 255, 255, 0.06)' : ''
  }
`;

export const FeaturesmallCard = styled.div<{
  themeMode?: EThemeType;
  isBackground?: boolean;
}>`
  width: 100%;
  background-color: ${({ themeMode, isBackground }) =>
    isBackground
      ? themeMode === "dark"
        ? "rgba(255, 255, 255, 0.06)"
        : "#F6F6F7"
      : "transparent"};
  border-radius: 10px;
  padding: 16px;
  align-items: center;
  gap: 16px;
  color: ${({ themeMode, theme }) =>
    themeMode === "light" ? theme.textColorLight : theme.white};

  @media (max-width: 576px) {
    gap: 8px;
  }
`;

export const IconContainer = styled.div<IIconContainer>`
  border: ${({ variant , isBackground}) =>
    `1px solid ${variant === "light" ? "rgba(35, 37, 39, 0.04)" : variant === "dark" &&  isBackground? "transparent" : "#fff"}`};
  border-radius: 12px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 10px;
  @media (max-width: 386px)
  {
    padding:5px;
  }
`;

export const Icon = styled.div<IIconContainer>`
  svg {
    & path {
      stroke: ${({ variant }) => (variant === "light" ? "#2A2831" : "#FFFFFF")};
    }
  }
`;

