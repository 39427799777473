import { clsx } from "clsx";

interface LoaderProps {
  mini?: boolean;
}

export const Loader : React.FC<LoaderProps> = ({ mini = false }) =>{
  // Define common size classes based on the `mini` prop
  const sizeClasses = {
    width: mini ? "w-1" : "w-2",
    height: mini ? "h-1" : "h-2",
    marginTop: mini ? "" : "mt-1",
  };

  // Define the properties for each dot
  const dots = [
    { bg: "bg-gray-400", animation: "animate-jump-01" },
    { bg: "bg-gray-600", animation: "animate-jump-02" },
    { bg: "bg-primary", animation: "animate-jump-03" },
  ];

  return (
    <div
      className={clsx(
        "flex flex-row items-center gap-1 transition duration-150 ease-in-out",
        sizeClasses.marginTop
      )}
    >
      {dots.map((dot, index) => (
        <span
          key={index}
          className={clsx(
            "rounded-full inline-block",
            dot.bg,
            dot.animation,
            `${sizeClasses.width} ${sizeClasses.height}`
          )}
        />
      ))}
    </div>
  );
};


