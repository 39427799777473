import React, {
  memo,
  Fragment,
  useState,
  useEffect,
  useRef,
} from "react";

import { CopyToClipboard } from "react-copy-to-clipboard";
import { FormattedMessage } from "react-intl";

import classNames from "classnames";
import styles from "./answerContainer.module.scss";

import {
  CopyIcon,
  ShareIcon,
} from "../icons";
import { AssistanceIcon } from "../userName";

import { Tooltip } from "components";
import { Message } from "../message";
import { DocQuestion } from "../docQuestion";

import {
  IChat,
  IChatModel,
  IMessage,
  IShareChatMessage,
} from "redux/actions";
import { useSelector } from "redux/hooks";
import { RegenerateWithModel } from "../regeneratewithModel";
import messageStyles from "../message/message.module.scss";
import { responseLoadingMessage } from "utils/constants";
import { Loader } from "components/Loader/Loader";
import { AudioController } from "./AudioController";

interface IProps {
  isAllChunksReceived?: boolean;
  message?: IMessage;
  isGenerating?: boolean;
  onSendMessage?: (question: string) => void;
  onComplete?: () => void;
  onRegenerate?: (messageIndex: number, chatModal: IChatModel) => void;
  share?: boolean;
  toggleShareChat?: () => void;
  shareMessage?: IShareChatMessage;
  shareChat?: boolean;
  handleCheckboxChange?: (messageId: any) => void;
  chatItem?: IChat;
  searchQuery?: string;
  setHighlighted?: React.Dispatch<React.SetStateAction<boolean>>;
  highlighted?: boolean;
  setContainerHeight?: React.Dispatch<React.SetStateAction<number>>;
  messageIndex?: number;
  userMessage?: boolean;
}

export const BlockType = {
  text: "text",
  code: "code",
} as const;

export type EBlockType = (typeof BlockType)[keyof typeof BlockType];

export interface IMessageBlock {
  blockType: EBlockType;
  language?: string;
  message?: string;
}

export const Answer = memo(
  ({
    isAllChunksReceived,
    message,
    isGenerating,
    onSendMessage,
    onComplete,
    onRegenerate,
    share,
    toggleShareChat,
    shareMessage,
    shareChat,
    handleCheckboxChange,
    chatItem,
    searchQuery,
    highlighted,
    setContainerHeight,
    messageIndex,
  }: IProps) => {
    const { theme, gptModel } = useSelector((state) => state.authReducer);
    const { PrivateChat, regenerateModel,
      } = useSelector((state) => state.chatReducer);

    const [currentMessageIndex, setCurrentMessageIndex] = useState<number>(0);
    const [showResponseLoadingPrompt, setShowResponseLoadingPrompt] = useState<boolean>(false);
    const [isCopied, setIsCopied] = useState<boolean>(false);
    const isHighlighted = searchQuery !== "";
    const containerRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
      const updateContainerHeight = () => {
        if (containerRef.current) {
          const height = containerRef.current.getBoundingClientRect().height;
          setContainerHeight && setContainerHeight(height);
        }
      };
      updateContainerHeight();
      window.addEventListener("resize", updateContainerHeight);

      return () => {
        window.removeEventListener("resize", updateContainerHeight);
      };
    }, [message]);

    useEffect(() => {
      const intervalID = setTimeout(() => {
        if (isCopied) setIsCopied(false);
      }, 500);

      return () => clearInterval(intervalID);
    }, [isCopied]);

    const content = String(message?.content);
    const [typingFinished, setTypingFinished] = useState(false);

    const showAssistanAction = message?.isNew
      ? content.length > 0 && !isGenerating && !message?.files
      : content.length > 0;

    useEffect(() => {
      if (isAllChunksReceived) setTypingFinished(true);
    }, [isAllChunksReceived]);

    useEffect(() => {
      if (typingFinished) {
        onComplete?.();
      }
    }, [typingFinished]);

    useEffect(() => {
      if (!isGenerating && (content as string).length > 100)
        setTypingFinished(true);
    }, [isGenerating, content]);

    useEffect(() => {
      if (message?.isNew && !content && !isGenerating && (regenerateModel?.modelName!== ''? ( regenerateModel?.loader === true ) : (gptModel?.attributes?.loader === true))) {
        setTimeout(() => setShowResponseLoadingPrompt(true), 100)
        const interval = setInterval(() => {
          setCurrentMessageIndex((prevIndex) => {
            if (prevIndex < responseLoadingMessage.length - 1) {
              return prevIndex + 1;
            } else {
              clearInterval(interval);
              return prevIndex;
            }
          });
        }, 8000);

        return () => clearInterval(interval);
      }
    }, []);

    const handleCopy = (e: any) => {
      e.preventDefault();

      const selection = window.getSelection();
      let messageText = selection ? selection.toString() : "";
      message?.related_questions?.forEach((question) => {
        messageText = messageText.replace(question, "");
      });
      messageText = messageText.replace(/\s{2,}/g, " ");

      e.clipboardData.setData("text/plain", messageText);
      setIsCopied(true);
    };

    return (
      <Fragment>
        <div className={styles.container} ref={containerRef}>
          <div className={styles.leftContainer}>
            <div
              className={classNames({
                [styles.assistanceIconAnimation]: ( message?.isNew && 
                message?.adminAccountdel !== true && 
                ((!content) || (content && isGenerating))),
              })}
            >
              <AssistanceIcon />
            </div>
            <div
              className={styles.messageContainer}
              onDoubleClick={(e) => {
                e.stopPropagation();
              }}
              onCopy={handleCopy}
            >

              {(message?.isNew && !content && !isGenerating && showResponseLoadingPrompt && (regenerateModel.modelName !== ''? ( regenerateModel?.loader === true)  : (gptModel?.attributes?.loader === true))) && (
                <div
                  className={classNames(messageStyles.message, {
                    [messageStyles.light]: theme === "light",
                    [messageStyles.dark]: theme === "dark",
                  })}
                  style={{ lineHeight: '24px' }}
                >
                  <FormattedMessage id={`response.loading.message.${responseLoadingMessage[currentMessageIndex]}`} />
                  <span className="inline-block ml-2">
                    <Loader mini/>
                  </span>
                </div>
              )}
              <span
                className={classNames(messageStyles.answerStyle, {
                  [messageStyles.light]: theme === "light" && message?.content,
                  [messageStyles.dark]: theme === "dark" && message?.content,
                })}>
                <Message
                  message={message}
                  shareMessage={shareMessage}
                  searchQuery={searchQuery}
                  isHighlighted={isHighlighted}
                  highlighted={highlighted}
                />
              </span>
            </div>
          </div>
        </div>
        {!shareChat && showAssistanAction && (
          <div className={styles.actionContainer}>
            {/* AUDIO BUTTON */}
            <AudioController message={message?.content ?? ''}
              messageId={message?.id ?? 1} />

            {/* COPY BUTTON */}
            <Tooltip
            regenrate={true}
            tooltipAdjustement={true}
              control={
                <CopyToClipboard
                  text={content}
                  onCopy={() => setIsCopied(true)}
                >
                  <div className={classNames(styles.iconContainer, {
                    [styles.light]: theme === "light",
                    [styles.dark]: theme === "dark",
                  })}
                  >
                    <CopyIcon theme={theme} />
                  </div>
                </CopyToClipboard>
              }
              placement="top"
              theme="light"
            >
              <div>
                <FormattedMessage
                  id={
                    isCopied ? "answer.tooltip.copied" : "answer.tooltip.copy"
                  }
                />
              </div>
            </Tooltip>
            <>
              {/* SHARE BUTTON */}
              {(!share && PrivateChat === false) && (
                <Tooltip
                regenrate={true}
                tooltipAdjustement={true}
                  control={
                    <div
                      className={classNames(styles.icon, {
                        [styles.light]: theme === "light",
                        [styles.dark]: theme === "dark",
                        [styles.iconContainer]: true
                      })}
                      onClick={() => {
                        toggleShareChat!();
                        handleCheckboxChange!(message?.id);
                      }}
                      data-testid="share-chat-btn"
                    >
                      <ShareIcon theme={theme} />
                    </div>
                  }
                  placement="top"
                  theme="light"
                >
                  <div>
                    <FormattedMessage id="share.tooltip.chat" />
                  </div>
                </Tooltip>
              )}

                  <RegenerateWithModel
                    message={message}
                    chatItem={chatItem}
                    messageIndex={messageIndex}
                    onRegenerate={onRegenerate}
                  />
            </>
          </div>
        )}
        {!shareChat &&
          !isGenerating &&
          (message?.related_questions as string[])?.length > 0 && (
            <div className={styles.questionContainer}>
              {message?.related_questions?.map((question, index) => (
                <DocQuestion
                  key={`${message.id}-${index}`}
                  question={question}
                  onClick={() => onSendMessage?.(question)}
                  delay={500 * index}
                />
              ))}
            </div>
          )}
      </Fragment>
    );
  }
);
