import { TYPES } from "../../types";
import { api } from "../../api";
import { store } from "../../store";
import { ICreditLimit } from "../../reducers";

export type EPlanName = (typeof PlanNames)[keyof typeof PlanNames];

export const PlanNames = {
  Free: "Free",
  Standard: "Standard",
  Professional: "Professional",
  Team: "Team",
  Business: "Business",
} as const;

export type EPlanDuration = (typeof PlanDurations)[keyof typeof PlanDurations];

export const PlanDurations = {
  month: "month",
  year: "year",
} as const;

export interface IPlan {
  id: number;
  name: EPlanName;
  duration: EPlanDuration;
  credits: number;
  price: number;
  discounted_price: number;
  stripe_price_id: string;
  max_team_members: number;
  created_at: string | null;
  updated_at: string | null;
}

interface PlanAttributes {
  max_document_chats: number;
  max_video_chats: number;
  max_audio_chats: number;
}
export interface IActivePlan {
  id: number;
  attributes: PlanAttributes;
  team_id: number;
  status: string;
  credits: number;
  document_chat_count: number;
  video_chat_count: number;
  audio_chat_count: number;
  is_cancelled: number;
  end_date: string;
  created_at: string;
  updated_at: string;
  name: EPlanName;
  total_credits: number;
  total_earned_credits: number;
  cost: string;
  renewal_date: string;
  duration: EPlanDuration;
}

export interface ICredit {
  id: number;
  credits: number;
  price: number;
  stripe_id: number | null;
  created_at: string | null;
  updated_at: string | null;
}

export interface IUpdateCreditRes {
  credits: number;
}

export interface IConfirmUpgradeBody {
  stripe_price_id?: string;
  proration_date?: number;
  plan_id?: number;
}

export const setCreditLoad = (creditLoad: boolean ) => {
  store.dispatch({ type: TYPES.CREDITS_LOADING_ANIMATION, payload: creditLoad });
};

export const getPlan = () => {
  return new Promise((resolve, reject) => {
    api
      .get("/api/plans")
      .then((res) => {
        resolve(res.data);
        store.dispatch({ type: TYPES.GET_PLAN, payload: res.data });
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const getActivePlan = () => {
  return new Promise((resolve, reject) => {
    api
      .get("/api/user/plan")
      .then((res) => {
        resolve(res.data);
        store.dispatch({ type: TYPES.GET_ACTIVE_PLAN, payload: res.data });
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const upgradePlan = (planId: number) => {
  return new Promise((resolve, reject) => {
    api
      .post("/api/user/plan/upgrade", { plan_id: planId })
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const confirmUpgrade = (body: IConfirmUpgradeBody) => {
  return new Promise((resolve, reject) => {
    api
      .post("/api/user/plan/confirm", body)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const getCredits = () => {
  return new Promise((resolve, reject) => {
    api
      .get("/api/additional-products")
      .then((res) => {
        resolve(res.data);
        store.dispatch({ type: TYPES.GET_CREDITS_LIST, payload: res.data });
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const addCredits = (creditId: number) => {
  return new Promise((resolve, reject) => {
    api
      .post("/api/credits/buy", { id: creditId })
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const updateCredits = (credits: number) => {
  store.dispatch({ type: TYPES.UPDATE_CREDITS, payload: credits });
};

export const cancelSubscription = () => {
  return new Promise((resolve, reject) => {
    api
      .post("/api/user/plan/cancel")
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const updateCreditLimit = (creditLimit: ICreditLimit) => {
  store.dispatch({
    type: TYPES.GET_CREDIT_LIMITS,
    payload: { creditLimits: creditLimit },
  });
};

export const getCreditLimits = () => {
  return new Promise((resolve, reject) => {
    api
      .get("/api/user/daily-limit")
      .then((res) => {
        resolve(res.data);
        updateCreditLimit(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const CustomerPortal = () => {
  return new Promise((resolve, reject) => {
    api
      .get("/api/user/plan/portal")
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
