import { PrivateChatIcon } from '../icons/PrivateChatIcon';
import { FormattedMessage } from "react-intl";
import { Switch } from '@headlessui/react';
import styles from "./privateChat.module.scss";
import classNames from "classnames";
import { deleteSingleChatHistory, removeMultipleChat, setPrivateChat } from 'redux/actions';
import { useSelector } from "redux/hooks";
import useRouter from 'hooks/useRouter';
import { useSidebar } from 'hooks/services/ReSizeSidebar/ReSizeSidebar';

interface PrivateChatProps {
  onStartNewChat?: ({ toChat, PrivateChat }: { toChat: boolean, PrivateChat?: boolean }) => void;
  sendMessageLoading?: boolean;
  isAnswerComplete?: boolean;
  sidebarDropDown?: boolean;
}

export const PrivateChatMode: React.FC<PrivateChatProps> = ({ onStartNewChat, sendMessageLoading, isAnswerComplete, sidebarDropDown }) => {
  const { pathname } = useRouter();
  const { theme } = useSelector((state) => state.authReducer);
  const { PrivateChat } = useSelector(
    (state) => state.chatReducer
  );
  const { newMessages } = useSelector((state) => state.chatReducer);
  const { sidebarWidth } = useSidebar();

  const handleSwitchChange = (checked: boolean) => {

    sessionStorage.setItem('privateChatActive', JSON.stringify(checked));
    localStorage.setItem('previousPrivateChat', JSON.stringify(checked));

    if (checked === false) {
      const currentPath = window.location.pathname;
      const pathSegments = currentPath.split('/');
      const chatIdFromUrl = pathSegments[pathSegments.length - 1];
      if (Number(chatIdFromUrl)) {
        deleteSingleChatHistory(Number(chatIdFromUrl))
        removeMultipleChat([Number(chatIdFromUrl)])
        sessionStorage.removeItem('privateChatActive');
        sessionStorage.removeItem('PrivateChatId');
      }
    }

    const history = pathname.includes("/chat/history");
    if (newMessages.length > 0 || history) {
      onStartNewChat?.({ toChat: true, PrivateChat: true });
    }

    setPrivateChat(checked);
  };

  return (
    <div className={classNames(styles.PrivateMain, {
      [styles.sideBarDD]: sidebarDropDown && sidebarWidth > 270,
    })}>
      <span className={styles.icon}><PrivateChatIcon /></span>
      <span className={classNames(styles.headingName,{
       [styles.sidebarDropDown]: sidebarDropDown,
      })}>
        <FormattedMessage id="PrivateChat" />
      </span>
      <span className={classNames(styles.toggle, {
        [styles.light]: theme === "light",
        [styles.dark]: theme === "dark",
        [styles.adjustToggle]: sidebarWidth<=270,
        [styles.sidebarDropDown]: sidebarDropDown,
        [styles.sidebarDropDowndark]: theme === "dark" && sidebarDropDown,
        // [styles.light]: theme === "light" && PrivateChat,
        // [styles.dark]: theme === "dark" && PrivateChat,
      })}
        onClick={() => { if (!sendMessageLoading || isAnswerComplete) handleSwitchChange(!PrivateChat) }}>
        <Switch
          className={classNames(styles.toggleCircle, {
            [styles.enabled]: PrivateChat
          })}
        />
      </span>
    </div>
  )
}
