import { faSortDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { components, DropdownIndicatorProps } from "react-select";
import styled from "styled-components";
import { useSelector } from "redux/hooks";
import { AccountArrowIcon } from "components/Header/Icons/AccountArrowIcon";

// import { AccountArrowIcon } from "components/Header/Icons/AccountArrowIcon";


const Arrow = styled(FontAwesomeIcon)`
  margin-top: -6px;
`;
interface CustomDropdownIndicatorProps extends DropdownIndicatorProps {
  account?: boolean; 
  signup?:boolean;
}
export const DropdownIndicator = ({
  account,
  signup,
  ...props
}: CustomDropdownIndicatorProps) => {
  const { theme } = useSelector((state) => state.authReducer);
  return (
    <components.DropdownIndicator {...props}>
      {/* <Arrow icon={faSortDown} /> */}
      {account || signup ? <AccountArrowIcon theme={theme} signup={true}/> : <Arrow icon={faSortDown} />}
    </components.DropdownIndicator>
  );
};
