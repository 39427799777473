import { useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";

import classNames from "classnames";
import styles from "./TeamNav.module.scss";

import { PlusIcon } from "./icons";

import CustomButton from "components/Button";
import { InviteMemberModal } from "pages/SettingsPage/pages/Team/components/InviteMemberModal";

import { useAppNotification } from "hooks/services/AppNotification";
import { useToggleSidebar } from "hooks/services/ToggleSidebarProvider";

import { inviteTeamMember, IInviteMemberBody } from "redux/actions";
import { useSelector } from "redux/hooks";
import { useSidebar } from "hooks/services/ReSizeSidebar/ReSizeSidebar";
import { useWindowSize } from "hooks/useWindowSize";

export const TeamNav = () => {
  const { formatMessage } = useIntl();
  const { triggerNotification } = useAppNotification();
  const { isOpen } = useToggleSidebar();
  const { theme, userDetail } = useSelector((state) => state.authReducer);
  const userRole = userDetail?.user.team.role;

  const { sidebarWidth } = useSidebar();
  const { width } = useWindowSize();

  // Invite Team Member
  const [inviteLoading, setInviteLoading] = useState<boolean>(false);
  const [inviteModal, setInviteModal] = useState<boolean>(false);
  const toggleInviteModal = () => {
    if (inviteLoading) return;
    setInviteModal((prev) => {
      if (!prev) {
        if (
          userDetail?.user.activeSubscription.name === "Free" ||
          userDetail?.user.activeSubscription.name === "Standard"
        ) {
          triggerNotification({
            message: "invite.teamMember.error",
            type: "info",
          });
          return false;
        } else {
          return true;
        }
      } else {
        return false;
      }
    });
  };
  const onInviteTeamMember = (data: IInviteMemberBody) => {
    setInviteLoading(true);
    inviteTeamMember(data)
      .then(() => {
        setInviteLoading(false);
        toggleInviteModal();
        triggerNotification({
          message: formatMessage(
            { id: "inivation.send.message.success" },
            { email: data.email }
          ),
          type: "info",
        });
      })
      .catch((err) => {
        setInviteLoading(false);
        triggerNotification({ message: err?.data?.message, type: "error" });
      });
  };

  return (
    <>
      {/* <FeatureCard isBackground={true}> */}
      <div
        className={classNames(styles.head, {
          [styles.light]: theme === "light",
          [styles.dark]: theme === "dark",
        })}
      >
        <div
          className={classNames(styles.headLeft, {
            [styles.isSidebarOpen]: isOpen,
            [styles.isSidebarClose]: !isOpen,
            [styles.resizeTeamNav]: sidebarWidth >= 400 && width < 1300 && isOpen
          })}
        >
          <h1 className={styles.title}>
            <FormattedMessage id="team.page.heading" />
          </h1>
          <span className={classNames(styles.text, {
            [styles.resizeText]: width > 768 && isOpen ,
            [styles.resize]:  isOpen && sidebarWidth >= 370,
          })}>
            <FormattedMessage id="team.page.subHeading" />
          </span>
        </div>

        {userRole !== "member" && (
          <CustomButton teamNav={true} variant="outline-primary" data-testid='toggle-invite-modal' onClick={toggleInviteModal}>
            <div
              className={classNames(styles.btnContent, {
                [styles.light]: theme === "light",
                [styles.dark]: theme === "dark",
              })}
            >
              <PlusIcon className={styles.plusIcon} />
              <span className={styles.text}>
                <FormattedMessage id="team.page.inviteTeam.btnText" />
              </span>
            </div>
          </CustomButton>
        )}
      </div>
      {/* </FeatureCard> */}

      {inviteModal && (
        <InviteMemberModal
          userRole={userRole}
          userDetail={userDetail}
          onClose={toggleInviteModal}
          onSubmit={onInviteTeamMember}
          submitLoading={inviteLoading}
        />
      )}
    </>
  );
};
