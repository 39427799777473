import moment from "moment";
import { FormattedMessage } from "react-intl";
import styles from "./Features.module.scss";
import classNames from "classnames";

import { Star, Credit, Plan, Calendar, PaymentFrequency } from "../icons";

import Button from "components/Button";
import Switch from "components/Switch";
import { FeatureCard, IconContainer, Icon, FeaturesmallCard } from "../components";

import { EPlanDuration, IUserDetail } from "redux/actions";
import { useSelector } from "redux/hooks";
import CustomButton from "components/Button";
import { useWindowSize } from "hooks/useWindowSize";

interface IProps {
  userDetail?: IUserDetail;
  duration: EPlanDuration;
  toggleIsMonthly?: () => void;
  toggleBuyCreditModal?: () => void;
  StripCustomerPortal?: () => void;
  stripeLoading?: boolean;
}

export const Features = ({
  userDetail,
  duration,
  toggleIsMonthly,
  toggleBuyCreditModal,
  StripCustomerPortal,
  stripeLoading,
}: IProps) => {
  const { theme } = useSelector((state) => state.authReducer);
  const { width } = useWindowSize();

  return (
    <div className={styles.container}>
      {(userDetail?.user.activeSubscription.name !== 'Free') &&
        <FeatureCard themeMode={theme} isBackground={true} customerPortal={true} >
          <div className={classNames(styles.textContainer, {
            [styles.light]: theme === 'light',
            [styles.dark]: theme === 'dark'
          }
          )}>
            <div className={styles.portalfeature}>
              <FormattedMessage id="sidebar.chat.newChat.currentPlan" />
            </div>
            <div className={styles.portaltitle}>
              <FormattedMessage id="plan.type.Option" />
            </div>
          </div>
          <CustomButton
            currentPlan={true}
            variant="primary"
            isloading={stripeLoading}
            className={styles.planbutton}
            style={{ width: '100%' }}
            onClick={StripCustomerPortal}
            data-testid='update-payment-method'
          >
            <div className={styles.text} style={{ color: theme === "dark" ? "#282828" : "#fff",  opacity: stripeLoading ? '0' : '' }}>
              <FormattedMessage id="update.plan.method" />
            </div>
          </CustomButton>
        </FeatureCard>
      }
      <div className={styles.rowContainer}>
        <FeatureCard isBackground={true} themeMode={theme} packageinfo={true}>
          <div className={styles.rowContainer}>

            <FeatureCard themeMode={theme} currentPackagenfo={true}>
              <IconContainer variant={theme}  isBackground={true}>
                <Icon variant={theme}>
                  <Star className={styles.icon} />
                </Icon>
              </IconContainer>
              <div className={styles.textContainer}>
                <div className={styles.title}>
                  <FormattedMessage id="settings.plan.feature.1" />
                </div>
                <div className={styles.feature}>
                  {userDetail?.user?.activeSubscription?.name}
                </div>
              </div>
            </FeatureCard>
            <FeatureCard themeMode={theme} currentPackagenfo={true}>
              <IconContainer variant={theme}  isBackground={true}>
                <Icon variant={theme}>
                  <Credit className={styles.icon} />
                </Icon>
              </IconContainer>
              <div className={styles.textContainer}>
                <div className={styles.title}>
                  <FormattedMessage id="settings.plan.feature.3" />
                </div>
                <div className={styles.feature}>
                  {userDetail?.user?.activeSubscription?.total_credits}/
                  {userDetail?.user?.activeSubscription?.name === "Free" ? (
                    <FormattedMessage id="settings.plan.feature.3.day" />
                  ) : (
                    <FormattedMessage id="settings.plan.feature.3.month" />
                  )}
                </div>
              </div>
            </FeatureCard>
          </div>
          <div className={styles.rowContainer}>
            <FeatureCard themeMode={theme} currentPackagenfo={true}>
              <IconContainer variant={theme} isBackground={true}>
                <Icon variant={theme}>
                  <Plan className={styles.icon} />
                </Icon>
              </IconContainer>
              <div className={styles.textContainer}>
                <div className={styles.title}>
                  <FormattedMessage id="settings.plan.feature.4" />
                </div>
                <div className={styles.feature}>
                  ${userDetail?.user?.activeSubscription?.cost}
                </div>
              </div>
            </FeatureCard>
            <FeatureCard themeMode={theme} currentPackagenfo={true}>
              <IconContainer variant={theme}  isBackground={true}>
                <Icon variant={theme}>
                  <Calendar className={styles.icon} />
                </Icon>
              </IconContainer>
              <div className={styles.textContainer}>
                <div className={styles.title}>
                  <FormattedMessage id="settings.plan.feature.5" />
                </div>
                <div className={styles.feature}>
                  {moment
                    .utc(userDetail?.user?.activeSubscription?.renewal_date)
                    .format("MMM Do, YYYY")}
                </div>
              </div>
            </FeatureCard>
          </div>
        </FeatureCard>
      </div>
      <div className={classNames(styles.rowContainer, {
        [styles.light]: theme === "light",
        [styles.dark]: theme === "dark",
      })}>
        <div className={styles.allowsmallscreen}>
          <div className="py-[6px] px-[6px] bg-[#fff] dark:bg-[#282828] rounded-[15px]">
            <FeaturesmallCard themeMode={theme} isBackground={true}>
              <div className="flex flex-row ">
                <Icon variant={theme}>
                  <PaymentFrequency className={styles.icon} />
                </Icon>
              </div>
              <div className={`${(width <= 576 && width > 350) ? "mt-[18px] gap-[18px]" : width <= 350 ? "gap-[8px] mt-[18px]" : ""} flex flex-row justify-between`} >
                <div className={styles.textContainer}>
                  <div className={styles.feature}>
                    <FormattedMessage id="settings.plan.feature.paymentFrequency" />
                  </div>
                  <div className={styles.title}>
                    <FormattedMessage id="settings.plan.feature.setFrequency" />
                  </div>

                </div>
                <div className={styles.featureTextRow}>
                  <div className={styles.switchTextContainer}>
                    <span className={styles.switchContainer}>
                      <div className={styles.switchText}>
                        <FormattedMessage id="settings.plan.feature.monthly" />
                      </div>
                      <Switch
                        currentPlan={true}
                        checked={duration === "year" ? true : false}
                        onChange={toggleIsMonthly}
                      />
                    </span>
                    <div className={styles.switchText}>
                      <FormattedMessage id="settings.plan.feature.annual" />
                      &nbsp;
                      <div className={styles.percentage}>-20%</div>
                    </div>
                  </div>
                </div>
              </div>
            </FeaturesmallCard>
          </div>
        </div>
        <FeatureCard themeMode={theme} isBackground={true} buycredit={true} className={styles.allowlgscreen}>
          <div className={styles.iconTextContainer}>
            <Icon variant={theme}>
              <PaymentFrequency className={styles.icon} />
            </Icon>
            <div className={styles.textContainer}>
              <div className={styles.feature}>
                <FormattedMessage id="settings.plan.feature.paymentFrequency" />
              </div>
              <div className={styles.title}>
                <FormattedMessage id="settings.plan.feature.setFrequency" />
              </div>
            </div>
          </div>

          <div className={styles.featureTextRow}>
            <div className={styles.switchTextContainer}>
              <span className={styles.switchContainer}>
                <div className={styles.switchText}>
                  <FormattedMessage id="settings.plan.feature.monthly" />
                </div>
                <Switch
                  currentPlan={true}
                  checked={duration === "year" ? true : false}
                  onChange={toggleIsMonthly}
                />
              </span>
              <div className={styles.switchText}>
                <FormattedMessage id="settings.plan.feature.annual" />
                &nbsp;
                <div className={styles.percentage}>-20%</div>
              </div>
            </div>
          </div>
        </FeatureCard>
        <div className={styles.allowsmallscreen}>
          <div className="py-[6px] px-[6px] bg-[#fff] dark:bg-[#282828] rounded-[15px]">
            <FeaturesmallCard themeMode={theme} isBackground={true} >
            <span className={width<=576 ? "gap-[18px] flex flex-col": ""}>
              <div className="flex flex-row justify-center">
                <div className={styles.iconTextContainer}>
                  <Icon variant={theme}>
                    <Credit className={styles.icon} />
                  </Icon>
                </div>
                {width > 576 && (
                  <Button
                    currentPlan={true}
                    full
                    className={styles.button}
                    onClick={toggleBuyCreditModal}
                    data-testid='setting-plan-buy-credits'
                  >
                    <div className={styles.text}>
                      <FormattedMessage id="settings.plan.feature.buyCredits" />
                    </div>
                  </Button>
                )}
              </div>
              <div className={styles.textContainer}>
                <div className={styles.feature}>
                  <FormattedMessage id="settings.plan.feature.addCredits" />
                </div>
                <div className={styles.title}>
                  <FormattedMessage
                    id="settings.plan.feature.creditsFor"
                    values={{ number: "100000", price: "500" }}
                  />
                </div>
              </div>
              {width <= 576 && (
                <Button
                  currentPlan={true}
                  full
                  className={styles.button}
                  onClick={toggleBuyCreditModal}
                  data-testid='setting-plan-buy-credits'
                >
                  <div className={styles.text}>
                    <FormattedMessage id="settings.plan.feature.buyCredits" />
                  </div>
                </Button>
              )}
              </span>
            </FeaturesmallCard>
          </div>
        </div>
        <FeatureCard themeMode={theme} buycredit={true} isBackground={true} className={styles.allowlgscreen}>
          <div className={styles.iconTextContainer}>
            <Icon variant={theme}>
              <Credit className={styles.icon} />
            </Icon>
            <div className={styles.textContainer}>
              <div className={styles.feature}>
                <FormattedMessage id="settings.plan.feature.addCredits" />
              </div>
              <div className={styles.title}>
                <FormattedMessage
                  id="settings.plan.feature.creditsFor"
                  values={{ number: "100000", price: "500" }}
                />
              </div>
            </div>
          </div>
          <Button
            currentPlan={true}
            full
            className={styles.button}
            onClick={toggleBuyCreditModal}
            data-testid='setting-feature-buy-credit'
          >
            <div className={styles.text}>
              <FormattedMessage id="settings.plan.feature.buyCredits" />
            </div>
          </Button>
        </FeatureCard>
      </div>
    </div>
  );
};
