import {
  ReactNode,
  Dispatch,
  SetStateAction,
  useRef,
  useState,
  useEffect,
} from "react";
import { useEffectOnce } from "react-use";
import styles from "./MainView.module.scss";
import classNames from "classnames";

import {
  ChatType,
  getCreditLimits,
  IChat,
  startNewChat,
  setChatModel,
  EChatType,
  DeleteS3Link,
  removeMultipleChat,
  setRememberHistoryType,
  RoleBot,
  setSearchWord,
  deleteSingleChatHistory,
  logout,
  regenerateModelNme,
} from "redux/actions";
import { RoutePaths } from "pages/routePaths";
import { ChatRoute, IUploadFile, SelectedSubOptions } from "pages/ChatPage";

import useRouter from "hooks/useRouter";
import { useAppNotification } from "hooks/services/AppNotification";
import { useToggleSidebar } from "hooks/services/ToggleSidebarProvider";
import { useSelector } from "redux/hooks";
import { useWindowSize } from "hooks/useWindowSize";
import { isIOS, isSafari } from "react-device-detect";
import { CustomSplitPane } from "../Sidebar/CustomSplit";
import { useSidebar } from "hooks/services/ReSizeSidebar/ReSizeSidebar";
import { Sidebar } from "../Sidebar";
import { MainLeftContainer } from "./MainLeftContainer";
import { setNewChatFolderId } from "redux/actions/chatFolder";

interface IProps {
  sendMessageLoading?: boolean;
  isAnswerComplete?: boolean;
  onChangeChat?: () => void;
  children?: ReactNode;
  contentContainerRef?: React.RefObject<HTMLDivElement>;
  isMainScreenOpen?: boolean;
  setIsMainScreenOpen?: Dispatch<SetStateAction<boolean>>;
  docuemntModel?: boolean;
  setShare?: Dispatch<SetStateAction<boolean>>;
  setSelectedMessages?: Dispatch<SetStateAction<any[]>>;
  setChatItem?: Dispatch<SetStateAction<IChat | undefined>>;
  searchQuery?: string;
  setSearchQuery?: Dispatch<SetStateAction<string>>;
  setChatSetting?: Dispatch<SetStateAction<boolean>>;
  updateChatModel?: (modalType: EChatType) => void;
  setIsDrag?: Dispatch<SetStateAction<boolean>>;
  setSelectedFile?: Dispatch<SetStateAction<File[] | null>>;
  isFileUploading?: boolean;
  setOpenHistory?: Dispatch<SetStateAction<boolean>>;
  controllerRef?: React.MutableRefObject<AbortController | null | undefined>;
  uploadingFiles?: IUploadFile[];
  setUploadingFiles?: Dispatch<SetStateAction<IUploadFile[]>>;
  setFileS3Link?: Dispatch<SetStateAction<string[]>>;
  isAllowUploadFile?: boolean;
  fileS3Link?: string[];
  setSelectedSubOptions?: Dispatch<SetStateAction<SelectedSubOptions>>;
  selectedSubOptions?: SelectedSubOptions;
  textareaRef?: React.RefObject<HTMLTextAreaElement>;
  userScrolled?: boolean;
}

export const MainView = ({
  sendMessageLoading,
  isAnswerComplete,
  onChangeChat,
  children,
  contentContainerRef,
  isMainScreenOpen,
  setIsMainScreenOpen,
  docuemntModel,
  setShare,
  setSelectedMessages,
  setChatItem,
  searchQuery,
  setSearchQuery,
  setChatSetting,
  updateChatModel,
  setIsDrag,
  setSelectedFile,
  isFileUploading,
  setOpenHistory,
  controllerRef,
  uploadingFiles,
  setUploadingFiles,
  setFileS3Link,
  isAllowUploadFile,
  fileS3Link,
  setSelectedSubOptions,
  selectedSubOptions,
  textareaRef,
  userScrolled,
}: IProps) => {

  const { width } = useWindowSize();
  const { isOpen, toggleSidebar } = useToggleSidebar();
  const { triggerNotification } = useAppNotification();
  const { includeRoute, push, pathname } = useRouter();

  const { chatModels } = useSelector((state) => state.chatModelsReducer);
  const {
    newMessages,
    PrivateChat,
    RememberSetting,
    chathistory,
  } = useSelector((state) => state.chatReducer);
  const { gptModel, theme } = useSelector((state) => state.authReducer);
  const { sidebarWidth, setSidebarWidth, setPaneWidth, paneStyle, setPaneStyle } = useSidebar();

  const isShareChat = window.location.pathname.includes("share-chat");
  const history = pathname.includes("/chat/history");
  const containerRef = useRef<HTMLDivElement>(null);
  const [allowDrag, setAllow] = useState<boolean>(false);

  // Calculate max width as 45% of the container width
  let maxSidebarWidth = window.innerWidth * 0.45;

  useEffect(() => {
    localStorage.removeItem("previousPrivateChat");
  }, []);

  useEffectOnce(() => {
    if (!isShareChat) {
      getCreditLimits().catch((err) => {
        triggerNotification({ message: err?.data?.message, type: "error" });
      });
    }
  });

  useEffect(() => {
    maxSidebarWidth = window.innerWidth * 0.45
    if (sidebarWidth > maxSidebarWidth && width > 768) {
      setSidebarWidth(maxSidebarWidth);
      setPaneStyle({ width: `${maxSidebarWidth}px`, marginRight: '', transition: 'none' });
    }
    else{
      setPaneStyle({ width: `${sidebarWidth}px`, marginRight: '', transition: 'none' });
    }
    if (width <= 768){
      setPaneStyle({ width: "", marginRight: '', transition: 'none' });
    }
  }, [width])

  const handleDeleteS3Link = () => {
    fileS3Link?.map((file) => {
      DeleteS3Link(file);
    });
  };

  useEffect(() => {
    const isPrivateChatActive = JSON.parse(
      sessionStorage.getItem("privateChatActive") || "false"
    );
    const PrivateChatId = Number(sessionStorage.getItem("PrivateChatId"));

    if (isPrivateChatActive && PrivateChatId && PrivateChat) {
      const handleBeforeUnload = (event: BeforeUnloadEvent) => {
        const currentPath = window.location.pathname;
        const pathSegments = currentPath.split("/");
        const chatIdFromUrl =
          Number(pathSegments[pathSegments.length - 1]) || PrivateChatId;

        if (chatIdFromUrl) {
          deleteSingleChatHistory(chatIdFromUrl);
          sessionStorage.setItem("redirectedFromPrivateChat", "true");
          removeMultipleChat([chatIdFromUrl]);
          // Show confirmation dialog if user tries to close/refresh the tab
          event.preventDefault();
          event.returnValue = ""; // Some browsers require this for showing a confirmation
          sessionStorage.removeItem("privateChatActive");
          sessionStorage.removeItem("PrivateChatId");
        }
      };
      // Attach event listener for 'beforeunload'
      window.addEventListener("beforeunload", handleBeforeUnload);

      // Cleanup function
      return () => {
        window.removeEventListener("beforeunload", handleBeforeUnload);
      };
    }
  }, [chathistory, removeMultipleChat]);

  const ResetBotSetting = () => {
    const hasNonDefaultValues = Object.values(selectedSubOptions || {}).some(
      (value) => value !== "default"
    );
    if (!RememberSetting && hasNonDefaultValues) {
      const updatedBot = {
        key: "bot_settings",
      };
      setSelectedSubOptions?.({
        outputFormats: "default",
        tones: "default",
        writingStyles: "default",
        responseLengths: "default",
        language: "default",
      });

      RoleBot(updatedBot);
    }
  };

  const onStartNewChat = ({
    toChat,
    PrivateChat,
    newChatbtn,
  }: {
    toChat: boolean;
    PrivateChat?: boolean;
    newChatbtn?: boolean;
  }) => {
    setShare && setShare(false);
    setUploadingFiles?.([]);
    setSelectedFile?.(null);
    setFileS3Link?.([]);
    setSelectedMessages && setSelectedMessages([]);
    const currentPath = window.location.pathname;
    const pathSegments = currentPath.split("/");
    const chatIdFromUrl = pathSegments[pathSegments.length - 1];
    if (!sendMessageLoading || isAnswerComplete) {
      if (toChat) {
        if (newChatbtn) setNewChatFolderId(null);
        regenerateModelNme('', false);
        ResetBotSetting();
        setRememberHistoryType("");
        const isPrivateChatActive = JSON.parse(
          localStorage.getItem("previousPrivateChat") || "false"
        );
        if (
          Number(chatIdFromUrl) &&
          isPrivateChatActive !== false &&
          PrivateChat !== true
        ) {
          deleteSingleChatHistory(Number(chatIdFromUrl));
          removeMultipleChat([Number(chatIdFromUrl)]);
          sessionStorage.removeItem("PrivateChatId");
        }
        handleDeleteS3Link();
        push(`/${RoutePaths.Chat}/${ChatRoute.New}`);
        setIsMainScreenOpen?.(true);
        setSelectedFile && setSelectedFile(null);
        const GPTModal = localStorage.getItem("GptModel");
        if (GPTModal && GPTModal !== "undefined") {
          const gptModel = JSON.parse(GPTModal);
          setChatModel(gptModel);
        } else if (
          gptModel?.type?.includes(ChatType.image) &&
          !gptModel?.type?.includes(ChatType.image_chat)
        ) {
          setChatModel(chatModels[0]);
        }
      }
      startNewChat();
      onChangeChat?.();
    }
  };

  const onSelectChatItem = (chatItem: IChat | undefined, folder_id: number) => {
    setRememberHistoryType(chatItem?.chat_type ?? "");
    controllerRef?.current?.abort();
    setShare?.(false);
    setUploadingFiles?.([]);
    setSelectedMessages?.([]);
    setFileS3Link?.([]);
    setChatItem && setChatItem(chatItem);
    handleDeleteS3Link();
    ResetBotSetting();
    regenerateModelNme('', false);
    push(`/${RoutePaths.Chat}/${ChatRoute.History}/${chatItem?.id}/${folder_id}`);
    setTimeout(() => setSearchWord(searchQuery ?? ""), 10);
    onChangeChat?.();
    setIsMainScreenOpen?.(true);
    setSelectedFile && setSelectedFile(null);
    if (
      gptModel &&
      !gptModel?.type?.includes(chatItem?.chat_type as EChatType)
    ) {
      setChatModel(
        chatModels?.filter((item) =>
          item?.type?.includes(chatItem?.chat_type as EChatType)
        )?.[0]
      );
    }
    if (chatItem?.model) {
      const filteredChatModels = chatModels?.filter(
        (item) => item?.name === chatItem?.model?.name
      );
      setChatModel(filteredChatModels[0]);
    }
  };

  useEffect(() => {
    if (
      newMessages[0]?.images?.length > 0 ||
      (newMessages[0]?.files && newMessages[0]?.files.length > 0)
    )
      setAllow(true);
    else setAllow(false);
  }, [newMessages]);

  const handleDragEnter = (e: React.DragEvent) => {
    e.preventDefault();
    e.stopPropagation();
    if (isAllowUploadFile) {
      setIsDrag!(true);
      if (!allowDrag || history) {
        setIsMainScreenOpen?.(false);
        updateChatModel?.("document");
      }
    }
  };
  const handleDragOver = (e: React.DragEvent) => {
    e.preventDefault();
    e.stopPropagation();
  };
  const handleDragLeave = (e: React.DragEvent) => {
    e.preventDefault();
    e.stopPropagation();
    if (e.relatedTarget === null) {
      setIsDrag!(false);
      setIsMainScreenOpen?.(true);
    }
  };

  const isChat = includeRoute(RoutePaths.Chat);
  const isSettings = includeRoute(RoutePaths.Settings);

  // Function to monitor for mouse or touch events which may lead to scrolling
  const prepareForUserScroll = () => {
    userScrolled = true;
  };

  const prepareForUserScrollOut = (event: TouchEvent) => {
    const target = event.target as HTMLElement;
    if (target === textareaRef?.current) {
      userScrolled = false;
    }
  };

  const handleScroll = () => {
    if (
      isIOS &&
      isSafari &&
      textareaRef &&
      textareaRef.current &&
      userScrolled &&
      width <= 576
    ) {
      textareaRef.current.blur();
      userScrolled = false;
    }
  };

  // Event listeners to flag user-initiated scrolling
  window.addEventListener("wheel", prepareForUserScroll);
  window.addEventListener("touchmove", prepareForUserScroll);
  window.addEventListener("touchstart", prepareForUserScrollOut);

  window.addEventListener("scroll", handleScroll, { passive: true });

  // Cleanup function to remove event listeners when appropriate (e.g., component unmount in React)
  const cleanup = () => {
    window.removeEventListener("wheel", prepareForUserScroll);
    window.removeEventListener("touchmove", prepareForUserScroll);
    window.removeEventListener("touchstart", prepareForUserScrollOut);
    window.removeEventListener("scroll", handleScroll);
  };

  useEffect(() => {
    return () => {
      cleanup();
    };
  }, []);

  useEffect(() => {
    const onLogout = () => {
      logout()
        .then(() => {
          localStorage.removeItem("email");
        })
        .catch((err: any) => {
          console.log(err);
        });
    };
    document.addEventListener("executeExitOnExtension", onLogout);
    return () => {
      document.removeEventListener("executeExitOnExtension", onLogout);
    };
  }, []);

  const updatePaneStyle = (size: number) => {
    if (size > 240) {
      setPaneStyle({ width: `${size}px`, marginRight: '', transition: 'none' });
    }
    else {
      const newMarginRight = size > 110 && size < 240 ? `-${size - 110}px` : '';
      window.requestAnimationFrame(() => {
        setPaneStyle((prevStyle) => {
          if ((prevStyle.width !== `${size}px` || prevStyle.marginRight !== newMarginRight)) {
            return {
              width: `${size}px`, marginRight: newMarginRight,
              transition: 'none'
            };
          }
          return prevStyle;
        });
      });
    }
  };

  const handleSizeChange = (size: number) => {
    setPaneWidth(size);
    if (size <= 240) {
      if (isOpen) {
        localStorage.setItem('sidebarWidth', '110');
        toggleSidebar();
        setTimeout(() => setPaneStyle({ width: `${110}px`, marginRight: '', transition: 'none' }), 20);
      }
      localStorage.setItem('sidebarWidth', '110');
      setTimeout(() => {
        setSidebarWidth(110);
        setPaneWidth(110)
      });
    } else if (size >= 240) {
      if (!isOpen) toggleSidebar();
      setSidebarWidth(size);
      localStorage.setItem('sidebarWidth', size.toString());
    }
    else setSidebarWidth(size);
    window.requestAnimationFrame(() => updatePaneStyle(size));
  }

  return (
    <div
      ref={containerRef}
      onScroll={handleScroll}
      style={{ width: "100%", height: "100%" }}
      onDragOver={handleDragOver}
      onDragEnter={handleDragEnter}
      onDragLeave={handleDragLeave}
    >
      <div
        className={classNames(styles.container, {
          [styles.lightSettings]:
            theme === "light" && (isSettings || isShareChat),
          [styles.lightChat]: theme === "light" && isChat && !isShareChat,
          [styles.dark]: theme === "dark",
          [styles.chatContainer]: isChat,
          [styles.settingContainer]: isSettings,
          [styles.isSidebarOpen]: !isOpen && !isShareChat,
          [styles.isShareChat]: isShareChat && theme === "light",
        })}
      >
        
        {( !isShareChat) ? (
          <CustomSplitPane
            split="vertical"
            minSize={110}
            maxSize={maxSidebarWidth}
            pane1Style={paneStyle}
            size={sidebarWidth}
            allowResize={true}
            onChange={handleSizeChange}
            style={{ position: 'relative', height: '100%' }}
          >
            {!isShareChat && (
              <Sidebar
                onSelectChatItem={onSelectChatItem}
                onStartNewChat={onStartNewChat}
                isMainScreenOpen={isMainScreenOpen}
                searchQuery={searchQuery}
                setSearchQuery={setSearchQuery}
                setOpenHistory={setOpenHistory}
                sendMessageLoading={sendMessageLoading}
                isAnswerComplete={isAnswerComplete}
                sidebarWidth={sidebarWidth}
              />
            )}
            <MainLeftContainer
              onStartNewChat={onStartNewChat}
              isMainScreenOpen={isMainScreenOpen}
              docuemntModel={docuemntModel}
              setChatSetting={setChatSetting}
              isFileUploading={isFileUploading}
              uploadingFiles={uploadingFiles}
              contentContainerRef={contentContainerRef}
              handleScroll={handleScroll}
              children={children}
            />
          </CustomSplitPane>
          ):(
           <>
            <MainLeftContainer
              onStartNewChat={onStartNewChat}
              isMainScreenOpen={isMainScreenOpen}
              docuemntModel={docuemntModel}
              setChatSetting={setChatSetting}
              isFileUploading={isFileUploading}
              uploadingFiles={uploadingFiles}
              contentContainerRef={contentContainerRef}
              handleScroll={handleScroll}
              children={children}
            /></>
          )}
      </div>
    </div>
  );
};
