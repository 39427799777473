import { useEffect, useState } from "react";
import { PromptsList } from "./PromptsList";

import { useSelector } from "redux/hooks";
import { IPrompt } from "redux/actions";
import { Spinner } from "components";
import { PromptOptions } from "./PomptOptions";
import { useWindowSize } from "hooks/useWindowSize";

interface IProps {
  handleSelectPrompt: (prompt: any) => void;
  promptLoading: boolean;
  searchQuery: any;
  userPromptLoading: boolean;
  setEditPrompt?: React.Dispatch<React.SetStateAction<number | null>>;
  setAllowEditPrompt?: React.Dispatch<React.SetStateAction<boolean>>;
}

export const Prompts = ({
  handleSelectPrompt,
  promptLoading,
  searchQuery,
  userPromptLoading,
  setEditPrompt,
  setAllowEditPrompt,
}: IProps) => {
  const { userPrompts, communityPrompts } = useSelector(
    (state) => state.chatReducer
  );
  const [filteredPrompts, setFilteredPrompts] = useState<IPrompt[]>([]);
  const [filteredUserPrompts, setFilteredUserPrompts] = useState<IPrompt[]>([]);
  const [activeTab, setActiveTab] = useState("myprompts");
  const { width } = useWindowSize();

  useEffect(() => {
    const query = searchQuery.toLowerCase();

    const newFilteredPrompts = communityPrompts.filter((prompt) => {
      return (
        prompt.title.toLowerCase().includes(query) ||
        prompt.prompt.toLowerCase().includes(query)
      );
    });
    setFilteredPrompts(newFilteredPrompts);

    const newFilteredUserPrompts = userPrompts.filter((prompt) => {
      return (
        prompt.title.toLowerCase().includes(query) ||
        prompt.prompt.toLowerCase().includes(query)
      );        
    });
    setFilteredUserPrompts(newFilteredUserPrompts);
  }, [searchQuery, communityPrompts, userPrompts]);

  const data = [
    {
      label: "prompt.user",
      value: "myprompts",
    },
    {
      label: "prompt.community",
      value: "communityprompts",
    },
  ];

  return (
    <>
      <div className={`flex flex-col gap-2 w-full h-90 ${width <= 576 ? "mt-5" : "mt-4"} `}>
        {userPromptLoading && promptLoading && (
          <div className="flex items-center justify-center">
            <Spinner small />
          </div>
        )}
     
        {!userPromptLoading &&
          <div className="w-full">
            <PromptOptions
              data={data}
              activeTab={activeTab}
              setActiveTab={setActiveTab} />
            <div className={` mt-[10px] overflow-y-auto overflow-scrolling-touch ${width <= 576 ? "max-h-[328px]" : "max-h-[300px]"}`} style={{}}>
              {data.map(({ value }) => (
                <div key={value} className={`${activeTab === value ? "" : "hidden"}`}>
                  <PromptsList
                    isloading={userPromptLoading || promptLoading}
                    prompts={value === "myprompts" ? userPrompts : communityPrompts}
                    filteredPrompts={value === "myprompts" ? filteredUserPrompts : filteredPrompts}
                    onSelect={handleSelectPrompt}
                    dataTestId={value === "myprompts" ? 'user-prompts' : 'community-prompts'}
                    setEditPrompt={setEditPrompt}
                    setAllowEditPrompt={setAllowEditPrompt}
                  />
                </div>
              ))}
            </div>
          </div>
        }
      </div>
    </>
  );
};
