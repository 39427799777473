import React, { useCallback, useState, useEffect, useRef } from "react";
import { getFolder } from "redux/actions";

export const useDynamicFolderHeights = (folders: getFolder[]) => {
    const [folderHeights, setFolderHeights] = useState<Record<number, number>>({});
    const folderContainerRef = useRef<HTMLDivElement>(null);
    const folderRefs = useRef<{ [key: string]: React.RefObject<HTMLDivElement> }>({});

    const calculateFolderHeights = useCallback(() => {
        if (!folderContainerRef.current || !folders?.length) return {};

        const FOLDER_HEADER_HEIGHT = 26; // Height of folder header
        const CHAT_ITEM_HEIGHT = 34; // Height of each chat item
        const BOTTOM_PADDING = 10; 

        const windowHeight = window.innerHeight;

        // Calculate total number of chats across all folders
        const totalChats = folders.reduce((sum, folder) => sum + folder.chats.length, 0);

        // Calculate available height per folder
        const availableHeight = windowHeight - BOTTOM_PADDING;
        
        // Calculate heights for each folder
        const calculatedHeights = folders.reduce<Record<number, number>>((acc, folder) => {
            // Initialize ref for this folder if not exists
            if (!folderRefs.current[folder.id]) {
                folderRefs.current[folder.id] = React.createRef<HTMLDivElement>();
            }

            // Calculate proportional height based on number of chats
            const chatCount = folder.chats.length;
            
            // If no chats, set minimal height
            if (chatCount === 0) {
                acc[folder.id] = FOLDER_HEADER_HEIGHT;
                return acc;
            }

            // Calculate height considering the proportion of chats in this folder
            const proportionalHeight = totalChats > 0
                ? Math.floor((chatCount / totalChats) * availableHeight)
                : 0;

            // Calculate total content height for this folder
            const totalContentHeight = FOLDER_HEADER_HEIGHT + (chatCount * CHAT_ITEM_HEIGHT);

            // Determine final height
            // Use the maximum of proportional height and total content height
            const calculatedHeight = Math.max(proportionalHeight, totalContentHeight);

            // Absolute maximum to prevent overflow
            const maxHeight = availableHeight * 0.8; // Max 80% of available height

            acc[folder.id] = Math.min(calculatedHeight, maxHeight);

            return acc;
        }, {});

        return calculatedHeights;
    }, [folders]);

    // Calculate heights on initial render and when folders change
    useEffect(() => {
        // Calculate and set folder heights
        const newFolderHeights = calculateFolderHeights();
        setFolderHeights(newFolderHeights);

        // Add resize listener to recalculate on window resize
        const handleResize = () => {
            const resizedHeights = calculateFolderHeights();
            setFolderHeights(resizedHeights);
        };

        window.addEventListener('resize', handleResize);

        // Cleanup listener on unmount
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [calculateFolderHeights]);

    return { 
        folderHeights, 
        folderContainerRef, 
        folderRefs 
    };
};