import React, { memo, } from "react";
import { FormattedMessage } from "react-intl";
import { useSelector } from "redux/hooks";
import { Modal, Spinner } from "components";
import CustomButton from "components/Button";
import { FeatureCard } from "pages/SettingsPage/pages/CurrentPlan/components/components";
import Switch from "components/Switch";

import styles from './chatSettingModal.module.scss';
import { ChatSetting, IChatSetting, SetChatSetting } from "redux/actions";

interface IProps {
    onClose?: () => void;
    loadingSetting?: boolean;
    settings: {
        real_time_results: boolean;
        related_questions: boolean;
        send_message_with_enter: boolean;
    };
    setSettings?: React.Dispatch<React.SetStateAction<IChatSetting>>;
}

export const ChatSettingModal = memo(({ onClose, settings, setSettings, loadingSetting }: IProps) => {
    const { theme } = useSelector((state) => state.authReducer);
    const { real_time_results, related_questions, send_message_with_enter } = settings;

    const handleDefaultValue = () => {
        setSettings && setSettings({
            real_time_results: false,
            related_questions: true,
            send_message_with_enter: true,
        });
        const data: ChatSetting = {
            key: "chat_settings",
            real_time_results: false,
            related_questions: true,
            send_message_with_enter: true,
        }
        window.postMessage({ settings: data });
        SetChatSetting(data)
    };

    const handleSwitchChange = (newValue: boolean, type: string) => {
        let updatedSettings: Partial<IChatSetting> = {};
        if (type === 'realTimeResult') {
            updatedSettings = { ...updatedSettings, real_time_results: newValue };
            handleSaveSetting(newValue, related_questions || false, send_message_with_enter || false, type);
        } else if (type === 'relatedQuestion') {
            updatedSettings = { ...updatedSettings, related_questions: newValue };
            handleSaveSetting(newValue, real_time_results || false, send_message_with_enter || false, type);
        } else if (type === 'allowEnter') {
            updatedSettings = { ...updatedSettings, send_message_with_enter: newValue };
            handleSaveSetting(newValue, real_time_results || false, related_questions || false, type);
        }
        setSettings && setSettings(prevSettings => ({ ...prevSettings, ...updatedSettings }));
    };

    const handleSaveSetting = (option1: boolean, option2: boolean, option3: boolean, type: string) => {
        const data: ChatSetting = {
            key: "chat_settings",
            real_time_results: type === 'realTimeResult' ? option1 : option2,
            related_questions: type === 'relatedQuestion' ? option1 : type === 'realTimeResult' ? option2 : option3,
            send_message_with_enter: type === 'allowEnter' ? option1 : option3,
        }
        window.postMessage({ settings: data })
        SetChatSetting(data)
    };

    return (
        <Modal size="md" onClose={onClose} chatSetting={true}>
            <div className="text-center text-[18px] leading-[20px] font-bold py-2">
                <FormattedMessage id="chat.setting" />
            </div>
            {loadingSetting ?
                <div className="flex justify-center align-center">
                    <Spinner small />
                </div> :
                <>
                    {[{ label: 'chat.setting.option1', title: 'chat.setting.option1.title', value: real_time_results || false, type: 'realTimeResult' },
                    { label: 'chat.setting.option2', value: related_questions || false, type: 'relatedQuestion' },
                    { label: 'chat.setting.option3', title: 'chat.setting.option3.title', value: send_message_with_enter || false, type: 'allowEnter' }].map(option => (
                        <div className="py-3 px-2" key={option.label}>
                            <FeatureCard themeMode={theme} isBackground={true} chatSetting={true} className={styles.chatSetting}>
                                <div className={styles.textContainer} style={{ width: '100%' }}>
                                    <div
                                        className={`${option.type === 'allowEnter' ? 'flex gap-3 items-center' : ''}`}
                                    >
                                        <div className={styles.feature}>
                                            <span className="opacity-[60%]"> <FormattedMessage id={option.label} /> </span>

                                        </div>
                                        {option.type === 'allowEnter' && (
                                            <span
                                                className={` ${styles.usageTaghead}`}
                                            >
                                                <FormattedMessage id="chat.setting.return" />
                                            </span>
                                        )}
                                    </div>
                                    {option.title && (
                                        <div>
                                            <div className={styles.title}
                                            >
                                                <span className="opacity-[60%]"><FormattedMessage id={option.title} /></span>
                                                {option.type === 'allowEnter' && (
                                                    <span className={`ml-[15px] ${styles.usageTag}`}>
                                                        <FormattedMessage id="chat.setting.return" />
                                                    </span>
                                                )}
                                            </div>

                                        </div>
                                    )}
                                </div>
                                <div>
                                    <Switch
                                        checked={option?.type == 'allowEnter' ? !option?.value : option?.value}
                                        chatSetting={true} onChange={() => handleSwitchChange(!option.value, option.type)} />
                                </div>
                            </FeatureCard>
                        </div>
                    ))}
                    <div className="w-full flex justify-center pb-2 mt-[9px]">
                        <CustomButton variant="primary" data-testid='default-chat-setting' className={styles.planbutton} onClick={handleDefaultValue}>
                            <div className={styles.text}>
                                <FormattedMessage id="chat.setting.default" />
                            </div>
                        </CustomButton>
                    </div>
                </>
            }
        </Modal>
    );
});
