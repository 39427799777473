import { useState,useEffect } from "react";
import { useEffectOnce } from "react-use";
import {
  FormattedMessage,
} from "react-intl";

import classNames from "classnames";
import styled from "styled-components";
import styles from "./Pricing.module.scss";
import textStyles from "../components/PriceCard/PriceCard.module.scss";

import CustomButton from "components/Button";
import { LoadingPage } from "components";
import { MobilePricing } from "./MobilePricing";

import { EPlanDuration, getPlan } from "redux/actions";
import { useSelector } from "redux/hooks";
import { EThemeType } from "redux/reducers";
import { Main } from "components/Main";
import { FrequentlyAskedQuestions } from "./FAQs";

const IterFont = styled.span`
  font-family: ${({ theme }) => theme.regularFont};
`;

const DurationButton = styled(CustomButton) <{ active: boolean, theme: EThemeType }>`
  background-color: ${({ active, theme }) => (active ? "#5b71f5" : theme === "dark" ? "#23212C" : "#fff")};
  color: ${({ active }) => (active ? "#fff" : "")};
`;

export const Pricing = () => {
  const plans = useSelector((state) => state.planSubscriptionReducer.plan);
  const { userDetail, theme } = useSelector((state) => state.authReducer);

  const [duration, setDuration] = useState<EPlanDuration>("month");
  const [isloading, setIsloading] = useState<boolean>(false);

  useEffectOnce(() => {
    window.scrollTo(0, 0);
  });

  useEffect(() => {
    if (plans && plans.length > 0) {
      return;
    }
    setIsloading(true);
    getPlan()
      .then(() => {
        setIsloading(false);
      })
      .catch(() => {
        setIsloading(false);
      });
  }, [plans]);
  
  const onChange = (prev?: EPlanDuration) => {
    const newDuration = prev === "month" ? "year" : "month";
    setDuration(newDuration);
  };
  return (
    <Main isHasBgOnFooter activeSection="pricing">
    <div style={{
      backgroundImage: `url(/landing/${theme}/Plans-bg.png)`,
      backgroundSize: "cover",
      backgroundPosition: "center",
    }}>
      <section id="pricing"
        className={classNames(styles.pricingSection, {
          [styles.light]: theme === "light",
          [styles.dark]: theme === "dark",
        })}
      >
        {isloading ? (
          <div className="mb-[13%] mt-[7%]">
          <LoadingPage />
          </div>
        ) : (
          <>
            <div className={styles.adjustMargin}>
              <h2
                className={classNames(styles.title, {
                  [styles.light]: theme === "light",
                  [styles.dark]: theme === "dark",
                })}
              >
                <FormattedMessage id= "landing.pricing.title" />
              </h2>
              <div className="w-[100%] flex justify-center">
                <p
                  className={classNames(styles.desc, {
                    [styles.light]: theme === "light",
                    [styles.dark]: theme === "dark",
                  })}
                >
                  <FormattedMessage id="landing.price.desc" />
                </p>
              </div>
            </div>
            <div className={styles.customBtn}>
              <DurationButton
                planBtn={true}
                theme={theme}
                onClick={() => setDuration("month")}
                active={duration === "month"}
              >
                <span className={styles.monthly}>
                  <FormattedMessage id="landing.monthly" />
                </span>
              </DurationButton>
              <DurationButton
                planBtn={true}
                theme={theme}
                onClick={() => setDuration("year")}
                active={duration === "year"}
              >
                <span className={styles.yearly}>
                  <FormattedMessage id="landing.yearly" />
                </span>
              </DurationButton>
            </div>

            <MobilePricing
              isLoggedIn={false}
              isSwitch={true}
              plans={plans}
              userDetail={userDetail}
              theme={theme}
              duration={duration}
              onChange={onChange}
            />
            <div className="max-w-[100%] mx-auto w-full px-[15px] md:px-0 hidden lg:block">

              <div className="mx-auto flex flex-col items-center leading-8">
                <p
                  className={classNames("text-[16px] my-[15px]", {
                    [textStyles.darkText]: theme === "dark",
                    [textStyles.lightText]: theme === "light",
                  })}
                >
                  <FormattedMessage
                    id="landing.price.table.bottom"
                    values={{
                      number: (
                        <span className="font-semibold">
                          <IterFont>100000</IterFont>
                        </span>
                      ),
                      price: (
                        <span className="font-semibold">
                          <IterFont>$500</IterFont>
                        </span>
                      ),
                    }}
                  />
                </p>
              </div>
            </div>
          </>
        )}
      </section>
      <FrequentlyAskedQuestions/>
    </div>
    </Main>
  );
};
