import { EThemeType } from "redux/reducers";
import {
  DallEIcon,
  StableDiffusionIcon,
  DownloadIcon,
} from "./icons";
import { FormattedMessage } from "react-intl";
import { useWindowSize } from "hooks/useWindowSize";

interface IHowToUseeProps {
  themeMode?: EThemeType;
}

const iconClassName =
  " w-[60px] h-[60px] md:w-[44px] md:h-[44px] lg:w-[50px] lg:h-[50px]";

export const HowToUse = ({ themeMode }: IHowToUseeProps) => {
  
  const { width } = useWindowSize();
  return (
    <div
      style={{
        backgroundImage: `url(/feature/bgImages/bg-003-${themeMode}.jpeg)`,
        backgroundSize: "cover",
        backgroundPosition: "center",
      }}
    >
      <div className={`text-light dark:text-dark px-[10%] ${width<=576 ? "px-10 pt-5 pb-[55px]":"py-14 md:py-28 px-[10%]" }`}>
        <h2 className={`flex text-center w-fit mx-auto bg-gradient-features-right bg-clip-text text-transparent ${width <=405 ? "text-[26px] mb-[55px] mt-5 w-[210px] ml-auto mr-auto": width<=576 ? "text-[34px] mb-[55px] mt-5 w-[283px] ml-auto mr-auto":"text-[20px] sm:text-[24px] md:text-[28px] lg:text-[32px]  mb-10 md:mb-20"}`}>
          <FormattedMessage id="feature.imageGenerator.use.title" />
        </h2>
        <div className="flex flex-col lg:flex-row justify-center gap-12 lg:py-8">
          <div className="rounded-[15px] bg-[rgba(255,255,255,0.3)] dark:border dark:border-solid dark:border-white dark:border-opacity-5 dark:bg-[rgba(255,255,255,0.05)] flex flex-col p-6 max-w-[392px] flex-1">
            <div className="mb-4">
              <img
                src="/feature/imageGenerator/loader.png"
                alt=""
                className={iconClassName}
              />
            </div>
            <p className="text-[16px] md:text-[18px] lg:text-[20px] leading-6 md:leading-7 lg:leading-8 font-bold">
              <FormattedMessage id="feature.imageGenerator.use.hightQualityImageTitle" />
            </p>
          </div>
          <div className="rounded-[15px] bg-[rgba(255,255,255,0.3)] flex flex-col p-6 max-w-[392px] dark:border dark:border-solid dark:border-white dark:border-opacity-5 dark:bg-[rgba(255,255,255,0.05)] flex-1">
            <div className="flex flex-row gap-12 mb-4">
              <DallEIcon className={iconClassName} />
              <StableDiffusionIcon className={iconClassName} />
            </div>
            <p className="text-[16px] md:text-[18px] lg:text-[20px] leading-6 md:leading-7 lg:leading-8 font-bold">
              <FormattedMessage id="feature.imageGenerator.use.sourceTitle" />
            </p>
          </div>
          <div className="rounded-[15px] bg-[rgba(255,255,255,0.3)] flex flex-col p-6 max-w-[392px] flex-1 dark:border dark:border-solid dark:border-white dark:border-opacity-5 dark:bg-[rgba(255,255,255,0.05)] ">
            <div className="mb-4">
              <DownloadIcon className={iconClassName} />
            </div>
            <p className="text-[16px] md:text-[18px] lg:text-[20px] leading-6 md:leading-7 lg:leading-8 font-bold">
              <FormattedMessage id="feature.imageGenerator.use.DownloadTitle" />
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};
