import Dropdown from "rc-dropdown";
import Menu, { Item as MenuItem } from "rc-menu";
import { FormattedMessage } from "react-intl";

import classNames from "classnames";
import "rc-dropdown/assets/index.css";
import styles from "./ActionsMenu.module.scss";

import { MenuIcon } from "../icons";

import { Separator } from "components";
import { IMember, UserRolesType } from "redux/actions";
import { useSelector } from "redux/hooks";

export interface IActionProps {
  toggleChangeRoleModal?: () => void;
  toggleCreditlimitModal?: () => void;
  toggleDeleteModal?: () => void;
  toggleResendInviteModal?: () => void;
  toggleCancelInviteModal?: () => void;
  onSelectMember?: (member: IMember | undefined) => void;
  userRole?: UserRolesType;
  toggleEditName?: () => void;
}

interface IMemberActionProps extends IActionProps {
  isInvited?: boolean;
  member?: IMember;
}

export const ActionsMenu = ({
  userRole,
  isInvited,
  member,
  toggleChangeRoleModal,
  toggleCreditlimitModal,
  toggleDeleteModal,
  toggleResendInviteModal,
  toggleCancelInviteModal,
  onSelectMember,
  // toggleEditName,
}: IMemberActionProps) => {
  const { userDetail, theme } = useSelector((state) => state.authReducer);
  const planName = userDetail?.user.activeSubscription.name;

  return (
    <Dropdown
      trigger={["click"]}
      overlay={
        <Menu
          style={{
            width: "180px",
            backgroundColor:
              theme === "dark"
                ? "rgba(255, 255, 255, 0.03)"
                : "rgba(42, 40, 49, 0.03)",
            border: "1px solid rgba(255, 255, 255, 0.05)",
            backdropFilter: "blur(15px)",
            borderRadius: "10px",
            boxShadow: "none",
            padding: "12px 16px",
          }}
        >
          {!isInvited ? (
            <>
              {userRole === "admin" && (
                <>
                  {/* <MenuItem
                    key="1"
                    className={classNames(styles.item, {
                      [styles.light]: theme === "light",
                      [styles.dark]: theme === "dark",
                    })}
                    onClick={() => {
                      toggleEditName?.();
                      onSelectMember?.(member);
                    }}
                    data-testid='team-table-edit-name'
                  >
                    <FormattedMessage id="team.table.action.editname" />
                  </MenuItem> */}
                 
                  {/* <Separator height="12px" /> */}
                  
                  <MenuItem
                    key="1"
                    className={classNames(styles.item, {
                      [styles.light]: theme === "light",
                      [styles.dark]: theme === "dark",
                    })}
                    onClick={() => {
                      toggleChangeRoleModal?.();
                      onSelectMember?.(member);
                    }}
                    data-testid='team-table-change-status'
                  >
                    <FormattedMessage id="team.table.action.changeStatus" />
                  </MenuItem>
                  <Separator height="12px" />
                </>
              )}
              {planName !== "Free" && (
                <>
                  <MenuItem
                    key="2"
                    className={classNames(styles.item, {
                      [styles.light]: theme === "light",
                      [styles.dark]: theme === "dark",
                    })}
                    onClick={() => {
                      toggleCreditlimitModal?.();
                      onSelectMember?.(member);
                    }}
                    data-testid='team-table-edit-credits'
                  >
                    <FormattedMessage id="team.table.action.editCreditLimit" />
                  </MenuItem>
                  <Separator height="12px" />
                  <div
                    className={classNames(styles.lineSeparator, {
                      [styles.light]: theme === "light",
                      [styles.dark]: theme === "dark",
                    })}
                  />
                  <Separator height="12px" />
                  <MenuItem
                    key="3"
                    className={classNames(styles.item, {
                      [styles.light]: theme === "light",
                      [styles.dark]: theme === "dark",
                    })}
                    onClick={() => {
                      toggleDeleteModal?.();
                      onSelectMember?.(member);
                    }}
                    data-testid='team-table-delete-user'
                  >
                    <FormattedMessage id="team.table.action.deleteUser" />
                  </MenuItem>
                </>
              )}
            </>
          ) : (
            <>
              {member?.is_expired && (
                <>
                  <MenuItem
                    key="1"
                    className={classNames(styles.item, {
                      [styles.light]: theme === "light",
                      [styles.dark]: theme === "dark",
                    })}
                    onClick={() => {
                      toggleResendInviteModal?.();
                      onSelectMember?.(member);
                    }}
                    data-testid='team-table-resend-invite'
                  >
                    <FormattedMessage id="team.table.action.resendInvite" />
                  </MenuItem>
                  <Separator height="12px" />
                </>
              )}
              <MenuItem
                key="2"
                className={classNames(styles.item, {
                  [styles.light]: theme === "light",
                  [styles.dark]: theme === "dark",
                })}
                onClick={() => {
                  toggleCancelInviteModal?.();
                  onSelectMember?.(member);
                }}
                data-testid='team-table-cancel-invite'
              >
                <FormattedMessage id="team.table.action.cancelInvite" />
              </MenuItem>
            </>
          )}
        </Menu>
      }
      alignPoint
    >
      <button
        className={classNames(styles.button, {
          [styles.light]: theme === "light",
          [styles.dark]: theme === "dark",
        })}
        data-testid='team-invite-dropdown'
      >
        <MenuIcon />
      </button>
    </Dropdown>
  );
};

