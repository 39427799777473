import { Modal } from "components/Modal";
import { FormattedMessage } from "react-intl";
import styles from './DeleteConfirm.module.scss'
import Button from "components/Button";
import { Separator } from "components";
import { CloseIcon } from "components/icons/CloseIcon";
import styled from "styled-components";
import { useSelector } from "redux/hooks";
import { EThemeType } from "redux/reducers";
import classNames from "classnames";

interface IProp {
    onSubmit: () => void;
    onCancel: () => void;
    loading: boolean;
    delAccount?: boolean;
    delConversation?: boolean;
    delFolder?: boolean;
}

const CancelBtn = styled(Button) <{ theme: EThemeType }> `
  flex-shrink: 0;
  border-radius: 5px;
  border: 1.5px solid  ${({ theme }) => (theme === "dark" ? "#fff" : "#2A2831")};
  padding: 10px 15px;
 
`;

const DeleteBtn = styled(Button)`
  flex-shrink: 0;
  border-radius: 5px;
  padding: 11.5px 15px;
 
`;

export const DeleteChatConfirm = ({ onCancel, onSubmit, loading, delAccount, delConversation, delFolder }: IProp) => {

    const { theme } = useSelector((state) => state.authReducer);

    return (
        <Modal size="md" onClose={onCancel} deleteConfirmationModal={true}>
            <div className="py-[20px] px-[20px] text-light dark:text-prompt-dark">
                <div className="flex justify-end">
                    <div
                        className={styles.crossBtn}
                        onClick={onCancel}
                        data-testid="confirmation-modal-cross-btn"
                    >
                        <CloseIcon />
                    </div>
                </div>
                <div className={`leading-[20px] font-bold -mt-[9px] ml-[12px]  ${styles.textheadSize}`}>
                    <FormattedMessage id={delFolder? "folder.del.head" : delAccount ? "delete.user.Account" : delConversation ? "delete.all.conversation" : "chat.delete.selected.heading"} />
                </div>
                <div className={`text-center pt-[28px] px-[7px] ${styles.textSize}`}>
                    <FormattedMessage id={delFolder? "folder.del.confirmation" : delAccount ? "delete.user.Account.title" : delConversation ? "delete.all.conversation.heading" : "chat.delete.selected.confirm"} />
                </div>
                <Separator height="20px" />
                <div className={styles.footer}>
                    <CancelBtn data-testid='close-btn' theme={theme} onClick={onCancel}>
                        <div className={classNames(styles.buttonText, {
                            [styles.btnTextDark]: theme === "dark",
                        })} >
                            <FormattedMessage id="creditLimit.modal.cancelBtn.text" />
                        </div>
                    </CancelBtn>
                    <DeleteBtn
                        variant="delete"
                        onClick={onSubmit}
                        isloading={loading}
                        data-testid='submit-btn'
                    >
                        <div>
                            <FormattedMessage id="modal.deleteMember.btn.delete" />
                        </div>
                    </DeleteBtn>
                </div>
            </div>

        </Modal>
    );
};
