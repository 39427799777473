import { FormattedMessage } from "react-intl";
import styles from "./DeActivate.module.scss";

import { CloseIcon } from "components/icons/CloseIcon";
import { Modal } from "components";
import CustomButton from "components/Button";

import { useSelector } from "redux/hooks";
import classNames from "classnames";
import { useState } from "react";
import { useWindowSize } from "react-use";

interface IProps {
    onClose?: () => void;
    onSubmit?: () => void;
    loading?: boolean;
}

export const DeActivateAccount = ({
    onClose,
    loading,
    onSubmit
}: IProps) => {

    const { width } = useWindowSize();
    const { theme } = useSelector((state) => state.authReducer);
    const [confirmDeActivation, setConfirmDeActivation] = useState<boolean>(false);

    return (
        <>
            {(!confirmDeActivation) && (
                <Modal onClose={onClose} deActivateAccount updateEmail>
                    <div className={styles.header}>
                        <div className={styles.headerTextContainer}>
                            <div className={styles.title}>
                                <FormattedMessage id={"account.deactivate.heading"} />
                            </div>
                        </div>
                        <div
                            className={styles.crossBtn}
                            onClick={onClose}
                            data-testid="team-form-cross-btn"
                        >
                            <CloseIcon />
                        </div>
                    </div>

                    <div className={styles.description}>
                        <FormattedMessage id={"account.deactivate.title"} />
                    </div>
                    <div className={`flex gap-[18px]`}>
                        <CustomButton
                            style={{ minWidth: width > 576 ? "100px" : "" }}
                            variant="latest-outlined"
                            type="button"
                            onClick={onClose}
                            data-testid='deactivate-account-btn-cancel'
                        >
                            <div
                                className={classNames(styles.buttonText, {
                                    [styles.light]: theme === "light",
                                    [styles.dark]: theme === "dark",
                                })}
                            >
                                <FormattedMessage id="modal.inviteTeam.btn.cancel" />
                            </div>
                        </CustomButton>
                        <CustomButton
                            variant="latest-primary"
                            type="submit"
                            onClick={() => {
                                setConfirmDeActivation(true)
                            }}
                            deActivate={true}
                            data-testid='deactivate-btn-account'
                        >
                            <FormattedMessage id="creditLimit.modal.continueBtn.text" />
                        </CustomButton>
                    </div>
                </Modal>
            )}

            {(confirmDeActivation) && (
                <Modal onClose={onClose} deActivateAccountConfirm updateEmail>
                    <div className="flex justify-end">
                        <div
                            className={styles.crossBtn}
                            onClick={onClose}
                            data-testid="team-form-cross-btn"
                        >
                            <CloseIcon />
                        </div>
                    </div>
                    <div className={`flex items-center justify-center w-full`}>
                        <div className={classNames(styles.ConfirmDeActivate,{
                            [styles.light]: theme === "light",
                            [styles.dark]: theme === "dark"
                        })}>
                            <FormattedMessage id={"Confirm.temporary.account"} />
                        </div>
                    </div>

                    <div className={classNames(styles.description, {
                        [styles.confirmDeActivate]: ( confirmDeActivation),
                    })}>
                        <FormattedMessage id={"confirm.temporary.title"} />
                    </div>
                    <div className={`flex gap-[18px]  ${( confirmDeActivation) ? "justify-center w-full" : ""}`}>
                        <CustomButton
                            style={{ minWidth: width > 576 ? "100px" : "" }}
                            variant="latest-outlined"
                            type="button"
                            onClick={onClose}
                            data-testid='deactivate-account-btn-cancel'
                        >
                            <div
                                className={classNames(styles.buttonText, {
                                    [styles.light]: theme === "light",
                                    [styles.dark]: theme === "dark",
                                })}
                            >
                                <FormattedMessage id="modal.inviteTeam.btn.cancel" />
                            </div>
                        </CustomButton>
                        <CustomButton
                            variant="latest-primary"
                            type="submit"
                            onClick={onSubmit}
                            className={ theme==="dark" && styles.confirmBtn}
                            deActivate={true}
                            isloading={loading}
                            data-testid='deactivate-btn-account'
                        >
                            <FormattedMessage id="deactivate.my.account" />
                        </CustomButton>
                    </div>
                </Modal>
            )}
        </>
    );
};
