import { FormattedMessage } from "react-intl";
import styles from "./ModalItem.module.scss";
import { useSelector } from "redux/hooks";

import { IChatModel } from "redux/actions";
import classNames from "classnames";

interface IProps {
  chatModel: IChatModel;
  selectedModal: IChatModel;
  onSelectModal: (chatModel: IChatModel) => void;
}

export const ModalItem = ({
  chatModel,
  // selectedModal,
  onSelectModal,
}: IProps) => {

  const { theme } = useSelector((state) => state.authReducer);

  return (
    <div data-testid='gpt-confirm-model' className={`${classNames(styles.container, {
      [styles.light]: theme === 'light',
      [styles.dark]: theme === 'dark',
    })} hover:cursor-pointer pl-[31px] pr-[20px] py-3`} onClick={() => onSelectModal(chatModel)}>
      <div className={styles.imageContainer}>
        <img className={chatModel.name === "flux-schnell" ? styles.image : ""} src={chatModel.image_url} />
      </div>
      <div className={styles.textContainer}>
        <div className={styles.modalName}>{chatModel.name}</div>
        {(chatModel?.type?.includes("image") && !chatModel?.type?.includes("image_chat"))? null : (
          <div className={styles.modelCreditPerQuery}>
            <FormattedMessage
              id="gptModal.modelCredits"
              values={{ credits: chatModel.credits_per_message }}
            />
          </div>
        )}
      </div>
    </div>
  );
};
