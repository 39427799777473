import { useState, useEffect, useCallback, useRef } from "react";
import { useEffectOnce } from "react-use";
import { FormattedMessage } from "react-intl";

import classNames from "classnames";
import styles from "./Account.module.scss";
import { CurrentPlanIcon } from "views/layout/Sidebar/component/SettingSidebar/components/icons";
import { EditNameIcon, OptionsIcon } from './icons/OptionsIcon';

import { DropDown, DropDownRow, Separator } from "components";
import { UserName } from "pages/ChatPage/pages/chatHistory/components/userName";
import { RoutePaths } from "pages/routePaths";

import useRouter from "hooks/useRouter";
import { languages } from "utils/languages";
import { useWindowSize } from "hooks/useWindowSize";

import {
  getActivePlan,
  getAllTeams,
  selectTeam,
  updateLanguage,
  getCreditLimits,
  removeAccount,
  IEditEmail,
  UpdateEmail,
  DeActivateUserAccount,
  IEditName,
  UpdateName,
  setTeamUpdated,
} from "redux/actions";
import { useAppNotification } from "hooks/services/AppNotification";
import { useSelector } from "redux/hooks";
import { DeleteAccount } from "views/layout/Sidebar/component/ChatSidebar/components/icons/DeleteHistory";
import { DeleteChatConfirm } from "views/layout/Sidebar/component/ChatSidebar/components/DeleteChatConfirm";
import { EditAccount } from "./EditAccount";
import { AccountDeactivate, EditEmail } from "views/layout/Sidebar/component/ChatSidebar/components/icons";
import { useToggleSidebar } from "hooks/services/ToggleSidebarProvider";
import { useSidebar } from "hooks/services/ReSizeSidebar/ReSizeSidebar";
import { DeActivateAccount } from "./DeActivateAccount";
import { EditName } from "./EditName";

interface UpdateNameResponse {
  message: string;
}

export const Account = () => {
  const { triggerNotification } = useAppNotification();
  const { userDetail, creditLimits, theme } = useSelector(
    (state) => state.authReducer
  );
  const teams = useSelector((state) => state.teamReducer.teams);
  const { teamSwitch } = useSelector((state) => state.teamReducer);
  const { push } = useRouter();

  const [teamOptions, setTeamOptions] = useState<DropDownRow.IDataItem[]>([]);
  const [team, setTeam] = useState<any>();
  const [language, setLanguage] = useState<string | undefined>(
    languages[0].value
  );
  const [delAccount, setDelAccount] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [editAccount, setEditAccount] = useState<boolean>(false);
  const [editEmailLoading, setEditLoading] = useState<boolean>(false);
  const [isConfirmationModalVisible, setConfirmationModalVisible] = useState(false);
  const [isDropdownOpen, setIsDropDownOpen] = useState<boolean>(false);
  const [deActivateAccount, setDeActivateAccount] = useState<boolean>(false);
  const [confirmDeActivationLoading, setConfirmDeActivationLoading] = useState<boolean>(false);
  const [editName, setEditName] = useState<boolean>(false);
  const [editNameLoading, setEditNameLoading] = useState<boolean>(false);

  const { width } = useWindowSize();
  const { sidebarWidth } = useSidebar();
  const { isOpen } = useToggleSidebar();

  const dropdownRef = useRef<HTMLDivElement | null>(null); // Reference to the dropdown
  const optionsIconRef = useRef<HTMLSpanElement | null>(null); // Reference to the options

  const onGetPlan = () => {
    getActivePlan().catch((err: any) => {
      triggerNotification({ message: err?.data?.message, type: "error" });
    });
  };

   useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      // Check if the click is outside the dropdown or options icon
      if (
        dropdownRef.current && !dropdownRef.current.contains(event.target as Node) &&
        optionsIconRef.current && !optionsIconRef.current.contains(event.target as Node)
      ) {
        setIsDropDownOpen(false); // Close the dropdown
      }
    };

    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside); // Clean up the event listener
    };
  }, []);

  const onGetTeams = () => {
    getAllTeams().catch((err: any) => {
      triggerNotification({ message: err?.data?.message, type: "error" });
    });
  };

  useEffectOnce(() => {
    onGetPlan();
    onGetTeams();
  });

  useEffect(() => {
    setLanguage(userDetail?.user.language || languages[0].value);
    if (teams !== undefined && teams.length > 0) {
      const isAvaliable = teams.findIndex(
        (team) => team.id === userDetail?.user.team.id
      );
      if (isAvaliable > -1) {
        setTeam(userDetail?.user.team.id);
      } else {
        const currentTeam = teams.find(
          (team) => team.id === userDetail?.user.activeSubscription.team_id
        );
        if (currentTeam) {
          setTeam(currentTeam.id);
          onSelectTeam(currentTeam.id);
        }
        else if (!currentTeam) {
          setTeam(teams[0]?.id);
          onSelectTeam(teams[0]?.id);
        }
      }
    }
  }, [userDetail, teams]);

  useEffect(() => {
    if (teams.length > 0) {
      setTeamOptions(
        teams.map((team) => {
          return {
            label: team.name,
            value: team.id,
          };
        })
      );
    }
  }, [teams]);

  const onSelectTeam = (team_id: number) => {
    setTeamUpdated(true);
    setTeam(team_id);
    selectTeam({ team_id })
      .then(() => {
        setTeamUpdated(false);
        getCreditLimits()
          .then(() => {
            setTeam(team_id);
          })
          .catch((err) => {
            setTeamUpdated(false);
            setTeam(userDetail?.user.team.id);
            triggerNotification({ message: err?.data?.message, type: "error" });
          });
      })
      .catch((err) => {
        setTeamUpdated(false);
        setTeam(userDetail?.user.team.id);
        triggerNotification({ message: err?.data?.message, type: "error" });
      });
  };

  const onUpdateLanguage = useCallback((lang: string) => {
    setLanguage(lang);
    updateLanguage(lang);
  }, []);

  const handleDelAccount = () => {
    setLoading(true);
    removeAccount().then(() => {
      localStorage.removeItem('email');
      localStorage.removeItem('sidebarWidth');
      setLoading(false);
      setDelAccount(false)

    }).catch((err) => {
      setLoading(false);
      setDelAccount(false)
      triggerNotification({ message: err?.data?.message, type: "error" });
    })
  }

  const handleEmailEditSubmit = (data: IEditEmail) => {
    setEditLoading(true);
    UpdateEmail(data).then(() => {
      setEditLoading(false);
      setEditAccount(false);
      setConfirmationModalVisible(true);
    }).catch((err) => {
      setEditLoading(false);
      setEditAccount(false);
      triggerNotification({ message: err?.data?.message, type: "error" });
    });
  }

  const handleConfirmDeActivation = () => {
    setConfirmDeActivationLoading(true);

    DeActivateUserAccount().then((res: any) => {
      setDeActivateAccount(false);
      setConfirmDeActivationLoading(false);
      triggerNotification({ message: res?.message, type: "info" });
    }).catch((err) => {
      setDeActivateAccount(false);
      setConfirmDeActivationLoading(false);
      triggerNotification({ message: err?.data?.message, type: "error" });
    });
    
  }

  const handleUpdateNameSubmit = (data: IEditName) => {
    setEditNameLoading(true);
    UpdateName(data).then((res) => {
      const response = res as UpdateNameResponse;
      setEditName(false);
      setEditNameLoading(false);
      triggerNotification({ message: response?.message, type: "info" });
    }).catch((err) => {
      setEditName(false);
      setEditNameLoading(false);
      triggerNotification({ message: err?.data?.message, type: "error" });
    })
  }

  return (
    <div className={styles.container}>
      <div
        className={classNames(styles.userInfo, {
          [styles.light]: theme === "light",
          [styles.dark]: theme === "dark",
        })}
      >
        {width <= 576 ? <span className={classNames(styles.account, {
          [styles.light]: theme === "light",
          [styles.dark]: theme === "dark",
        })}> <FormattedMessage id="sidebar.settings.account" /></span> : null}

        <UserName
          name={userDetail?.user?.name}
          email={userDetail?.user?.email}
          className={styles.userAvatar}
        />
        <div className={`${styles.userName} ${styles.emailContainer}`}>
          <span className="flex justify-center w-full"> {userDetail?.user?.name ?? ""} </span>
          {!editName && (
            <span className={`${styles.optionsIcon} cursor-pointer flex items-center mr-[20px]`} onClick={() => {
              setEditName(true);
            }}>
              <EditNameIcon themeMode={theme} /> </span>
          )}
        </div>
        {/* {width <= 576 ? */}
        <div className={classNames(styles.nameSeperator, {
          [styles.light]: theme === "light",
          [styles.dark]: theme === "dark",
        })}></div>
        {/* // : null} */}
        <div className={styles.emailContainer} >
          <span className={`flex justify-center w-full ${styles.email}`}>{userDetail?.user?.email ?? ""}</span>
          <span className={`${styles.optionsIcon} cursor-pointer mt-[5px] mr-[20px]`} onClick={() => setIsDropDownOpen((prev) => !prev)}  ref={optionsIconRef}>
            <OptionsIcon theme={theme === 'dark' ? true : false} />
          </span>
          {isDropdownOpen && (
            <div
            ref={dropdownRef} 
              className={`shadow-md absolute top-[1px] -right-[177px] z-[2] rounded-[10px] bg-white dark:bg-[#252526] ${(width <= 583 || width <= 1046 && isOpen && sidebarWidth) ?
                styles.smallScreenAdjustment
                : ''
                }`}
            >
              <div className={styles.DropDownbody}>
                <div key="edit" className={classNames(styles.menuItemEdit, {
                  [styles.dark]: theme === 'dark',
                  [styles.light]: theme === 'light',
                })} onClick={() => {
                  setEditAccount(true)
                  setIsDropDownOpen(false);
                }}>
                  <span style={{ marginTop: '4px', }}>
                    <EditEmail theme={theme} />
                  </span>
                  <FormattedMessage id="account.update-email" />
                </div>

                <div key="Deactivate" className={classNames(styles.menuItemEdit, styles.menuItemdeactivate, {
                  [styles.dark]: theme === 'dark',
                  [styles.light]: theme === 'light',
                })} onClick={() => {
                  setDeActivateAccount(true)
                  setIsDropDownOpen(false);
                }}>
                  <span style={{ marginTop: '4px', }}>
                    <AccountDeactivate theme={theme} />
                  </span>
                  <FormattedMessage id="account.deactivate" />
                </div>

                {/* <div className={styles.accountSVG} /> */}
                <div key="delete" className={styles.menuItemDel} onClick={() => {
                  setIsDropDownOpen(false);
                  setDelAccount(true)
                }}>
                  <span style={{ marginTop: '4px' }}>
                    <DeleteAccount />
                  </span>
                  <FormattedMessage id="account.delete-account" />
                </div>
              </div>
            </div>
          )}
        </div>

        {delAccount && (
          <DeleteChatConfirm onCancel={() => setDelAccount(false)} onSubmit={handleDelAccount} loading={loading} delAccount={true} />
        )}

        {deActivateAccount && (
          <DeActivateAccount onClose={() => setDeActivateAccount(false)} onSubmit={handleConfirmDeActivation} loading={confirmDeActivationLoading} />
        )}

        {editName && (
          <EditName onSubmit={handleUpdateNameSubmit} onClose={() => setEditName(false)} loading={editNameLoading} />
        )}

        {(editAccount || isConfirmationModalVisible) && (
          <EditAccount onClose={() => {
            if (editAccount)
              setEditAccount(false);
            else
              setConfirmationModalVisible(false);
          }}
            onSubmit={handleEmailEditSubmit} loading={editEmailLoading} isConfirmationModalVisible={isConfirmationModalVisible} />
        )}

        <Separator height="20px" />

        {width <= 576 && (
          <>
            <h4 className={classNames(styles.displaylang, {
              [styles.lightss]: theme === "light",
              [styles.darkss]: theme === "dark",
            })}>
              <FormattedMessage id="settings.account.languange" />
            </h4>
            <div
              className={classNames(styles.accountCols, {
                [styles.lights]: theme === "light",
                [styles.darks]: theme === "dark",
              })}
            >
              <div className={styles.language}>
                <h4 className={styles.h4}>
                  <FormattedMessage id="settings.account.languange" />
                </h4>
                <DropDown
                  $background={theme === "light" ? "#fff" : "rgba(255, 255, 255, 0.06)"}
                  variant={theme}
                  options={languages}
                  value={language}
                  onChange={(item: DropDownRow.IDataItem) =>
                    onUpdateLanguage(item.value)
                  } 
                  height={50}
                  className={styles.select}
                  account={true}
                />
              </div>
            </div>
          </>
        )}
        <Separator height="20px" />
        {width <= 576 ? <span className={classNames(styles.organization, {
          [styles.light]: theme === "light",
          [styles.dark]: theme === "dark",
        })}><FormattedMessage id="account.organization" /></span> : null}

        <div className={styles.teamDDContainer}>
          <DropDown
           updateTeam={true}
            $background={theme === "light" ? "#fff" : "rgba(255, 255, 255, 0.06)"}
            $withBorder={false}
            options={teamOptions}
            value={team}
            placeholder={
              <FormattedMessage id="account.select.team.placeholder" />
            }
            onChange={(item: DropDownRow.IDataItem) => {
              if(!teamSwitch)
              onSelectTeam(item.value)
            }}
            className={styles.select}
            variant={theme}
            account={true}
            height={width <= 576 ? 50 : 36}
          />
        </div>
      </div>
      <div className={classNames(styles.row, {
        [styles.light]: theme === "light",
        [styles.dark]: theme === "dark",
      })}>
        <h4 className={classNames(styles.usageSmall, {
          [styles.lightss]: theme === "light",
          [styles.darkss]: theme === "dark",
        })}>
          <FormattedMessage id="settings.account.dailyUsage" />
        </h4>
        {width <= 576 ? <div className={classNames(styles.usageSeperator, {
          [styles.light]: theme === "light",
          [styles.dark]: theme === "dark",
        })}></div> : null}

        <div
          className={classNames(styles.col, {
            [styles.light]: theme === "light",
            [styles.dark]: theme === "dark",
          })}
        >
          <div className={styles.usage}>
            <h4 className={styles.h4}>
              <FormattedMessage id="settings.account.dailyUsage" />
            </h4>
            <div className={styles.usageRow}>
              <span className={`opacity-80 ${styles.text}`}>
                <FormattedMessage id="settings.account.made" />
              </span>
              <span className={styles.number}>{creditLimits.used_today}</span>
            </div>
            {width > 576 && (<div className={styles.svg}>
            </div>
            )}
            <div className={styles.usageRow}>
              <span className={`opacity-80 ${styles.text}`}>
                {userDetail?.user.activeSubscription.name === "Free" ||
                  creditLimits.daily_limit ? (
                  <FormattedMessage id="settings.account.allowed.daily" />
                ) : (
                  <FormattedMessage id="settings.account.allowed.monthly" />
                )}
              </span>
              <span className={styles.number}>
                {creditLimits.daily_limit
                  ? creditLimits.daily_limit
                  : userDetail?.user.activeSubscription.total_credits ?? 0}
              </span>
            </div>
          </div>
        </div>
        {width > 576 && (
          <>
            <h4 className={classNames(styles.displaylang, {
              [styles.lightss]: theme === "light",
              [styles.darkss]: theme === "dark",
            })}>
              <FormattedMessage id="settings.account.languange" />
            </h4>
            <div
              className={classNames(styles.cols, {
                [styles.lights]: theme === "light",
                [styles.darks]: theme === "dark",
              })}
            >
              <div className={styles.language}>
                <h4 className={styles.h4}>
                  <FormattedMessage id="settings.account.languange" />
                </h4>
                <DropDown   
                  $background={theme === "light" ? "#fff" : "rgba(255, 255, 255, 0.06)"}
                  variant={theme}
                  options={languages}
                  value={language}
                  onChange={(item: DropDownRow.IDataItem) =>
                    onUpdateLanguage(item.value)
                  }
                  height={50}
                  className={styles.select}
                  account={true}
                />
              </div>
            </div>
          </>
        )}
      </div>
      <div
        className={classNames(styles.plan, {
          [styles.light]: theme === "light",
          [styles.dark]: theme === "dark",
        })}
      >
        {width > 576 && (
          <>
            <div className={styles.planbox}>
              <span className={styles.text}>
                <FormattedMessage
                  id={
                    userDetail?.user.activeSubscription.name === "Free" ||
                      creditLimits.daily_limit
                      ? "settings.account.limited.perDay"
                      : "settings.account.limited.perMonth"
                  }
                  values={{
                    credits: (
                      <span className={styles.count}>
                        {creditLimits.daily_limit
                          ? creditLimits.daily_limit
                          : userDetail?.user.activeSubscription.total_credits ?? 0}
                      </span>
                    ),
                  }}
                />
              </span>
            </div>
          </>
        )}
        <div className={styles.planTypeContainer}>
          <div className={styles.playType}>
            <span className={`font-bold text-[16px] ${styles.text}`}>
              <FormattedMessage id="settings.account.yourCurrentPlan" />
            </span>

            {width <= 576 ? <div className={classNames(styles.usageSeperator, {
              [styles.light]: theme === "light",
              [styles.dark]: theme === "dark",
            })}></div> : null}

            <span className={styles.planValue}>
              {userDetail?.user.activeSubscription.name}
            </span>
          </div>
          {userDetail?.user.team.role === "admin" && (
            <button
              data-testid='account-upgrade-plan'
              className={classNames(styles.upgradeButton, {
                [styles.light]: theme === "light",
                [styles.dark]: theme === "dark",
              })}
              onClick={() =>
                push(`/${RoutePaths.Settings}/${RoutePaths.CurrentPlan}`)
              }
            >
              <CurrentPlanIcon account={true} theme={theme} />
              <span>
                <FormattedMessage id="settings.account.upgrade" />
              </span>
            </button>
          )}
        </div>
      </div>
      {width <= 576 && (<div className={classNames(styles.line, {
        [styles.light]: theme === "light",
        [styles.dark]: theme === "dark",
      })}></div>)}
    </div>
  );
};

