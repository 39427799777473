import { TYPES } from "../../types";
import { api } from "../../api";
import { store } from "../../store";
import { setChatModel } from "../chatModels";
import { SetStateAction } from "react";
import { updateCreditLimit, updateCredits } from "../planSubscription";
import {
  ChatSetting,
  ChatType,
  EMessageViewType,
  IBot,
  IChat,
  IChatHistoryResponse,
  IGenerateRelatedQuestionsBody,
  IGetChat,
  IMessageData,
  IRemoveGeneratedQuestions,
  ISavePrompt,
  IStopGeneratingMessageBody,
  MessageType,
  RememberBot,
  TextToSpeech,
} from "../chatInterface";
import { addNewChatInFolder } from "../chatFolder";

export const getAllChats = ({
  search,
  page,
  perPage,
  folder_id,
}: {
  search?: string;
  page?: number;
  perPage?: number;
  folder_id?: number | null;
}) => {
  return new Promise((resolve, reject) => {
    const url =
      (folder_id && folder_id !== 0)
        ? `/api/chat?search=${search}&page=${page}&perPage=${perPage}&folder_id=${folder_id}`
        : page && perPage
          ? `/api/chat?search=${search}&page=${page}&perPage=${perPage}`
          : `/api/chat?search=${search}`;
    api
      .get(url)
      .then((res: any) => {
        resolve(res.data.chats);

        if (folder_id !== null && folder_id !== undefined) {
          const response = res.data.chats as IMessageData;

          const pagination = {
            page: response.current_page,
            lastPage: response.last_page,
          }
          store.dispatch({
            type: TYPES.GET_FOLDER_CHAT,
            payload: { chats: res?.data.chats.data, pagination: pagination, folder_id: folder_id },
          });
        }
        else {
          store.dispatch({
            type: TYPES.GET_ALL_CHATS_HISTORY,
            payload: res?.data?.chats?.data,
          });
          store.dispatch({ type: TYPES.GET_ALL_CHATS, payload: res.data.chats });
        }
      })
      .catch((err: any) => {
        reject(err);
        store.dispatch({ type: TYPES.GET_ALL_CHATS, payload: [] });
      });
  });
};

export const addNewChatHistory = (chat: IChat) => {
  store.dispatch({ type: TYPES.ADD_CHAT_HISTORY, payload: chat });
};

export const updateChatHistory = (id: number, topic: string, folder_id: number | string | undefined) => {
  return new Promise((resolve, reject) => {
    api
      .put(`/api/chat/${id}`, { topic: topic })
      .then((res) => {
        resolve(res.data.chat);
        if (typeof folder_id === 'number')
          store.dispatch({
            type: TYPES.UPDATE_CHAT_TOPIC,
            payload: { id, topic, folder_id },
          });

        store.dispatch({
          type: TYPES.UPDATE_CHAT_HISTORY,
          payload: { id, topic },
        });
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const deleteChatHistory = (id: number) => {
  return new Promise((resolve, reject) => {
    api
      .delete(`/api/chat/${id}`)
      .then((res) => {
        resolve(res.data);
        store.dispatch({
          type: TYPES.DELETE_CHAT_HISTORY,
          payload: { id: id },
        });
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const getChatHistory = (chatId: number, options?: { signal?: AbortSignal }, page?: number) => {
  return new Promise((resolve, reject) => {
    const url = !page
      ? `/api/chat/history/${chatId}`
      : `/api/chat/history/${chatId}?page=${page}`;
    api
      .get(url, { signal: options?.signal })
      .then((res: any) => {
        const response: IChatHistoryResponse = res.data;
        const { chat, messages } = response;

        let messagesList = messages;
        if (
          chat.files ||
          (messages.data[0]?.files &&
            messages.data[0]?.files.length > 0 &&
            messages.data[0]?.type === "assistant")
        ) {
          const newMessage = {
            id: messagesList.data[0].id,
            type: MessageType.user,
            images: [],
            files: messages.data[0]?.files,
          };
          if (messagesList.data[0]?.type === "assistant") {
            messagesList.data.unshift(newMessage);
          }
        }

        messagesList.data = messagesList.data.map((message: any) => {
          return {
            ...message,
            chatType: chat.chat_type, // Add the chatType from the chat object
          };
        });

        const updatedResponse = { chat, messages: messagesList.data };

        resolve(updatedResponse);
        store.dispatch({
          type: TYPES.GET_CHAT_HISTORY,
          payload: messagesList.data,
        });

        if (!page) {
          store.dispatch({
            type: TYPES.MESSAGES_PAGINATION,
            payload: {
              current_page: messagesList.current_page,
              last_page: messagesList.last_page,
            },
          });
        } else {
          store.dispatch({
            type: TYPES.UPDATE_MESSAGES_PAGINATION,
            payload: {
              current_page: messagesList.current_page,
            },
          });
        }

        setTimeout(() => {
          if (chat?.model) {
            let Model = chat.model;
            if (Model && typeof Model.type === "string") {
              Model.type = JSON.parse(Model.type);
            }
            if (Model && typeof Model.attributes === "string") {
              Model.attributes = JSON.parse(Model.attributes);
            }
            setChatModel(Model);
          }
        }, 300);

        resolve(updatedResponse);
      })
      .catch((err: any) => {
        reject(err);
      });
  });
};

export const stopGeneratingResponse = (data: IStopGeneratingMessageBody) => {
  return new Promise((resolve, reject) => {
    api
      .post("/api/chat/message/stop", data)
      .then((res: any) => {
        resolve(res.data);
      })
      .catch((err: any) => {
        reject(err);
      });
  });
};

export const getNewChatTopic = (chatId: number, folder_id?: number | null | undefined) => {
  return new Promise((resolve, reject) => {
    api
      .get(`/api/chat/${chatId}`)
      .then((res: any) => {
        if (folder_id) addNewChatInFolder(folder_id, res.data);
        else if (folder_id === null) addNewChatInFolder(0, res.data);
        else addNewChatHistory(res.data);
        resolve(res.data);
      })
      .catch((err: any) => {
        reject(err);
      });
  });
};

export const generatePresignedURL = (data: {
  name: string;
  type?: null | typeof ChatType.document | typeof ChatType.image_chat;
  signal?: AbortSignal;
}) => {
  return new Promise((resolve, reject) => {
    api
      .post(
        "/api/generate-s3-url",
        { name: data.name, type: data?.type },
        { signal: data.signal }
      )
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const saveFilePath = ({
  path,
  name,
  token,
  message,
  setISUploadURL,
  uploadUrl,
  resetMessage,
  signal,
  model,
  setIsFileUploading,
}: {
  path: string;
  name: string;
  token: string | undefined;
  message?: string;
  setISUploadURL?: React.Dispatch<SetStateAction<boolean>>;
  uploadUrl?: boolean;
  resetMessage?: () => void;
  signal?: AbortSignal;
  model: string;
  setIsFileUploading?: React.Dispatch<SetStateAction<boolean>>;
}) => {
  return new Promise((resolve, reject) => {
    fetch(`${process.env.REACT_APP_API_BASEURL}/api/chat/file`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      signal: signal,
      body: JSON.stringify({ path, name, message, model }),
    })
      .then(async (response: any) => {
        if (!response.ok) {
          const errorResponse = await response.json();
          const errorMessage = errorResponse.message || `Something went wrong`;
          return reject(new Error(errorMessage));
        }
        if (!uploadUrl) {
          setIsFileUploading!(false);
        }
        if (uploadUrl) {
          setISUploadURL!(false);
          resetMessage!();
        }

        const reader = response?.body?.getReader();
        const decoder = new TextDecoder();
        let i = 0;
        let content = "";
        let texts = "";
        let isJsonParsed = false;
        let jsonData = null;
        while (true) {
          const streamResult = await reader?.read();
          const decoderText = decoder.decode(streamResult?.value);
          content += decoder.decode(streamResult?.value || new Uint8Array(), {
            stream: !streamResult?.done,
          });
          let completeMessage = decoderText;
          while (!isJsonParsed) {
            let leftBracket = content.indexOf("{");
            let rightBracket = content.lastIndexOf("}");
            if (leftBracket === -1 || rightBracket === -1) {
              break;
            }
            if (leftBracket !== -1 && rightBracket !== -1) {
              const jsonDataString = content.substring(
                leftBracket,
                rightBracket + 1
              );
              const jsonData = JSON.parse(jsonDataString);
              if (jsonData.chat) {
                texts = content.substring(rightBracket + 1);
                isJsonParsed = true;
              }
            }
            completeMessage = content.substring(leftBracket, rightBracket + 1);
            content = content.substring(rightBracket + 1);
          }
          if (streamResult?.done) {
            resolve({ jsonData, content });
            break;
          }
          if (i === 0) {
            try {
              jsonData = JSON.parse(
                completeMessage && texts !== "" ? completeMessage : decoderText
              );
              completeMessage = "";

              if (jsonData) {
                updateCredits(jsonData.credits);
                updateCreditLimit({
                  daily_limit: jsonData.daily_limit,
                  used_today: jsonData.used_today,
                });
                store.dispatch({
                  type: TYPES.SAVE_FILE_PATH,
                  payload: {
                    chatItem: {
                      id: jsonData?.chat?.id,
                      chat_type: jsonData?.chat?.chat_type,
                      topic: jsonData?.chat?.topic,
                      is_topic_updated: jsonData?.chat?.is_topic_updated,
                      document_index_name: jsonData?.chat?.document_index_name,
                      user_id: jsonData?.chat?.user_id,
                      created_at: jsonData?.chat?.created_at,
                      updated_at: jsonData?.chat?.updated_at,
                      file: jsonData.file,

                      last_assistant_message: {
                        id: jsonData?.chat?.id,
                        content: content,
                        type: "assistant",
                        created_at: jsonData?.chat?.created_at,
                        updated_at: jsonData?.chat?.updated_at,
                        chat_id: jsonData?.chat?.id,
                      },
                    },
                    message: [
                      ...(message
                        ? [
                          {
                            id: jsonData?.message?.id,
                            content: message,
                            type: "user",
                            images: [],
                            file: jsonData.file,
                          },
                        ]
                        : [
                          {
                            id: jsonData?.assistant_message?.id,
                            type: "user",
                            images: [],
                            file: jsonData.file,
                          },
                        ]),
                      {
                        id: jsonData?.assistant_message?.id,
                        content: content,
                        type: "assistant",
                        created_at: jsonData?.chat?.created_at,
                        updated_at: jsonData?.chat?.updated_at,
                        chat_id: jsonData?.chat?.id,
                        file: jsonData.file,
                      },
                    ],
                  },
                });
              }
            } catch (error: any) {
              console.error("Error parsing JSON:", error);
            }
          } else {
            if (jsonData) {
              store.dispatch({
                type: TYPES.SAVE_FILE_PATH,
                payload: {
                  chatItem: {
                    id: jsonData?.chat?.id,
                    chat_type: jsonData?.chat?.chat_type,
                    topic: jsonData?.chat?.topic,
                    is_topic_updated: jsonData?.chat?.is_topic_updated,
                    document_index_name: jsonData?.chat?.document_index_name,
                    user_id: jsonData?.chat?.user_id,
                    created_at: jsonData?.chat?.created_at,
                    updated_at: jsonData?.chat?.updated_at,
                    file: jsonData.file,

                    last_assistant_message: {
                      id: jsonData?.chat?.id,
                      content: content,
                      type: "assistant",
                      created_at: jsonData?.chat?.created_at,
                      updated_at: jsonData?.chat?.updated_at,
                      chat_id: jsonData?.chat?.id,
                    },
                  },
                  message: [
                    ...(message
                      ? [
                        {
                          id: jsonData?.message?.id,
                          content: message,
                          type: "user",
                          images: [],
                          file: jsonData.file,
                        },
                      ]
                      : [
                        {
                          id: jsonData?.assistant_message?.id,
                          type: "user",
                          images: [],
                          file: jsonData.file,
                        },
                      ]),
                    {
                      id: jsonData?.assistant_message?.id,
                      content: content,
                      type: "assistant",
                      created_at: jsonData?.chat?.created_at,
                      updated_at: jsonData?.chat?.updated_at,
                      chat_id: jsonData?.chat?.id,
                      file: jsonData.file,
                    },
                  ],
                },
              });
            }
          }
          i++;
        }
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const removeRelatedQuestions = ({
  messageId,
  messageViewType,
}: IRemoveGeneratedQuestions) => {
  if (messageViewType === "new") {
    store.dispatch({
      type: TYPES.REMOVE_RELATED_QUESTIONS_FROM_NEW_MESSAGES,
      payload: messageId,
    });
  }
  if (messageViewType === "history") {
    store.dispatch({
      type: TYPES.REMOVE_RELATED_QUESTIONS_FROM_MESSAGES,
      payload: messageId,
    });
  }
};

export const generateRelatedQuestions = ({
  chatId,
  messageViewType,
  signal,
}: IGenerateRelatedQuestionsBody & { signal?: AbortSignal }) => {
  return new Promise((resolve, reject) => {
    api
      .post("/api/chat/related-questions", { chat_id: chatId }, { signal })
      .then((res) => {
        resolve(res.data);
        if (messageViewType === "new") {
          store.dispatch({
            type: TYPES.GET_GENERATED_QUESTIONS_NEW,
            payload: res.data,
          });
        }
        if (messageViewType === "history") {
          store.dispatch({
            type: TYPES.GET_GENERATED_QUESTIONS_HISTORY,
            payload: res.data,
          });
        }
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const removeLastMessage = (messageViewType: EMessageViewType) => {
  if (messageViewType === "new") {
    store.dispatch({
      type: TYPES.REMOVE_LAST_MESSAGE_FROM_NEW_MESSAGES,
    });
  }
  if (messageViewType === "history") {
    store.dispatch({
      type: TYPES.REMOVE_LAST_MESSAGE_FROM_MESSAGES,
    });
  }
};

export const getAllPropts = () => {
  return new Promise((resolve, reject) => {
    api
      .get(`/api/prompts`)
      .then((res) => {
        store.dispatch({
          type: TYPES.GET_ALL_PROMPT,
          payload: res.data,
        });
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const SavePrompts = (data: ISavePrompt) => {
  return new Promise((resolve, reject) => {
    api
      .post("/api/prompts/user", data)
      .then((res) => {
        getSavePrompts();
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const getSavePrompts = () => {
  return new Promise((resolve, reject) => {
    api
      .get(`/api/prompts/user`)
      .then((res) => {
        store.dispatch({
          type: TYPES.GET_USER_PROMPT,
          payload: res.data,
        });
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const deleteUserPRompts = (id: number) => {
  return new Promise((resolve, reject) => {
    api
      .delete(`/api/prompts/user/${id}`)
      .then((res) => {
        resolve(res.data);
        store.dispatch({
          type: TYPES.DELETE_USER_PROMPT,
          payload: { id: id },
        });
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const updateUserPrompt = (id: number, data: ISavePrompt) => {
  return new Promise((resolve, reject) => {
    api
      .put(`/api/prompts/user/${id}`, data)
      .then((res) => {
        resolve(res.data);
        store.dispatch({
          type: TYPES.UPDATE_USER_PROMPT,
          payload: { id, data },
        });
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const ShareChat = ({
  chat_id,
  messages,
  folder_id,
}: {
  chat_id?: number;
  messages?: (string | number)[];
  folder_id: number | null;
}) => {
  return new Promise((resolve, reject) => {
    api
      .post("/api/chat/share", { chat_id, messages, folder_id })
      .then((res: any) => {
        const token = res.data?.token;
        const baseURL = window.location.origin;
        resolve(res.data);
        const path = `${baseURL}/share-chat/${token}`;
        resolve(path); // Simply resolve with the path
      })
      .catch((err: any) => {
        reject(err);
      });
  });
};

export const getShareChat = (data: IGetChat) => {
  return new Promise((resolve, reject) => {
    api
      .get(`/api/shared-chat/${data?.token} `)
      .then((res: any) => {
        resolve(res.data);
        const chatData = res.data; // Extract chat data from the API response
        const messages = chatData.messages; // Extract messages array

        // Check the condition for the first message in the messages array
        if (
          messages[0]?.files &&
          messages[0]?.files.length > 0 &&
          messages[0]?.type === "assistant"
        ) {
          // Create a new message object
          const newMessage = {
            id: messages[0].id,
            type: "user",
            images: [],
            files: messages[0]?.files,
          };
          if (messages[0]?.type === "assistant") {
            messages.unshift(newMessage);
          }
        }

        // Updated chat data
        const updatedChatData = {
          ...chatData,
          messages, // Updated messages array
        };
        store.dispatch({
          type: TYPES.GET_SHARE_CHAT,
          payload: updatedChatData,
        });
      })
      .catch((err: any) => {
        reject(err);
      });
  });
};

export const CopyShareChat = (data: IGetChat) => {
  return new Promise((resolve, reject) => {
    api
      .post("/api/shared-chat/copy", data)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const getChatSettings = () => {
  return new Promise((resolve, reject) => {
    api
      .get("/api/user-setting")
      .then((res) => {
        resolve(res?.data);
        store.dispatch({
          type: TYPES.GET_CHAT_SETTING,
          payload: res?.data?.chat_settings,
        });
        store.dispatch({
          type: TYPES.GET_ROLL_BOT_SETTING,
          payload: res?.data?.bot_settings,
        });
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const SetChatSetting = (data: ChatSetting | RememberBot) => {
  return new Promise((resolve, reject) => {
    api
      .post("/api/user-setting", data)
      .then((res) => {
        getChatSettings().catch();
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const DeleteS3Link = (s3Link: string) => {
  return new Promise((resolve, reject) => {
    api
      .delete("api/s3-file", {
        data: { file_path: s3Link },
      })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
export const removeMultipleChat = (chat_ids: number[], folder_id?: number | string) => {
  return new Promise((resolve, reject) => {
    api
      .delete(`/api/chat/delete-multiple`, {
        data: { chat_ids },
      })
      .then((res) => {
        resolve(res.data);
        if (typeof folder_id === "number")
          store.dispatch({
            type: TYPES.DELETE_MULTIPLE_CHAT_HISTORY_FOLDER,
            payload: { chat_ids, folder_id },
          });
        else store.dispatch({
          type: TYPES.DELETE_MULTIPLE_CHAT_HISTORY,
          payload: { chat_ids },
        });

      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const deleteAllConversation = (delete_folders?: boolean) => {
  return new Promise((resolve, reject) => {

    const URL = delete_folders ? `/api/chat/clear-all?delete_folders=${delete_folders}`
      : `/api/chat/clear-all`;
    api
      .delete(URL)
      .then((res) => {
        resolve(res.data);
        if(delete_folders) {
        store.dispatch({
          type: TYPES.EMPTY_FOLDER
        });
        getAllChats({ search: "" });
      }
      else {
        store.dispatch({
          type: TYPES.EMPTY_DEFAULT_FOLDER_CHATS
        });
      }
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const RoleBot = (data: IBot) => {
  return new Promise((resolve, reject) => {
    api
      .post("/api/user-setting", data)
      .then((res: any) => {
        resolve(res.data);
        getChatSettings();
      })
      .catch((err: any) => {
        reject(err);
      });
  });
};

export const filterLanguages = (model: string) => {
  return new Promise((resolve, reject) => {
    api
      .get(`api/user-setting?model=${model}`)
      .then((res) => {
        resolve(res?.data?.bot_settings_allowed);
        store.dispatch({
          type: TYPES.UPDATE_DROP_DOWN_OPTIONS,
          payload: res?.data?.bot_settings_allowed,
        });
      })
      .catch((err: any) => {
        reject(err);
      });
  });
};

export const AudioPlayer = (
  data: TextToSpeech,
  token: string | undefined,
  abortSignal: AbortSignal,
): Promise<ReadableStreamDefaultReader<Uint8Array> | undefined> => {
  return new Promise((resolve, reject) => {
    // Create the URL with team_id as a query parameter
    const url = new URL(`${process.env.REACT_APP_API_BASEURL}/api/chat/text-to-speech`);
    const userDetail = store.getState().authReducer.userDetail; // Assuming userDetail is available in your Redux store
    if (userDetail?.user?.team?.id) {
      // Append team_id to the URL search params
      url.searchParams.append("team_id", userDetail?.user?.team?.id.toString());
    }

    fetch(url
      , {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(data),
        signal: abortSignal,
      })
      .then((response) => {
        if (!response.ok) {
          return reject(`Error: ${response.status} ${response.statusText}`);
        }
        const reader = response.body?.getReader();
        resolve(reader)
      })
      .catch((err) => {
        if (err.name === 'AbortError') {
          console.error("Fetch aborted");
          return;
        }
        reject(err);
      });
  });
};



