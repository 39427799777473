import React from "react";
import { RemoveIcon, TickIcon } from "../icons";
import {
  ChatFolderAdd,
  ChatFolderDel,
  ChatFolderEdit,
} from "../icons/ChatFolder";
import folderStyle from "../ChatItem/ChatItem.module.scss";
import { Spinner } from "components";
import { useSelector } from "redux/hooks";
import { getFolder } from "redux/actions";

interface ChatFolderActionsProps {
  folderId: number;
  isEdit: boolean;
  toggleEdit: () => void;
  handleEditFolderName: () => void;
  isloading: boolean;
  handleNewChat: () => void;
  handleDelFolder: () => void;
  selectedFolderItem: getFolder | undefined;
}

export const ChatFolderActions: React.FC<ChatFolderActionsProps> = ({
  folderId,
  isEdit,
  toggleEdit,
  handleEditFolderName,
  isloading,
  handleNewChat,
  handleDelFolder,
  selectedFolderItem,
}) => {
  const themeMode = useSelector((state) => state.authReducer.theme);
  if (folderId === 0) return;

  return (
    <div className="relative flex left-[16px] gap-[5px] cursor-pointer">
      {isEdit && selectedFolderItem?.id === folderId ? (
        <div className={folderStyle.actionsContainer}>
          {isloading ? (
            <Spinner extraSmall isWhite={themeMode === "light"} />
          ) : (
            <div
              className={folderStyle.actionIcon}
              onClick={handleEditFolderName}
            >
              <TickIcon />
            </div>
          )}
          <div className={folderStyle.actionIcon} onClick={toggleEdit}>
            <RemoveIcon />
          </div>
        </div>
      ) : (
        <>
          <span onClick={handleNewChat}>
            <ChatFolderAdd />
          </span>
          <span onClick={toggleEdit}>
            <ChatFolderEdit />
          </span>
          <span onClick={handleDelFolder}>
            <ChatFolderDel />
          </span>
        </>
      )}
    </div>
  );
};
