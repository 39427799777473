import { Fragment, memo, Dispatch, SetStateAction, useEffect, useState, useRef } from "react";
import styles from "./Sidebar.module.scss";
import settingStyles from "./component/SettingSidebar/SettingSidebar.module.scss";
import classNames from "classnames";
import { BurgerIconlg } from "../Navbar/components/icons";

import useRouter from "hooks/useRouter";
import { ChatSidebar, SettingSidebar } from "./component";
import { Credits } from "../Navbar/components/ChatNav/components/Credits";
import { RoutePaths } from "pages/routePaths";

import { useSelector } from "redux/hooks";
import { useToggleSidebar } from "hooks/services/ToggleSidebarProvider";
import { IChat } from "redux/actions";
import { ChatMiniSidebar } from "./component/ChatSidebar/components/ChatMiniSideBar";
import { SettingMiniSidebar } from "./component/SettingSidebar/components/SettingMiniSidebar";
import { useWindowSize } from "hooks/useWindowSize";
import { PlusIcon } from "./component/ChatSidebar/components/icons";
import { FormattedMessage } from "react-intl";
import { SidebarDownArrow } from "./component/ChatSidebar/components/icons/DownArrow";
import { SidebarDropdown } from "./component/ChatSidebar/components/SidebarDropdown/SidebarDropdown";

interface IProps {
  onSelectChatItem?: (chatItem: IChat | undefined, folder_id: number) => void;
  onStartNewChat?: ({ toChat, PrivateChat,newChatbtn }: { toChat: boolean, PrivateChat?: boolean, newChatbtn?: boolean }) => void;
  isMainScreenOpen?: boolean;
  searchQuery?: string; setSearchQuery?: Dispatch<SetStateAction<string>>;
  setOpenHistory?: Dispatch<SetStateAction<boolean>>;
  sendMessageLoading?: boolean;
  isAnswerComplete?: boolean;
  sidebarWidth: number;
  sidebarRef?: React.RefObject<HTMLDivElement>

}

export const Sidebar = memo(
  ({
    sidebarWidth,
    onSelectChatItem, onStartNewChat, isMainScreenOpen,
    searchQuery, setSearchQuery, setOpenHistory, sendMessageLoading,
    isAnswerComplete,
    sidebarRef
  }: IProps) => {

    const { includeRoute } = useRouter();
    const { width } = useWindowSize();
    const { isOpen, toggleSidebar } = useToggleSidebar();
    const { theme } = useSelector((state) => state.authReducer);
    const [showOption, setShowOptions] = useState<boolean>(false);
    // Ref for SidebarDropdown
    const dropdownRef = useRef<HTMLDivElement>(null);
    const optionsIconRef = useRef<HTMLDivElement | null>(null); // Reference to the options
    const { teamSwitch } = useSelector((state) => state.teamReducer);

    const itemStyles = (route: string) => {
      return classNames(settingStyles.menuItem, {
        [settingStyles.active]: includeRoute(route),
        [settingStyles.light]: theme === "light",
        [settingStyles.dark]: theme === "dark",
      });
    };
    const isSettings = includeRoute(RoutePaths.Settings);

    useEffect(() => {
      let lastScrollTop = 0;

      const preventPullToRefresh = (e: TouchEvent) => {
        const currentScrollTop = e.touches[0].clientY;

        if (currentScrollTop > lastScrollTop && width <= 576) {
          e.preventDefault();
        }
        lastScrollTop = currentScrollTop;
      };

      if (width <= 576 && isOpen) {
        document.addEventListener('touchmove', preventPullToRefresh, { passive: false });
      }
      else {
        document.removeEventListener('touchmove', preventPullToRefresh);
      }

      return () => {
        document.removeEventListener('touchmove', preventPullToRefresh);
      };
    }, [isOpen, width]);

    const handleShowOption = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      e.stopPropagation();
      setShowOptions((prev) => !prev);
    }

    // Close dropdown on outside click
    useEffect(() => {
      const handleClickOutside = (event: MouseEvent) => {
        if (
          dropdownRef.current && !dropdownRef.current.contains(event.target as Node) &&
          optionsIconRef.current && !optionsIconRef.current.contains(event.target as Node)
        ) {
          setShowOptions(false);
        }
      };

      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, []);

    return (
      <Fragment>
        {isOpen && <div className={styles.backdrop} onClick={toggleSidebar} data-testid='backdrop-toggle-sidebar' />}
        <div ref={sidebarRef} className={classNames(styles.mainContainer, {
          [styles.isOpen]: isOpen,
          [styles.light]: theme === 'light',
          [styles.dark]: theme === 'dark',
          [styles.smallmainContainer]: !isOpen,
          [styles.backDropSwitch]: teamSwitch,
        })}
          style={{ width: isOpen && width > 768 ? `${sidebarWidth}px` : '' }}
        >

          {/* Left Pane */}
          <aside
            className={classNames(styles.container, {
              [styles.isOpen]: isOpen,
              [styles.lights]: theme === "light",
              [styles.darks]: theme === "dark",
              [styles.smallmainContainer]: !isOpen,
              [styles.settingsSidebarContainer]: includeRoute(RoutePaths.Settings)
            })}
            style={{ width: isOpen && width > 768 ? `${sidebarWidth - 30}px` : '' }}
          >
            <div className={classNames(styles.header, {
              [styles.settingsHeader]: includeRoute(RoutePaths.Settings)
            })} >
              <div className={classNames(styles.logoHeader, {
                [styles.isOpen]: !isOpen,
                [styles.chatspace]: includeRoute(RoutePaths.Chat) && isOpen,
                [styles.settingsSidebar]: includeRoute(RoutePaths.Settings)
              })}>
                <button
                  className={classNames(styles.button, {
                    [styles.light]: theme === "light",
                    [styles.dark]: theme === "dark",
                    [styles.setting]: isSettings && !isOpen,
                    [styles.isChatSide]: includeRoute(RoutePaths.Chat) && isOpen
                  })}
                  onClick={toggleSidebar}
                  data-testid='toggle-sidebar-burger-btn'
                >
                  {isSettings && !isOpen ?
                    <img
                      src={require('./logo.png')}
                      style={{ backgroundColor: "transparent", height: '44px', width: '44px' }}
                    />
                    :
                     <BurgerIconlg />
                  }
                </button>

                {width <= 576 && includeRoute(RoutePaths.Settings) ? (
                  <button className={classNames(styles.newChatBtn, {
                    [styles.light]: theme === "light",
                    [styles.dark]: theme === "dark",
                  })}
                    onClick={() => onStartNewChat?.({ toChat: true })}
                    data-testid='new-chat-btn'
                  >
                    <div
                      className={classNames(styles.btnContent, {
                        [styles.light]: theme === "light",
                        [styles.dark]: theme === "dark",
                      })}
                    >
                      <PlusIcon />
                      <div className={styles.text}>
                        <FormattedMessage id="sidebar.chat.newChat.btn.text" />
                      </div>
                    </div>
                  </button>
                ) : (
                  <button
                    className={styles.logoButton}
                    onClick={() => onStartNewChat?.({ toChat: true })}
                    data-testid='deftgpt-sidebar-logo-btn'
                  >
                    <div ref={optionsIconRef} className={`flex items-center gap-2 ${classNames(styles.logoHeaderlg, {
                      [styles.dopDownOpen]: showOption && isOpen,
                      [styles.dropdownDark]: showOption && isOpen && theme === "dark",
                      [styles.logoHeaderlgChat]: includeRoute(RoutePaths.Chat),
                    })}`}
                      onClick={(e) => {
                        if (includeRoute(RoutePaths.Chat)) handleShowOption(e)
                      }}>
                      <div
                        className={classNames(styles.logoText, {
                          [styles.light]: theme === "light",
                          [styles.dark]: theme === "dark",
                          [styles.setting]: isSettings && !isOpen,
                          [styles.chatLogoText]: includeRoute(RoutePaths.Chat) && isOpen,
                        })}
                      >
                        {isOpen ? 'DeftGPT' : ''}
                      </div>
                      <span className={classNames({
                        [styles.logoHide]: isSettings || !isOpen,
                      })}> <SidebarDownArrow /></span>
                    </div>

                    <div
                      className={classNames(styles.logoTextsmall, {
                        [styles.light]: theme === "light",
                        [styles.dark]: theme === "dark",
                      })}
                    >
                      <div className={styles.mainsidehead}>
                        <div className={` ${includeRoute(RoutePaths.Chat) ? styles.sidebarSpace : styles.sidebarhead}`}>
                          <div >
                            DeftGPT
                          </div>
                          <div> <Credits /> </div>
                        </div>
                      </div>
                    </div>
                  </button>
                )}
              </div>
            </div>
            {(showOption && isOpen) && (
              <div ref={dropdownRef}>
                <SidebarDropdown
                  onStartNewChat={onStartNewChat}
                  sendMessageLoading={sendMessageLoading}
                  isAnswerComplete={isAnswerComplete}
                />
              </div>
            )}
            <div className={styles.content}>
              <div className={styles.lgContent}>
                {includeRoute(RoutePaths.Chat) && (isOpen || width <= 768)
                  ? (
                    <ChatSidebar
                      onSelectChatItem={onSelectChatItem}
                      onStartNewChat={onStartNewChat}
                      isMainScreenOpen={isMainScreenOpen}
                      toggleSidebar={toggleSidebar}
                      itemStyles={itemStyles}
                      searchQuery={searchQuery} setSearchQuery={setSearchQuery}
                      setOpenHistory={setOpenHistory}
                    />
                  )
                  : includeRoute(RoutePaths.Chat) && !isOpen && (
                    <ChatMiniSidebar
                      onStartNewChat={onStartNewChat}
                      isMainScreenOpen={isMainScreenOpen}
                      itemStyles={itemStyles}
                    />
                  )
                }
                {includeRoute(RoutePaths.Settings) && isOpen ? <SettingSidebar
                /> : includeRoute(RoutePaths.Settings) && !isOpen && <SettingMiniSidebar
                />}
              </div>
            </div>
          </aside>
        </div >
      </Fragment >
    );
  }
);
