import { EThemeType } from "redux/reducers";
interface IProps {
  className?: string;
  theme?: EThemeType;
}

export const LogoutIcon = ({
  theme,
  }: IProps) => {
  return (
 
    <svg xmlns="http://www.w3.org/2000/svg" width="21" height="22" viewBox="0 0 21 22" fill="none">
  <path d="M13.1393 6.96555V6.14918C13.1393 4.36855 11.6955 2.9248 9.91491 2.9248H5.64928C3.86953 2.9248 2.42578 4.36855 2.42578 6.14918V15.8879C2.42578 17.6686 3.86953 19.1123 5.64928 19.1123H9.92366C11.699 19.1123 13.1393 17.6729 13.1393 15.8976V15.0724" stroke={theme==="light"? "#2A2831": "#fff"}stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M19.0832 11.0186H8.54736" stroke={theme==="light"? "#2A2831": "#fff"} stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M16.5215 8.4668L19.0835 11.0174L16.5215 13.5689" stroke={theme==="light"? "#2A2831": "#fff"} stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
</svg>
  );
};
