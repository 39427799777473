import React, {
  createContext,
  SetStateAction,
  useContext,
  useState,
  Dispatch,
  useRef,
} from "react";

interface AudioContextProps {
  currentAudio: HTMLAudioElement | null;
  currentVideo: HTMLVideoElement | null;
  playAudio: (audio: HTMLAudioElement, messageId: number) => void;
  playVideo: (video: HTMLVideoElement
    ,videoMessageId: number
  ) => void;
  stopAudio: () => void;
  stopVideo: () => void;
  isPlaying: boolean;
  setIsPlaying: Dispatch<SetStateAction<boolean>>;
  isPlayingVideo: boolean;
  setIsPlayingVideo: Dispatch<SetStateAction<boolean>>;
  currentMessageId: number | null;
  setCurrentMessageId: Dispatch<SetStateAction<number | null>>;
  currentVideoMessageId: number | null;
  setCurrentVideoMessageId: Dispatch<SetStateAction<number | null>>;
  setIsAudioLoading: Dispatch<SetStateAction<boolean>>;
  isAudioLoading: boolean;
}

const AudioContext = createContext<AudioContextProps | undefined>(undefined);

export const AudioProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const currentAudioRef = useRef<HTMLAudioElement | null>(null);
  const currentVideoRef = useRef<HTMLVideoElement | null>(null);

  const [isPlaying, setIsPlaying] = useState<boolean>(false);
  const [isPlayingVideo, setIsPlayingVideo] = useState<boolean>(false);
  const [currentMessageId, setCurrentMessageId] = useState<number | null>(null);
  const [currentVideoMessageId, setCurrentVideoMessageId] = useState<number | null>(null);
  const [isAudioLoading, setIsAudioLoading] = useState<boolean>(false);

  const handleAudioEnded = () => {
    setIsPlaying(false);
    currentAudioRef.current = null;
    setCurrentMessageId(null);
  };

  const handleVideoEnded = () => {
    setIsPlayingVideo(false);
    currentVideoRef.current = null;
    setCurrentVideoMessageId(null);
  };

  const playAudio = (audio: HTMLAudioElement, messageId: number) => {
    if (isPlayingVideo && currentVideoRef.current) {
      currentVideoRef.current.pause(); // Pause video if playing
      setIsPlayingVideo(false);
      setCurrentVideoMessageId(null);
    }

    if (currentAudioRef.current && currentAudioRef.current !== audio) {
      currentAudioRef.current.pause();
    }

    audio.removeEventListener("ended", handleAudioEnded);
    audio.addEventListener("ended", handleAudioEnded);

    currentAudioRef.current = audio;

    audio
      .play()
      .then(() => {
        setIsPlaying(true);
        setCurrentVideoMessageId(null);
        setCurrentMessageId(messageId);
      })
      .catch((err) => {
        console.error(err);
        setIsPlaying(false);
      });
  };

  const playVideo = (video: HTMLVideoElement
    , videoMessageId: number
  ) => {
    if (isPlaying && currentAudioRef.current) {
      currentAudioRef.current.pause(); // Pause audio if playing
      setIsPlaying(false);
    }

    if (currentVideoRef.current && currentVideoRef.current !== video) {
      currentVideoRef.current.pause();
    }

    video.removeEventListener("ended", handleVideoEnded);
    video.addEventListener("ended", handleVideoEnded);

    currentVideoRef.current = video;

    video
      .play()
      .then(() => {
        setIsPlayingVideo(true);
        setCurrentVideoMessageId(videoMessageId);
      })
      .catch((err) => {
        console.error(err);
        setIsPlayingVideo(false);
      });
  };

  const stopAudio = () => {
    if (currentAudioRef.current) {
      currentAudioRef.current.pause();
      currentAudioRef.current.removeEventListener("ended", handleAudioEnded);
      currentAudioRef.current = null;
      setIsPlaying(false);
    }
  };

  const stopVideo = () => {
    if (currentVideoRef.current) {
      currentVideoRef.current.pause();
      currentVideoRef.current.removeEventListener("ended", handleVideoEnded);
      currentVideoRef.current = null;
      setIsPlayingVideo(false);
    }
  };

  return (
    <AudioContext.Provider
      value={{
        currentAudio: currentAudioRef.current,
        currentVideo: currentVideoRef.current,
        playAudio,
        playVideo,
        stopAudio,
        stopVideo,
        isPlaying,
        isPlayingVideo,
        setIsPlaying,
        setIsPlayingVideo,
        currentMessageId,
        setCurrentMessageId,
        currentVideoMessageId,
        setCurrentVideoMessageId,
        setIsAudioLoading,
        isAudioLoading
      }}
    >
      {children}
    </AudioContext.Provider>
  );
};

export const useAudio = () => {
  const context = useContext(AudioContext);
  if (!context) {
    throw new Error("useAudio must be used within an AudioProvider");
  }
  return context;
};
