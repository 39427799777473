import classNames from "classnames";
import { EThemeType } from "redux/reducers";
import { useWindowSize } from "hooks/useWindowSize";

export interface IImage {
  src: string;
  alt: string;
  className?: string;
}

const imageClassNames: string[] = [
  "rounded-[10px] md:rounded-[16px] lg:rounded-[20px] translate-y-[40px] md:translate-y-[75px] lg:translate-y-[90px] xl:translate-y-[150px]",
  "rounded-[10px] md:rounded-[16px] lg:rounded-[20px] translate-y-[11px] md:translate-y-[20px] lg:translate-y-[25px] xl:translate-y-[45px]",
  "rounded-[10px] md:rounded-[16px] lg:rounded-[20px] translate-y-[40px] md:translate-y-[75px] lg:translate-y-[90px] xl:translate-y-[150px]",
  "rounded-tl-[10px] md:rounded-tl-[20px] rounded-tr-[10px] md:rounded-tr-[20px]",
  "rounded-tl-[10px] md:rounded-tl-[20px] rounded-tr-[10px] md:rounded-tr-[20px]",
  "rounded-tl-[10px] md:rounded-tl-[20px] rounded-tr-[10px] md:rounded-tr-[20px]",
];

interface IHeroProps {
  images: IImage[];
  title: string;
  content: string;
  themeMode?: EThemeType;
}

export const Hero: React.FC<IHeroProps> = ({ title, content, themeMode, images }) => {
  const { width } = useWindowSize();
  return (
    <div
      style={{
        backgroundImage: `url(/feature/bgImages/bg-001-${themeMode}.jpeg)`,
        backgroundSize: "cover",
        backgroundPosition: "center",
      }
      }
    >
      <div className="relative z-[1]">
        <div className={`text-center flex flex-col items-center text-light dark:text-dark px-5} ${width <= 576 ? "pt-[20px]" : "pt-[60px] md:pt-[111px]"}`}>
          <h2 className={`bg-gradient-features-right bg-clip-text text-transparent ${width <= 405 ? "text-[26px] w-[210px]" : width <= 576 ? "text-[34px] w-[283px]" : "text-[20px] sm:text-[24px] md:text-[28px] lg:text-[32px]"}`}>
            {title}
          </h2>
          <p className={`opacity-70  px-10 font-medium leading-7 ${width <= 405 ? "text-[16px] mt-5" : width <= 576 ? "text-[18px] mt-5" : "text-[14px] md:text-[16px] lg:text-[18px] mt-3"}`}>
            {content}
          </p>
        </div>
        <div className="grid grid-rows-2 grid-cols-3 place-items-end max-w-fit mx-auto py-10 md:py-16 lg:py-28 gap-3 md:gap-4 xl:gap-5">
          {images.map((img, index) => (
            <img
              key={index}
              src={img.src}
              alt=""
              className={classNames(
                "max-w-[90px] md:max-w-[180px] lg:max-w-[220px] xl:max-w-[360px] shadow-xl",
                imageClassNames[index],
                img?.className
              )}
            />
          ))}
        </div>
      </div>
    </div>
  );
};
