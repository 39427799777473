import { TYPES } from "../../types";
import { api } from "../../api";
import { store } from "../../store";
import { IActivePlan } from "../planSubscription";
import { ICurrentTeam, IEditEmail, IEditName } from "../teams";
import { setShowUpscaleModal } from "../imageGeneration";
import { EThemeType } from "redux/reducers";

export interface ISignupReq {
  first_name: string;
  surname: string;
  email: string;
  language: string;
}

declare global {
  interface Window {
    fpr: any; 
  }
}

export interface IConfirmEmailRegistrationReq {
  expires: string;
  hash: string;
  id: string;
  signature: string;
  referral_token: string | null;
}

export interface ILoginReq {
  email: string;
}

export interface IVerifyEmailReq {
  token: string;
}

export interface IChangeLanguageReq {
  language: string;
}

export interface ISocialCallback {
  authuser: string;
  code: string;
  prompt: string;
  scope: string;
}

export interface IUserDetail {
  token: string;
  user: IUser;
}

export interface googleAuthToken {
  provider: string;
  token: string;
}

export interface IUser {
  activeSubscription: IActivePlan;
  id: number;
  name: string;
  email: string;
  email_verified_at: string;
  stripe_id: string;
  avatar: boolean;
  two_factor_secret: string;
  two_factor_recovery_codes: string;
  created_at: string;
  updated_at: string;
  language?: string;
  team: ICurrentTeam;
  referral_token: string;
  social_providers?: googleAuthToken[];
}

export const setIsPlanExpired = (isExpired: boolean) => {
  store.dispatch({ type: TYPES.PLAN_EXPIRE, payload: isExpired});
}

export const signup = (data: ISignupReq) => {
  return new Promise((resolve, reject) => {
    api
      .post("/api/auth/register", data)
      .then((res: any) => {
        localStorage.setItem('email', res?.data?.data?.email);
        resolve(res.data);
        setShowUpscaleModal({ showUpscaleModal: "show" });
        localStorage.removeItem('GptModel');
        localStorage.removeItem('imageChatGptModel');
        localStorage.removeItem('documentChatGptModel');
        localStorage.removeItem('videoChatGptModel');
        localStorage.removeItem('imageGptModel');
        localStorage.removeItem('audioChatGptModel');
      })
      .catch((err: any) => {
        reject(err);
      });
  });
};

export const confirmEmailRegistration = ({
  expires,
  hash,
  id,
  signature,
  referral_token,
}: IConfirmEmailRegistrationReq) => {
  return new Promise((resolve, reject) => {
    api
      .get(
        `/api/auth/verify-email?expires=${expires}&hash=${hash}&id=${id}&signature=${signature}${referral_token ? `&referral_token=${referral_token}` : ""
        }`
      )
      .then((res: any) => {
        resolve(res.data);
        store.dispatch({ type: TYPES.VERIFY_EMAIL, payload: res.data.data });
        window.fpr("referral", { email: res.data.data.user.email });
      })
      .catch((err: any) => {
        reject(err);
      });
  });
};

export const login = (data: ILoginReq) => {
  return new Promise((resolve, reject) => {
    api
      .post("/api/auth/login", data)
      .then((res: any) => {
        resolve(res.data);
        store.dispatch({ type: TYPES.SIGN_IN, payload: res });
      })
      .catch((err: any) => {
        reject(err);
      });
  });
};

export const fetchUserDetails = () => {
  return new Promise((resolve, reject) => {
    api
      .get("/api/user/me")
      .then((res: any) => {
        resolve(res.data);
        store.dispatch({ type: TYPES.UPDATE_USER_DETAILS, payload: res.data });
      })
      .catch((err: any) => {
        reject(err);
      });
  });
};

export const verifyEmail = (data: IVerifyEmailReq) => {
  return new Promise((resolve, reject) => {
    api
      .post("/api/auth/verify", data)
      .then((res: any) => {
        resolve(res.data);
        const { plan_expired, ...filteredData } = res.data.data;
        store.dispatch({ type: TYPES.PLAN_EXPIRE, payload: res?.data?.data?.plan_expired });
        store.dispatch({ type: TYPES.VERIFY_EMAIL, payload: filteredData });
      })
      .catch((err: any) => {
        reject(err);
      });
  });
};

export const socialRedirect = () => {
  return new Promise((resolve, reject) => {
    api
      .get("/api/auth/social/google")
      .then((res: any) => {
        resolve(res.data);
      })
      .catch((err: any) => {
        reject(err);
      });
  });
};

export const socialCallback = (data: ISocialCallback) => {
  return new Promise((resolve, reject) => {
    const { authuser, code, prompt, scope } = data;

    const referralToken = localStorage.getItem("referralToken");
    const apiUrl = `/api/auth/social/callback/google?code=${code}&scope=${scope.replace(
      / /g,
      "red"
    )}&authuser=${authuser}&prompt=${prompt}`;
    const finalApiUrl = referralToken
      ? `${apiUrl}&referral_token=${referralToken}`
      : apiUrl;

    api
      .get(finalApiUrl)
      .then((res: any) => {
        resolve(res.data);
        const { plan_expired, ...filteredData } = res.data.data;
        store.dispatch({ type: TYPES.PLAN_EXPIRE, payload: res?.data?.data?.plan_expired });
        store.dispatch({ type: TYPES.SOCIAL_CALLBACK, payload: filteredData });
        localStorage.removeItem("referralToken");
        window.fpr("referral", { email: res.data.data.user.email });
      })
      .catch((err: any) => {
        reject(err);
      });
  });
};

export const changeLanguage = (data: IChangeLanguageReq) => {
  return new Promise((resolve, reject) => {
    api
      .post(`/api/user/language`, data)
      .then((res: any) => {
        resolve(res.data);
        store.dispatch({ type: TYPES.UPDATE_LANGUAGE, payload: data.language });
      })
      .catch((err: any) => {
        reject(err);
      });
  });
};

export const logoutDispatch = () => {
  store.dispatch({ type: TYPES.INIT_USER_DETAILS });
};

export const updateLanguage = (language: string) => {
  const { userDetail, theme } = store.getState().authReducer;
  if (userDetail?.token) {
    changeLanguage({ language });
  } else {
    store.dispatch({ type: TYPES.INIT_USER_DETAILS });
    if (theme) {
      setTheme(theme)
    }
    store.dispatch({ type: TYPES.UPDATE_LANGUAGE, payload: language });
  }
};

export const setTheme = (theme: EThemeType) => {
  store.dispatch({ type: TYPES.SET_SYSTEM_THEME, payload: { theme } });
};

export const logout = () => {
  return new Promise((resolve, reject) => {
    api
      .post("/api/auth/logout")
      .then((res: any) => {
        resolve(res.data);
        logoutDispatch();
      })
      .catch((err: any) => {
        reject(err.response);
      });
  });
};

export const removeAccount = () => {
  return new Promise((resolve, reject) => {
    api
      .delete("/api/user/delete")
      .then((res: any) => {
        resolve(res.data);
        logoutDispatch();
        localStorage.removeItem('GptModel');
        localStorage.removeItem('imageChatGptModel');
        localStorage.removeItem('documentChatGptModel');
        localStorage.removeItem('imageGptModel');
      })
      .catch((err: any) => {
        reject(err.response);
      });
  });
}

export const UpdateEmail = (data: IEditEmail) => {
  return new Promise((resolve, reject) => {
    api
      .post(`/api/user/update?email=${data.email}`)
      .then((res: any) => {
        resolve(res.data);
      })
      .catch((err: any) => {
        reject(err);
      });
  });
}

export const VerifyChangeEmail = (token: string) => {
  return new Promise((resolve, reject) => {
    api
      .get(
        `/api/user/verify-email-change/${token}`
      )
      .then((res: any) => {
        resolve(res.data);
        const updatedEmail = res.data.email;
        store.dispatch({
          type: "UPDATE_USER_EMAIL",
          payload: { email: updatedEmail },
        });
        fetchUserDetails();
        window.fpr("referral", { email: res.data.email });
      })
      .catch((err: any) => {
        reject(err);
      });
  });
};

export const sendTableDataToBackend = (chatId: number): Promise<string> => {
  return new Promise((resolve, reject) => {

    api
      .get(`/api/auth/google`, {
        params: { chat_id: chatId },
      })
      .then((res) => {
        const url = res?.data?.url;
        if (typeof url === 'string') {
          resolve(url);
        } else {
          reject("Invalid URL received from backend.");
        }
      })
      .catch((err: any) => {
        reject(err);
      });
  });
};

export const googleCallback = (params: { [key: string]: string }): Promise<{ data: { google_token: string } }> => {
  return new Promise((resolve, reject) => {
    api
      .get(`/api/auth/callback/google`, { params })
      .then((res) => { 
        fetchUserDetails().then(()=>{
        resolve(res?.data); 
        }).catch(()=> {
          resolve(res?.data);
        }) 
      })
      .catch((err: any) => {
        reject(err);
      });
  });
};

const REQUEST_HEADERS = {
  "Content-Type": "application/json",
};

export const createRequestHeaders = (accessToken: string) => {
  const headers = new Headers(REQUEST_HEADERS);
  headers.append("Authorization", "Bearer " + accessToken);
  return headers;
};

const getAndEnsureArray = () => {
  const rawData = localStorage.getItem("tableData");
  let tableData;

  try {
    tableData = JSON.parse(rawData || "[]");
  } catch (error) {
    console.error("Failed to parse tableData:", error);
    tableData = [];
  }

  if (Array.isArray(tableData) && Array.isArray(tableData[0])) {
    return tableData;
  } else if (Array.isArray(tableData)) {
    return [tableData];
  } else if (typeof tableData === "string") {
    const rows = tableData.split("\n"); 
    return rows.map(row => row.split("\t")); 
  } else {
   
    return [[tableData]];
  }
};

export const createGoogleSheet = async (accessToken: string,
  authuser?: boolean
) => {
  return new Promise(async (resolve, reject) => {
    const tableData = getAndEnsureArray();

    try {
      const createSpreadsheetResponse = await fetch(
        process.env.REACT_APP_SPREADSHEETS_API_BASE_URL as string,
        {
          method: "POST",
          headers: createRequestHeaders(accessToken),
          body: JSON.stringify({
            properties: {
              title: "New Sheet",
            },
          }),
        }
      );

      const { spreadsheetId, spreadsheetUrl } =
        await createSpreadsheetResponse.json();

      if (createSpreadsheetResponse?.status === 401) {
        reject({ result: false, message: "Google Sheets creation failed" });
        return;
      }

      const range = "A1";
      await fetch(
        `${process.env.REACT_APP_SPREADSHEETS_API_BASE_URL}/${spreadsheetId}/values/${range}?valueInputOption=USER_ENTERED`,
        {
          method: "PUT",
          headers: createRequestHeaders(accessToken),
          body: JSON.stringify({
            range,
            values: tableData, 
          }),
        }
      );

      const spreadUrl = `https://docs.google.com/spreadsheets/d/${spreadsheetId}/edit`;
      if (spreadUrl && !authuser) {
        window.location.replace(spreadUrl)
      }
      else if (spreadsheetUrl) {
        window.open(spreadsheetUrl, "_blank");
      }

      resolve({ result: true, message: "Google Sheets creation success" });
    } catch (error) {
      console.error("Error in createGoogleSheet:", error);
      reject({ result: false, message: "Google Sheets creation failed" });
    }
  });
};

export const DeActivateUserAccount = () => {
  return new Promise((resolve, reject) => {

    api
      .post(`/api/user/deactivate`,{is_deactivated: true})
      .then((res: any) => {
        resolve(res.data);
        logoutDispatch();
      })
      .catch((err: any) => {
        reject(err);
      });
  });
}

export const UpdateName = (data: IEditName) => {
  return new Promise((resolve, reject) => {
    api
      .post(`/api/user/update?first_name=${data.first_name}&surname=${data.surname}`)
      .then((res: any) => {
        fetchUserDetails() .then(() => {
          resolve(res.data); 
        })
        .catch((err: any) => {
          reject(err); 
        });
      })
      .catch((err: any) => {
        reject(err);
      });
  });
}